import { FormField, Icon, ColumnLayout, StatusIndicator, Box } from '@amzn/awsui-components-react-v3';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { validatePrerequisites } from 'src/api/permissions';
import { GLADSTONE_STRING_LIST_MAX_LENGTH, TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface validatePrerequisiteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  registerItems: any[];
  selectedDatabaseName: string;
  catalogName: string;
  setLoadingValidation: any;
  setSatisfiedPrerequisite: any;
  containsPII: boolean;
}

export const ValidatePrerequisite = (props: validatePrerequisiteProps) => {
  const [databaseIamPrincipalRevoked, setDatabaseIamPrincipalRevoked] = useState(false);
  const [tableIamPrincipalRevoked, setTableIamPrincipalRevoked] = useState(false);
  const [uncheckDefaultPermission, setUncheckDefaultPermission] = useState(false);
  const [registeredInDataLocation, setRegisteredInDataLocation] = useState(false);
  const [glueKmsSettings, setGlueKmsSettings] = useState(false);
  const [lFCrossAccountVersionCheck, setLFCrossAccountVersionCheck] = useState(false);
  const [databaseIamPrincipalRevokedCause, setDatabaseIamPrincipalRevokedCause] = useState([]);
  const [tableIamPrincipalRevokedCause, setTableIamPrincipalRevokedCause] = useState([]);
  const [uncheckDefaultPermissionCause, setUncheckDefaultPermissionCause] = useState([]);
  const [glueKmsSettingsCause, setGlueKmsSettingsCause] = useState([]);
  const [lFCrossAccountVersionCheckCause, setLFCrossAccountVersionCheckCause] = useState([]);
  const [registeredInDataLocationCause, setRegisteredInDataLocationCause] = useState([]);
  const [loadingResource, setLoadingResource] = useState(true);
  const [s3EncryptionSettings, setS3EncryptionSettings] = useState(false);
  const [s3EncryptionSettingsCause, setS3EncryptionSettingsCause] = useState([]);

  const handleRefresh = async () => {
    props.setLoadingValidation(true);
    let databaseIamPrincipalRevokedRes = false;
    let tableIamPrincipalRevokedRes = false;
    let uncheckDefaultPermissionRes = false;
    let registeredInDataLocationRes = false;
    let glueKmsSettingsRes = false;
    let s3EncryptionSettingsRes = false;
    let lfCrossAccountVersionCheckRes = false;
    let startTime = Math.floor(Date.now() / 1000);

    try {
      const tableNames = props.registerItems.map((item) => item.label);
      // split the tables into chunks for GLADSTONE_STRING_LIST_MAX_LENGTH (100) and then process each list.
      const tableNamesList = _.chunk(tableNames, GLADSTONE_STRING_LIST_MAX_LENGTH);
      const validatePrerequisitesResults = await Promise.all(
        tableNamesList.map(async (names) =>
          validatePrerequisites({
            ownerId: props.activeWorkspace.workspaceId,
            lakeFormationPrerequisites: {
              catalogId: props.activeWorkspace.accountId,
              database: props.selectedDatabaseName,
              tables: names,
              region: props.activeWorkspace.region,
            },
          }),
        ),
      );
      // group validation result by using validatedResource key.
      const resultsToShow = new Map<string, object[]>();
      validatePrerequisitesResults.forEach((result) => {
        result.lakeFormationPrerequisitesResult.lakeFormationPrerequisitesValidations.forEach((validation) => {
          const key = validation.validatedResource;
          if (resultsToShow.has(key)) resultsToShow.get(key).push(validation);
          else resultsToShow.set(key, [validation]);
        });
      });

      resultsToShow.forEach((value, key) => {
        const validatedResource = key;
        const isValid = value.every((validation) => validation['isValid']);
        const causes = value
          // Get all valid ones but if at least one failed, get all failed ones.
          .filter((validation) => isValid == validation['isValid'] && validation['cause'])
          .map((validation) => validation['cause']);
        const cause = _.uniq(causes);

        if (validatedResource === 'DatabaseIAMPrincipalRevoked') {
          databaseIamPrincipalRevokedRes = isValid;
          setDatabaseIamPrincipalRevoked(databaseIamPrincipalRevokedRes);
          setDatabaseIamPrincipalRevokedCause(cause);
        } else if (validatedResource === 'TableIAMPrincipalRevoked') {
          tableIamPrincipalRevokedRes = isValid;
          setTableIamPrincipalRevoked(tableIamPrincipalRevokedRes);
          setTableIamPrincipalRevokedCause(cause);
        } else if (validatedResource === 'IAMAccessControlUnchecked') {
          uncheckDefaultPermissionRes = isValid;
          setUncheckDefaultPermission(uncheckDefaultPermissionRes);
          setUncheckDefaultPermissionCause(cause);
        } else if (validatedResource === 'DataLakeLocationRegistered') {
          registeredInDataLocationRes = isValid;
          setRegisteredInDataLocation(registeredInDataLocationRes);
          setRegisteredInDataLocationCause(cause);
        } else if (validatedResource === 'GlueKmsSettings') {
          glueKmsSettingsRes = isValid;
          setGlueKmsSettings(glueKmsSettingsRes);
          setGlueKmsSettingsCause(cause);
        } else if (validatedResource === 'S3EncryptionSettings') {
          s3EncryptionSettingsRes = isValid;
          setS3EncryptionSettings(s3EncryptionSettingsRes);
          setS3EncryptionSettingsCause(cause);
        } else if (validatedResource === 'LFCrossAccountVersion') {
          lfCrossAccountVersionCheckRes = isValid;
          setLFCrossAccountVersionCheck(lfCrossAccountVersionCheckRes);
          setLFCrossAccountVersionCheckCause(cause);
        }
      });
    } catch (e) {
      //validate prerequisite when lambda timeout
      let endTime = Math.ceil(Date.now() / 1000);
      let executionTime = endTime - startTime;
      if (executionTime >= 29 && e.statusCode == null) {
        console.log('Validate prerequisite when lambda timeout, execution time: ' + executionTime + ' seconds');
        setLoadingResource(false);
        props.setLoadingValidation(false);
        setDatabaseIamPrincipalRevoked(true);
        setDatabaseIamPrincipalRevokedCause(null);
        setTableIamPrincipalRevoked(true);
        setTableIamPrincipalRevokedCause(null);
        setUncheckDefaultPermission(true);
        setUncheckDefaultPermissionCause(null);
        setRegisteredInDataLocation(true);
        setRegisteredInDataLocationCause(null);
        setGlueKmsSettings(true);
        setGlueKmsSettingsCause(null);
        setLFCrossAccountVersionCheck(true);
        setLFCrossAccountVersionCheckCause(null);
        props.setSatisfiedPrerequisite(true);
        return;
      } else {
        console.error('Error validating prerequisite ', e);
      }
    }

    setLoadingResource(false);
    props.setLoadingValidation(false);
    props.setSatisfiedPrerequisite(
      databaseIamPrincipalRevokedRes &&
        tableIamPrincipalRevokedRes &&
        uncheckDefaultPermissionRes &&
        registeredInDataLocationRes &&
        glueKmsSettingsRes &&
        lfCrossAccountVersionCheckRes &&
        // only mandatory for PII data
        (!props.containsPII || s3EncryptionSettingsRes),
    );
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  return (
    <>
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Prerequisite checklist</h2>
            </div>
          </div>
        </div>
        <ColumnLayout columns={1}>
          {validateStatus(
            'LF cross account version check ',
            lFCrossAccountVersionCheck,
            'Make sure to use LF version >=3.',
            loadingResource,
            lFCrossAccountVersionCheckCause,
          )}
          {validateStatus(
            'IAMAllowedPrincipals revoked for database ',
            databaseIamPrincipalRevoked,
            'Make sure to revoke all IAMAllowedPrincipals for database if not hybrid.',
            loadingResource,
            databaseIamPrincipalRevokedCause,
          )}
          {validateStatus(
            'IAMAllowedPrincipals revoked for tables ',
            tableIamPrincipalRevoked,
            'Make sure to revoke all IAMAllowedPrincipals for tables if not hybrid.',
            loadingResource,
            tableIamPrincipalRevokedCause,
          )}
          {validateStatus(
            'Unchecked default permissions for newly created tables ',
            uncheckDefaultPermission,
            'Make sure to uncheck "Default permissions for newly created tables - Use only IAM access control for new tables in this database" if not hybrid.',
            loadingResource,
            uncheckDefaultPermissionCause,
          )}
          {validateStatus(
            'Registered in data lake location ',
            registeredInDataLocation,
            'Make sure s3 locations of selected tables have been registered in data lake locations.',
            loadingResource,
            registeredInDataLocationCause,
          )}
          {validateStatus(
            'Glue KMS Encryption Settings ',
            glueKmsSettings,
            'Make sure to safelist Omni role in the KMS key if the Glue catalog is encrypted.',
            loadingResource,
            glueKmsSettingsCause,
          )}
          {validateStatus(
            'S3 Encryption At Rest ' + (props.containsPII ? '(Required for PII datasets) ' : '(Recommended) '),
            s3EncryptionSettings,
            'The underlying s3 data should be encrypted. Mandatory for PII datasets.',
            loadingResource,
            s3EncryptionSettingsCause,
            !props.containsPII,
          )}
        </ColumnLayout>
      </div>
    </>
  );
};

const validateStatus = (validatePrerequisiste, successful, description, loading, causes, warning = false) => {
  if (loading) {
    let label = (
      <div>
        <StatusIndicator type='loading'>{validatePrerequisiste}</StatusIndicator>
      </div>
    );
    return <FormField label={label} description={description}></FormField>;
  }
  if (successful) {
    let label = (
      <div>
        {validatePrerequisiste}
        <Icon name='status-positive' variant='success' />
      </div>
    );
    return <FormField label={label} description={description}></FormField>;
  } else {
    let label = (
      <div>
        {validatePrerequisiste}
        <Icon name={warning ? 'status-warning' : 'status-negative'} variant='warning' />
      </div>
    );
    const cause = causes.length
      ? causes.map((item) => (
          <Box fontSize='body-s' color='text-status-error' key={item}>
            {item}
          </Box>
        ))
      : null;
    return <FormField label={label} description={cause}></FormField>;
  }
};
