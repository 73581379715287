import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ColumnLayout,
  Flashbar,
  Header,
  Icon,
  SpaceBetween,
  Tabs,
} from '@amzn/awsui-components-react-v3';
import {
  convertToDgsHCResourceArn,
  convertToDgsHCResourceId,
  CopiableText,
  fetchTemplatesForResourceId,
  isDGSAdmin,
} from 'src/commons/common';
import { PageHeader } from 'src/components/notifications/common';
import { WSDatasetList } from 'src/components/workspaces/dataBrowse/schema/datasetList';
import { getSchemas } from 'src/api/catalog';
import { getResourceTags } from 'src/api/permissions';
import { SchemaAccessSelectModal } from 'src/components/workspaces/dataBrowse/schema/schemaAccessSelectModal';
import { SchemaEditModal } from 'src/components/workspaces/dataBrowse/schema/schemaEdit';
import { SchemaTagList } from 'src/components/workspaces/dataBrowse/schema/SchemaTagList';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  createGroupDetailLink,
  createWorkspaceDetailLink,
  createWSRedshiftCatalogDetailLink,
  createWSRedshiftDatabaseDetailLink,
} from 'src/routes';
import { Link } from 'react-router-dom';
import { DATA_PERMISSION_REDSHIFT_TYPE, REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import BusinessGlossaries from 'src/components/workspaces/common/businessGlossaries';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import ContactInfo from 'src/components/workspaces/common/ContactInfo';

export interface WorkspaceSchemaDetailProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveDatabaseName: any;
  cartItemIds: [];
  addToCart: any;
  catalogMap: any;
  userInfo: any;
}

export const WorkspaceSchemaDetail = (props: WorkspaceSchemaDetailProps) => {
  const [schema, setSchema] = useState(undefined);
  const [editSchemaModalVisible, setEditSchemaModalVisible] = useState(false);
  const [tags, setTags] = useState(undefined);
  const [accessSelectVisible, setAccessSelectVisible] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [catalogName, setCatalogName] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(undefined);

  const fetchTemplatesForSchema = async (schema) => {
    let resourceId = convertToDgsHCResourceId(
      schema?.CatalogId,
      schema?.ClusterIdentifier,
      schema?.DatabaseName,
      schema.Schema,
      undefined,
      undefined,
      schema?.DataAccessRole,
    );
    let fetchedTemplates = await fetchTemplatesForResourceId(resourceId);
    setTemplates(fetchedTemplates);
  };

  const fetchTags = async (schema) => {
    let resourceId = `DS-redshift|A-${schema?.CatalogId}|CI-${schema?.ClusterIdentifier}|DN-${schema?.DatabaseName}|SN-${schema?.Schema}|R-${schema?.Region}`;
    let tagList = [];
    try {
      let getResourceTagsResponse = await getResourceTags({
        resourceId: resourceId,
        statusCustomerType: 'Active:Publisher',
      });
      tagList.push(...getResourceTagsResponse.tags);
    } catch (err) {
      console.log(err);
    }
    setTags(tagList);
  };

  const notifyEditSuccess = async (msg) => {
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyEditFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const schemas = await getSchemas({
        SchemaKeyList: [
          {
            CatalogId: props.match.params.catalogid,
            DatabaseName: props.match.params.databasename,
            Schema: props.match.params.schemaname,
            DataSourceId: REDSHIFT_DATASOURCE_ID,
            Region: props.match.params.region,
            ClusterIdentifier: props.match.params.clustername,
          },
        ],
      });
      let schema = schemas.SchemaInfoList[0];
      await fetchTags(schema);
      await fetchTemplatesForSchema(schema);
      setSchema(schema);
      setCatalogName(
        props.catalogMap.get(schema?.CatalogId + ':' + schema?.ClusterIdentifier + ':' + schema?.Region) == null
          ? schema?.CatalogId
          : props.catalogMap.get(schema?.CatalogId + ':' + schema?.ClusterIdentifier + ':' + schema?.Region),
      );
      props.setActiveDatabaseName(`${schema?.DatabaseName}.${schema?.Schema}`);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
    setAccessSelectVisible(false);
  };

  const getTemplateForSchema = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsSchema()} />;
  };

  const tagLabels = () => {
    return (
      <div>
        {tags?.map((tag, _) => {
          return (
            <Badge color='blue'>
              {tag.tagKey}.{tag.tagValue}{' '}
            </Badge>
          );
        })}
      </div>
    );
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const userOwnsSchema = () => {
    if (!schema || !schema.Owners) return false;
    if (props.userInfo && isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    return schema?.Owners.includes(props.activeWorkspace?.workspaceId);
  };

  const buildResource = () => {
    if (!schema) return {};
    return {
      accountId: schema.CatalogId,
      region: schema.Region,
      type: 'SCHEMA',
      dataCatalogObjectDetails: {
        dataSourceId: 'Redshift',
        clusterIdentifier: schema.ClusterIdentifier,
        databaseName: schema.DatabaseName,
        schemaName: schema.Schema,
      },
      dpType: DATA_PERMISSION_REDSHIFT_TYPE,
      tagResourceId: `DS-redshift|A-${schema.CatalogId}|CI-${schema.ClusterIdentifier}|DN-${schema.DatabaseName}|SN-${schema.Schema}`,
    };
  };

  const getSchemaDetailsMetadata = () => {
    const schemaDetails = [];
    schemaDetails.push(<CopiableText name={'Schema name'} key={'Schema name'} value={schema?.Schema} />);

    schemaDetails.push(
      <CopiableText
        name={'Database name'}
        key={'Database name'}
        value={schema?.DatabaseName}
        url={createWSRedshiftDatabaseDetailLink(
          schema?.CatalogId,
          schema?.ClusterIdentifier,
          schema?.DatabaseName,
          schema?.Region,
        )}
      />,
    );

    schemaDetails.push(<CopiableText name={'Cluster name'} key={'Cluster name'} value={schema?.ClusterIdentifier} />);

    schemaDetails.push(<CopiableText name={'Description'} key={'Description'} value={schema?.Description} />);

    schemaDetails.push(<CopiableText name={'Region'} key={'Region'} value={schema?.Region} />);

    if (schema?.CreatedBy) {
      schemaDetails.push(<CopiableText name={'Created by'} key={'CreatedBy'} value={schema?.CreatedBy} />);
    }
    if (schema?.CreatedOn) {
      schemaDetails.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={schema?.CreatedOn} />);
    }

    if (schema?.UpdatedBy) {
      schemaDetails.push(<CopiableText name={'Updated by'} key={'UpdatedBy'} value={schema?.UpdatedBy} />);
    }
    if (schema?.UpdatedOn) {
      schemaDetails.push(<CopiableText name={'Updated on'} key={'UpdatedOn'} value={schema?.UpdatedOn} />);
    }

    if (schema?.Owners) {
      schemaDetails.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={schema?.Owners.map((owner) => (
            <div>
              <Link to={isValidWorkspace(owner) ? createWorkspaceDetailLink(owner) : createGroupDetailLink(owner)}>
                {owner}
              </Link>
            </div>
          ))}
        />,
      );
    }

    schemaDetails.push(
      <CopiableText
        name={'Catalog name'}
        key={'CatalogName'}
        value={props.catalogMap.get(schema?.CatalogId + ':' + schema?.ClusterIdentifier + ':' + schema?.Region)}
        url={createWSRedshiftCatalogDetailLink(schema?.CatalogId, schema?.ClusterIdentifier, schema?.Region)}
      />,
    );

    return schemaDetails;
  };

  const closeEditModal = () => {
    setEditSchemaModalVisible(false);
  };

  const schemaDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <SpaceBetween size={'l'}>
              <div className='awsui-util-container'>
                <div className='awsui-util-container-header'>
                  <div className='awsui-util-action-stripe'>
                    <div className='awsui-util-action-stripe-title'>
                      <h2>Schema details</h2>
                    </div>
                    <div className='awsui-util-action-stripe-group'>
                      <div style={{ paddingTop: 5, paddingRight: 10 }} hidden={!userOwnsSchema()}>
                        <Button
                          variant='normal'
                          onClick={() => {
                            setEditSchemaModalVisible(true);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <ColumnLayout columns={3} borders='horizontal'>
                  {getSchemaDetailsMetadata()}
                </ColumnLayout>
              </div>
              {!loading && schema && (
                <ContactInfo
                  resource={convertToDgsHCResourceArn(
                    schema?.CatalogId,
                    schema?.ClusterIdentifier,
                    schema?.DatabaseName,
                    schema?.Schema,
                    undefined,
                    undefined,
                    schema?.DataAccessRole,
                  )}
                />
              )}
              {!loading && schema && (
                <BusinessGlossaries
                  activeGroup={props.activeGroup}
                  activeWorkspace={props.activeWorkspace}
                  match={''}
                  setContentType={props.setContentType}
                  resource={convertToDgsHCResourceArn(
                    schema?.CatalogId,
                    schema?.ClusterIdentifier,
                    schema?.DatabaseName,
                    schema?.Schema,
                    undefined,
                    undefined,
                    schema?.DataAccessRole,
                  )}
                  isOwner={userOwnsSchema()}
                />
              )}
            </SpaceBetween>
          </>
        ),
      },
      {
        label: 'Datasets',
        id: 'Datasets',
        content: (
          <WSDatasetList
            {...props}
            catalogId={props.match.params.catalogid}
            databaseName={props.match.params.databasename}
            clusterIdentifier={props.match.params.clustername}
            region={props.match.params.region}
            schemaName={props.match.params.schemaname}
          />
        ),
      },
      {
        label: 'Tags',
        id: 'Tags',
        content: (
          <SchemaTagList
            {...props}
            notifyEditFailure={notifyEditFailure}
            notifyEditSuccess={notifyEditSuccess}
            schema={schema}
            updateTagLabel={handleRefresh}
          />
        ),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForSchema(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={schema?.Owners}
            resource={convertToDgsHCResourceArn(
              schema?.CatalogId,
              schema?.ClusterIdentifier,
              schema?.DatabaseName,
              schema?.Schema,
              undefined,
              undefined,
              schema?.DataAccessRole,
            )}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
            setContentType={props.setContentType}
            setNotification={setNotification}
          />
        ),
      },
    ];

    if (userOwnsSchema())
      tabs.push({
        label: 'Consumers',
        id: 'consumers',
        content: <DataConsumersTable {...props} resource={buildResource()} />,
      });

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const handleAddToCart = (_) => {
    setAccessSelectVisible(true);
  };

  let redshiftIcon = require('src/components/common/Redshift.png');

  if (!schema && !loading) {
    return (
      <>
        <h2>Schema not found</h2>
        The given schema is not valid, or you do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );
  }

  return (
    <>
      <Flashbar items={notifications} />

      <PageHeader
        buttons={[
          {
            text: 'Request Redshift access',
            onItemClick: handleAddToCart,
            disabled: props.activeWorkspace == null,
          },
        ]}
        header={
          <Header description={schema?.Description} variant={'h1'}>
            <Icon url={redshiftIcon} size='big' /> {schema?.Schema}
          </Header>
        }
      />
      <div>{tagLabels()}</div>
      {schemaDetail()}

      {schema !== undefined && (
        <SchemaEditModal
          {...props}
          schema={schema}
          visible={editSchemaModalVisible}
          dismiss={closeEditModal}
          notifyEditSuccess={notifyEditSuccess}
          notifyEditFailure={notifyEditFailure}
        />
      )}

      {accessSelectVisible !== undefined && accessSelectVisible && tags && schema && props.activeWorkspace != null && (
        <SchemaAccessSelectModal
          {...props}
          visible={accessSelectVisible}
          dismiss={() => {
            setAccessSelectVisible(false);
          }}
          // candidateAccess={accessApproaches}
          tags={tags}
          schema={schema}
          catalogName={catalogName}
        />
      )}
    </>
  );
};
