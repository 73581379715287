import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';

export interface quarterlyViewProps {
  setContentType: any;
  match: any;
}

export const QuarterlyView = (props: quarterlyViewProps) => {
  return <MetricTableView props={props} contentType='QUARTERLY' />;
};
