import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Component } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Tabs,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { coloredStatus } from 'src/components/resourcesmanager/components';
import { editDatabase, listDatabases, listDatasetsForDatabase } from '../../api/catalog';
import { scrollUp } from '../utils/navigation';
import { PageHeader } from '../subscriptions/common';
import BrowseTable from './browsedatasets';
import RegisterTable from './register';
import * as validate from 'src/commons/validationUtils';
import { createCatalogDetailLink, createGroupDetailLink, createWorkspaceDetailLink } from 'src/routes';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import {
  convertToDgsHCResourceArn,
  convertToDgsHCResourceId,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  getGroupOrWorkspaceName,
  isDGSAdmin,
} from 'src/commons/common';
import { SubscriptionsTable } from 'src/components/catalog/subscriptions/subscriptionsTable';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import BusinessGlossaries from 'src/components/workspaces/common/businessGlossaries';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import _ from 'lodash';
import { ContactInfo } from 'src/components/workspaces/common/ContactInfo';

export interface DatabaseDetailProps extends DefaultRouteProps {
  allowListed: boolean;
  setContentType: any;
  setActiveDatabaseName: any;
  match: any;
  activeGroup: string;
  activeWorkspace: object;
  cartItemIds: string[];
  setItemsInCart: any;
  addToCart: any;
  userInfo: any;
}

export interface DatabaseDetailState {
  notifications: FlashbarProps.MessageDefinition[];
  loading: boolean;
  database: object;
  datasetIds: string[];
  redirect: string;
  contentType: string;
  editDatabaseModalVisible: boolean;
  saveEditDatabaseLoading: boolean;
  editableOwners: string;
  editableDescription: string;
  editableAutoTableOnboard: Boolean;
  editableAllowSNSEventSubscription: Boolean;
  editDatabaseSuccess: boolean;
  templates: object[];
}

export default class DatabaseDetail extends Component<DatabaseDetailProps, DatabaseDetailState> {
  state = {
    loading: true,
    database: null,
    datasetIds: [],
    redirect: undefined,
    contentType: undefined,
    notifications: [],
    editDatabaseButton: undefined,
    editDatabaseModalVisible: false,
    saveEditDatabaseLoading: false,
    editableOwners: '',
    editableDescription: undefined,
    editableAutoTableOnboard: false,
    editableAllowSNSEventSubscription: false,
    editDatabaseSuccess: true,
    templates: [],
  };

  componentDidMount = async () => {
    this.props.setContentType(TABLE_CONTENT_TYPE);
    // This is for Save button, after editing the Database on clicking this will invoke api
    this.handleEditDatabase = this.handleEditDatabase.bind(this);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  handleRefresh = async () => {
    this.setState({ loading: true });
    // pull the specific database
    try {
      const databases = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
          },
        ],
      });
      await this.fetchTemplatesForDatabase(databases.DatabaseInfoList[0]);
      this.setState({
        database: databases.DatabaseInfoList[0],
      });
    } catch (err) {
      this.setState({ database: null });
    }

    // pull all datasets belonging to this database
    let nextToken = '';
    let datasetIds = [];
    while (nextToken != null) {
      try {
        let datasets;
        if (nextToken != '') {
          datasets = await listDatasetsForDatabase({
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
            NextToken: nextToken,
          });
        } else {
          datasets = await listDatasetsForDatabase({
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
          });
        }

        const newIds = datasets.DataSetList.map((item) => item.Id);
        datasetIds = datasetIds.concat(newIds);
        nextToken = datasets.NextToken;
      } catch (err) {
        console.log('Exception when fetch datasets for database', err);
        this.setState({ datasetIds: [] });
        break;
      }
    }
    this.setState({
      datasetIds: datasetIds,
    });
    if (this.state.database) {
      this.props.setActiveDatabaseName(this.state.database.DatabaseName);
    }

    this.setState({ loading: false });
  };

  setNotifications = (value) => {
    this.handleRefresh();
    scrollUp();
    this.setState({ notifications: value });
  };

  fetchTemplatesForDatabase = async (database) => {
    let resourceId = convertToDgsHCResourceId(
      database?.CatalogId,
      database?.ClusterIdentifier,
      database?.DatabaseName,
      undefined,
      undefined,
      undefined,
      database?.DataAccessRole,
    );
    let templates = await fetchTemplatesForResourceId(resourceId);
    this.setState({
      templates: templates,
    });
  };

  buildResource = () => {
    if (!this.state.database) return {};
    const database = this.state.database;
    const isGlueLF = database.DataAccessRole && database.DataAccessRole.length;
    return {
      accountId: database.CatalogId,
      region: database.Region,
      type: 'DATABASE',
      dataCatalogObjectDetails: {
        dataSourceId: isGlueLF ? 'GlueLF' : 'Glue',
        databaseName: database.DatabaseName,
      },
      dpType: isGlueLF ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_IAM_TYPE,
      tagResourceId: isGlueLF ? `DS-glueLF|A-${database.CatalogId}|DN-${database.DatabaseName}` : undefined,
    };
  };

  userOwnsDatabase() {
    if (!this.state.database) return false;
    let owners = this.state.database?.Owners;
    if (!this.props.userInfo) return false;
    if (this.props.userInfo && isDGSAdmin(this.props.userInfo.memberGroupIds)) return true;
    const groupOwner =
      !!this.props.userInfo.memberGroupIds && _.find(this.props.userInfo.memberGroupIds, (grp) => owners.includes(grp));
    return groupOwner
      ? true
      : !!this.props.userInfo.memberWorkspaceIds &&
          _.find(this.props.userInfo.memberWorkspaceIds, (wks) => owners.includes(wks));
  }

  getDetailsContainer() {
    return (
      <>
        <div className='awsui-util-container'>
          <div className='awsui-util-container-header'>
            <div className='awsui-util-action-stripe'>
              <div className='awsui-util-action-stripe-title'>
                <h2>Database details</h2>
              </div>
              <div className='awsui-util-action-stripe-group'>
                <div style={{ paddingTop: 5, paddingRight: 10 }} hidden={!this.userOwnsDatabase()}>
                  <Button
                    variant='normal'
                    onClick={() => {
                      this.editDatabaseForm();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ColumnLayout columns={3} borders='horizontal'>
            {this.getDatabaseDetailsMetadata()}
          </ColumnLayout>
        </div>
        <SpaceBetween size={'l'}>
          {!this.state.loading && this.state.database && (
            <ContactInfo
              resource={convertToDgsHCResourceArn(
                this.state.database?.CatalogId,
                this.state.database?.ClusterIdentifier,
                this.state.database?.DatabaseName,
                undefined,
                undefined,
                undefined,
                this.state.database?.DataAccessRole,
              )}
            />
          )}
          {!this.state.loading && this.state.database && (
            <BusinessGlossaries
              activeGroup={this.props.activeGroup}
              match={''}
              setContentType={this.props.setContentType}
              resource={convertToDgsHCResourceArn(
                this.state.database?.CatalogId,
                this.state.database?.ClusterIdentifier,
                this.state.database?.DatabaseName,
                undefined,
                undefined,
                undefined,
                this.state.database?.DataAccessRole,
              )}
              isOwner={this.userOwnsDatabase()}
            />
          )}
        </SpaceBetween>
      </>
    );
  }

  getRegisterDataset() {
    return (
      <>
        <RegisterTable
          setContentType={(type) => this.setState({ contentType: type })}
          title='Register datasets'
          onNotificationChange={this.setNotifications}
          extraFeatures={true}
          databaseDetail={this.state.database}
          idFilter={this.state.datasetIds}
          onlyShowDatasetName={false}
          loading={this.state.loading}
          activeGroup={this.props.activeGroup}
        />
        <br />
      </>
    );
  }

  getDatabaseDatasets() {
    return (
      <BrowseTable
        {...this.props}
        setContentType={(type) => this.setState({ contentType: type })}
        title='Datasets'
        extraFeatures={true}
        idFilter={this.state.datasetIds}
        onlyShowDatasetName={true}
        loading={this.state.loading}
        activeGroup={this.props.activeGroup}
        cartItemIds={this.props.cartItemIds}
        addToCart={this.props.addToCart}
      />
    );
  }

  getMetadataForDatabase() {
    return (
      <MetadataDetails
        resourceOwnerIds={this.state.database?.Owners}
        resource={convertToDgsHCResourceArn(
          this.state.database?.CatalogId,
          this.state.database?.ClusterIdentifier,
          this.state.database?.DatabaseName,
          undefined,
          undefined,
          undefined,
          this.state.database?.DataAccessRole,
        )}
        activeGroup={this.props.activeGroup}
        setContentType={this.props.setContentType}
        setNotification={this.setNotification}
      />
    );
  }

  getTemplatesForDatabase() {
    return <TemplatesForResourceDetailsTable items={this.state.templates} userOwnsResource={this.userOwnsDatabase()} />;
  }

  setNotification = async (header, message) => {
    if (header === 'error') {
      this.setState({
        notifications: [
          {
            header: header,
            type: 'error',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            type: 'success',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  getDatabaseTabs() {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: this.getDetailsContainer(),
      },
      {
        label: 'Datasets',
        id: 'Datasets',
        content: this.getDatabaseDatasets(),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: this.getTemplatesForDatabase(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: this.getMetadataForDatabase(),
      },
    ];

    if (this.state.database && this.state.database.DataAccessRole !== null && this.userOwnsDatabase()) {
      tabs.push({
        label: 'Register datasets',
        id: 'Register datasets',
        content: this.getRegisterDataset(),
      });
    }

    if (this.userOwnsDatabase()) {
      tabs.push({
        label: 'Consumers',
        id: 'Consumers',
        content: <DataConsumersTable {...this.props} resource={this.buildResource()} />,
      });
    }

    tabs.push({
      label: 'Subscriptions',
      id: 'Subscriptions',
      content: (
        <SubscriptionsTable
          {...this.props}
          allowSNSEventSubscription={this.state.database?.AllowSNSEventSubscription}
          resource={this.buildResource()}
        />
      ),
    });

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (!this.state.database && !this.state.loading) {
      return (
        <>
          <h2>Database not found</h2>
          The given database {this.props.match.params.databasename} is not valid, or you do not have permission to view
          it. Please check the URL for mistakes and try again.
        </>
      );
    }
    return (
      <>
        <Flashbar items={this.state.notifications} />
        <PageHeader buttons={[]} header={`${this.props.match.params.databasename}`} />
        <Modal
          onDismiss={() => {
            this.setState({ editDatabaseModalVisible: false });
          }}
          visible={this.state.editDatabaseModalVisible}
          size='medium'
          footer={
            <span className='awsui-util-f-r'>
              <Button
                variant='link'
                onClick={() => {
                  this.setState({ editDatabaseModalVisible: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={this.disableEditDatabaseSaveButton()}
                loading={this.state.saveEditDatabaseLoading}
                onClick={this.handleEditDatabase}
              >
                Save
              </Button>
            </span>
          }
          header='Edit database'
        >
          <ColumnLayout>
            <FormField label='Catalog ID'>
              <Input disabled={true} value={this.state.database?.CatalogId} />
            </FormField>
            <FormField label='Database name'>
              <Input disabled={true} value={this.state.database?.DatabaseName} />
            </FormField>
            <FormField label='Description' description=''>
              <Input
                ariaRequired={true}
                placeholder={'Description for the database.'}
                value={this.state.editableDescription}
                onChange={(e) =>
                  this.setState({
                    editableDescription: e.detail.value,
                  })
                }
              />
            </FormField>

            <FormField
              label='Owners'
              description='Comma-separated Omni Group IDs.'
              errorText={
                validate.isFalsy(this.state.editableOwners)
                  ? 'Owners field cannot be empty.'
                  : this.isActiveGroupExists()
                  ? false
                  : 'Cannot delete the group you are currently logged in with.'
              }
            >
              <Input
                ariaRequired={true}
                placeholder={'GroupId1,GroupId2'}
                value={this.state.editableOwners}
                onChange={(e) =>
                  this.setState({
                    editableOwners: e.detail.value,
                  })
                }
              />
            </FormField>

            <FormField
              label='Auto table onboard'
              description='Select this to automatically register new datasets from this database into Omni.'
            >
              <Toggle
                checked={this.state.editableAutoTableOnboard}
                onChange={(e) =>
                  this.setState({
                    editableAutoTableOnboard: e.detail.checked,
                  })
                }
              />
            </FormField>

            <FormField
              label='Allow SNS event subscriptions'
              description='Allows subscriptions for event notifications.'
            >
              <Toggle
                checked={this.state.editableAllowSNSEventSubscription}
                onChange={(e) =>
                  this.setState({
                    editableAllowSNSEventSubscription: e.detail.checked,
                  })
                }
              />
            </FormField>
          </ColumnLayout>
        </Modal>
        <div>{this.getDatabaseTabs()}</div>
      </>
    );
  }

  getDatabaseDetailsMetadata() {
    const database_metadata = [];
    if (this.state.database?.DatabaseName) {
      database_metadata.push(
        <CopiableText name={'Database name'} key={'Database name'} value={this.state.database?.DatabaseName} />,
      );
    }
    if (this.state.database?.Description) {
      database_metadata.push(
        <CopiableText name={'Description'} key={'Description'} value={this.state.database?.Description} />,
      );
    }
    if (this.state.database?.CatalogId) {
      database_metadata.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={this.state.database?.CatalogId}
          url={createCatalogDetailLink(this.state.database?.CatalogId, this.state.database?.Region)}
        />,
      );
    }
    if (this.state.database?.Region) {
      database_metadata.push(<CopiableText name={'Region'} key={'Region'} value={this.state.database?.Region} />);
    }
    if (this.state.database?.Owners) {
      database_metadata.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={this.state.database?.Owners.map((owner) => (
            <div>
              <Link to={isValidWorkspace(owner) ? createWorkspaceDetailLink(owner) : createGroupDetailLink(owner)}>
                {getGroupOrWorkspaceName(owner, this.props.workspaceNameMap)}
              </Link>
            </div>
          ))}
        />,
      );
    }
    if (this.state.database?.CreatedBy) {
      database_metadata.push(
        <CopiableText name={'Created by'} key={'Created by'} value={this.state.database?.CreatedBy} />,
      );
    }
    if (this.state.database?.CreatedOn) {
      database_metadata.push(
        <CopiableText name={'Created on'} key={'CreatedOn'} value={this.state.database?.CreatedOn} />,
      );
    }
    if (this.state.database?.UpdatedBy) {
      database_metadata.push(
        <CopiableText name={'Updated by'} key={'Updated by'} value={this.state.database.UpdatedBy} />,
      );
    }
    if (this.state.database?.UpdatedOn) {
      database_metadata.push(
        <CopiableText name={'Updated on'} key={'Updated on'} value={this.state.database.UpdatedOn} />,
      );
    }
    if (this.state.database?.AutoTableOnboard !== null) {
      database_metadata.push(
        <CopiableText
          copiable={false}
          name={'Auto-onboard'}
          key={'Auto-onboard'}
          value={coloredStatus(this.state.database?.AutoTableOnboard ? 'ENABLED' : 'DISABLED')}
        />,
      );
    }
    if (this.state.database?.AllowSNSEventSubscription !== null) {
      database_metadata.push(
        <CopiableText
          copiable={false}
          name={'Allow Event Subscriptions'}
          key={'Allow Event Subscriptions'}
          value={coloredStatus(this.state.database?.AllowSNSEventSubscription ? 'ENABLED' : 'DISABLED')}
        />,
      );
    }
    return database_metadata;
  }

  // make sures that it is visible only for the owners
  hideEditDatabaseButton() {
    return !this.state.database?.Owners.includes(this.props.activeGroup);
  }

  // on clicking Edit button this method is triggered
  editDatabaseForm() {
    this.clearEditableParametersForDatabase();
    this.initializeEditableParametersForDatabase();
    this.setState({ editDatabaseModalVisible: true });
  }

  // clear all the editable fields before assigning
  clearEditableParametersForDatabase() {
    this.setState({
      editableAutoTableOnboard: false,
      editableAllowSNSEventSubscription: false,
      editableDescription: undefined,
      editableOwners: '',
    });
  }

  // this will initialize editable fields with the current fields, so they can be prefilled in form
  initializeEditableParametersForDatabase() {
    // converting Array type of Owners to comma seperated GroupIds
    for (const item of this.state.database.Owners) {
      if (this.state.editableOwners === '') {
        this.state.editableOwners = this.state.editableOwners + item;
      } else {
        this.state.editableOwners = this.state.editableOwners + ',' + item;
      }
    }
    this.setState({
      editDatabaseSuccess: true,
      editableDescription: this.state.database.Description,
      editableAutoTableOnboard: this.state.database.AutoTableOnboard ? this.state.database.AutoTableOnboard : false,
      editableAllowSNSEventSubscription: this.state.database.AllowSNSEventSubscription
        ? this.state.database.AllowSNSEventSubscription
        : false,
    });
  }

  // will validate Editable parameters provided and changes the visibility of Save Button
  disableEditDatabaseSaveButton() {
    return !this.isActiveGroupExists();
  }

  // make sures that the current ActiveGroup is not edited in the Edit Form
  isActiveGroupExists() {
    return !!this.state.editableOwners.split(',').includes(this.props.activeGroup);
  }

  // On clicking Save Database this will make an api call and stores the Data
  async handleEditDatabase() {
    this.setState({
      saveEditDatabaseLoading: true,
    });
    // converting string type into Array to pass through Edit Database API
    let ownersArray = this.state.editableOwners.split(',').map((item) => item.trim());
    ownersArray = ownersArray.filter((a, b) => ownersArray.indexOf(a) === b);
    try {
      let editDatabaseResponse = await editDatabase({
        CatalogId: this.state.database.CatalogId,
        DatabaseName: this.state.database.DatabaseName,
        Owners: ownersArray,
        Description: this.state.editableDescription,
        AutoTableOnboard: this.state.editableAutoTableOnboard,
        AllowSNSEventSubscription: this.state.editableAllowSNSEventSubscription,
      });
      if (!editDatabaseResponse.Message.includes('Success')) {
        this.setState({
          editDatabaseSuccess: false,
        });
      }
    } catch (e) {
      this.setState({
        editDatabaseSuccess: false,
      });
      console.log(e);
    } finally {
      if (this.state.editDatabaseSuccess) {
        // to get the edited details displayed on the page without refresh
        await this.handleRefresh();
        this.setState({
          notifications: [
            {
              type: 'success' as FlashbarProps.Type,
              content: `Successfully edited the database.`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      } else {
        this.setState({
          notifications: [
            {
              type: 'error' as FlashbarProps.Type,
              content: `Failed to edit the database.`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      }
      this.setState({
        saveEditDatabaseLoading: false,
        editDatabaseModalVisible: false,
      });
    }
  }
}
