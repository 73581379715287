import {
  Pagination,
  Table,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  PropertyFilter,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { auditDataPermission, getDataLink, getDataPermission, getTaggedResources } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { Link } from 'react-router-dom';
import {
  DATASET_RESOURCE_TYPE,
  REDSHIFT_TAG_TYPE,
  SCHEMA_RESOURCE_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import {
  createWSDatasetDetailLink,
  createWSRedshiftCatalogDetailLink,
  createWSRedshiftDatabaseDetailLink,
  createWSSchemaDetailLink,
} from 'src/routes';
import { dataPermissionDetail } from 'src/components/workspaces/common/common';

export interface PermissionDetailProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  match: any;
  dismiss: any;
  dataset: any;
  catalogName: any;
  catalogMap: any;
}

export const RedshiftPermissionDetail = (props: PermissionDetailProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dataPermission, setDataPermission] = useState(undefined);
  const [loadingButton, setLoadingButton] = useState(false);
  const [, setSelectedItem] = useState(undefined);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions = [
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => (
        <Link to={createWSRedshiftCatalogDetailLink(item?.catalogId, item?.clusterId, item?.region)}>
          {item?.catalogId}
        </Link>
      ),
      minWidth: 250,
    },
    {
      id: 'clusterId',
      header: 'Cluster ID',
      cell: (item) => item?.clusterId,
      minWidth: 250,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => (
        <Link
          to={createWSRedshiftDatabaseDetailLink(item?.catalogId, item?.clusterId, item?.databaseName, item?.region)}
        >
          {item?.databaseName}
        </Link>
      ),
      minWidth: 250,
    },
    {
      id: 'schema',
      header: 'Schema name',
      cell: (item) => (
        <Link
          to={createWSSchemaDetailLink(
            item?.catalogId,
            item?.clusterId,
            item?.databaseName,
            item?.schemaName,
            item?.region,
          )}
        >
          {item?.schemaName}
        </Link>
      ),
      minWidth: 250,
    },
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) =>
        item.tableName == undefined ? (
          '*'
        ) : (
          <Link
            to={createWSDatasetDetailLink(
              `DS-redshift|A-${item?.catalogId}|CI-${item?.clusterId}|DN-${item?.databaseName}|SN-${item?.schemaName}|TN-${item?.tableName}|R-${item?.region}`,
            )}
          >
            {item?.tableName}
          </Link>
        ),
      minWidth: 250,
    },
  ];

  const fetchDataPermission = async () => {
    try {
      const dataPermissionResult = await getDataPermission({
        dataPermissionId: props.match.params.permissionId,
        metadata: props.match.params.permissionId,
      });
      setDataPermission(dataPermissionResult?.DataPermission);
      let response = await getDataLink({
        dataPermissionId: props.match.params.permissionId,
      });
      if (response?.dataLink !== undefined && response.dataLink.length > 0) {
        return response.dataLink;
      }
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to get data permission ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    return undefined;
  };

  const handleRefresh = async () => {
    setLoadingResource(true);
    let permissionList = await fetchDataPermission();
    if (permissionList == undefined) {
      return;
    }
    let itemList = [];
    for (let permission of permissionList) {
      if (permission.resource?.redshiftTagPolicy !== undefined) {
        let policy = permission.resource?.redshiftTagPolicy;
        let tagKey = policy.tagKey;
        let tagValue = policy.tagValue;
        let catalogId = policy.catalogId;
        let region = permission.region;
        let sharedResources = await fetchTagResources(tagKey, tagValue, catalogId, region);
        itemList.push(...sharedResources);
      } else if (permission.resource?.dataCellsFilter !== undefined) {
        let dataCellFilter = permission.resource?.dataCellsFilter;
        let currentItem = {
          catalogId: dataCellFilter.tableCatalogId,
          clusterId: dataCellFilter.clusterId,
          databaseName: dataCellFilter.databaseName,
          schemaName: dataCellFilter.schemaName,
          tableName: dataCellFilter.tableName,
          dataPermissionId: props.match.params.permissionId,
          fgapName: dataCellFilter.name,
          fgapId: permission.fgapId,
          region: permission.region,
        };
        itemList.push(currentItem);
      }
    }
    setItems(itemList);
    setLoadingResource(false);
  };

  const fetchTagResources = async (tagKey, tagValue, catalogId, region) => {
    let request = {
      tagPair: tagKey + ':' + tagValue,
      catalogId: catalogId,
      region: region,
      type: REDSHIFT_TAG_TYPE,
    };
    let resources = await getTaggedResources(request);
    let taggedResourceList = resources.resources;
    let curItems = [];
    let catalogName = props.catalogMap.get(catalogId + ':' + region);
    for (let taggedResource of taggedResourceList) {
      if (taggedResource.resourceType == SCHEMA_RESOURCE_TYPE) {
        curItems.push({
          catalogId: taggedResource.catalogId,
          clusterId: taggedResource.clusterId,
          databaseName: taggedResource.databaseName,
          schemaName: taggedResource.schemaName,
          dataPermissionId: props.match.params.permissionId,
          catalogName: catalogName,
          resourceId: taggedResource.resourceId,
          region: region,
        });
      } else if (taggedResource.resourceType == DATASET_RESOURCE_TYPE) {
        curItems.push({
          catalogId: taggedResource.catalogId,
          clusterId: taggedResource.clusterId,
          databaseName: taggedResource.databaseName,
          schemaName: taggedResource.schemaName,
          tableName: taggedResource.resourceName,
          dataPermissionId: props.match.params.permissionId,
          catalogName: catalogName,
          resourceId: taggedResource.resourceId,
          region: region,
        });
      }
    }
    return curItems;
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No data</b>
          </div>
          <p className='awsui-util-mb-s'>No schemas/datasets were found in this permission.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Catalog name',
          key: 'catalogName',
          groupValuesLabel: 'Catalog names',
        },
        {
          propertyLabel: 'Database name',
          key: 'databaseName',
          groupValuesLabel: 'Database names',
        },
        {
          propertyLabel: 'Dataset name',
          key: 'tableName',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });

  const auditCurrentDataPermission = async () => {
    setLoadingButton(true);
    try {
      const response = await auditDataPermission({
        dataPermissionId: props.match.params.permissionId,
      });
      if (response.auditStatus === 'SUCCEEDED') {
        setNotifications([
          {
            type: 'success',
            content: `Audit of ${props.match.params.permissionId} succeeded`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        setNotifications([
          {
            type: 'error',
            content: `Audit of ${props.match.params.permissionId} failed`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
      await handleRefresh();
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
      setNotifications([
        {
          type: 'error',
          content: `Audit of ${props.match.params.permissionId} failed because ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedItem(selectedItem);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Audit data permission',
            onItemClick: auditCurrentDataPermission,
            loading: loadingButton,
          },
        ]}
        header={`Dataset Permission ID: ${props.match.params.permissionId}`}
      />
      <Table
        {...collectionProps}
        loadingText='Loading permission detail...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
              ]}
              header={
                <>
                  Shared Redshift data
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No shared data</p>
          </div>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      {dataPermission != null && dataPermissionDetail(dataPermission)}
    </>
  );
};
