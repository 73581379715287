import * as React from 'react';
import { useEffect, useState } from 'react';
import { PageHeader } from '../subscriptions/common';
import { listDependencies } from '../../api/dependency';
import BrowseDependenciesTable from './browseDependenciesTable';
import { Redirect } from 'react-router';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { Page } from '../../routes/Paths';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MyDependenciesProps {
  setContentType: any;
  activeGroup: string;
  groupInfo: any;
  username: string;
}

export const MyDependencies = (props: MyDependenciesProps) => {
  const [activeDependencies, setActiveDependencies] = useState([]);
  const [inactiveDependencies, setInactiveDependencies] = useState([]);
  const [loadingDependencies, setLoadingDependencies] = useState(true);
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    loadDependencies();
  }, []);

  useEffect(() => {
    loadDependencies();
  }, [props.activeGroup]);

  const loadDependencies = async () => {
    setLoadingDependencies(true);

    let nextToken = null;
    const listDependenciesRequest = {
      groupId: props.activeGroup,
      limit: 100,
      nextToken: nextToken,
    };

    const dependencies = [];

    try {
      do {
        listDependenciesRequest.nextToken = nextToken;
        const listDependenciesResponse = await listDependencies(listDependenciesRequest);
        nextToken = listDependenciesResponse.nextToken;
        dependencies.push(...listDependenciesResponse.dependencies);
      } while (typeof nextToken !== 'undefined');

      setActiveDependencies(dependencies.filter((dependency) => dependency.status === 'enabled'));

      setInactiveDependencies(dependencies.filter((dependency) => dependency.status === 'disabled'));

      setLoadingDependencies(false);
    } catch (err) {
      if (err.name == 'PermissionDeniedException') {
        setLoadingDependencies(false);
        setNotifications([
          {
            type: 'error',
            content: `${props.username} does not have the permission to view dependencies under group ${props.activeGroup}`,
            dismissible: false,
          },
        ]);
      }
    }
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    loadDependencies();
  };

  if (redirect) return <Redirect push to={redirect} />;

  if (props.activeGroup != 'AWSDL_Services') {
    return <h1>The page is under construction</h1>;
  }

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <br />
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Create dependency',
            variant: 'primary',
            onItemClick: () => {
              setRedirect(Page.CREATE_DEPENDENCY);
            },
          },
        ]}
        header={<div>Group dependencies: {props.activeGroup}</div>}
      />
      <BrowseDependenciesTable
        {...props}
        setContentType={(_) => {}}
        title='Active dependencies'
        dependencies={activeDependencies}
        loadingDependencies={loadingDependencies}
        activeGroup={props.activeGroup}
        tableType='active'
      />
      <br />
      <BrowseDependenciesTable
        {...props}
        setContentType={(_) => {}}
        title='Inactive dependencies'
        dependencies={inactiveDependencies}
        loadingDependencies={loadingDependencies}
        activeGroup={props.activeGroup}
        tableType='inactive'
      />
    </div>
  );
};
