import _ from 'lodash';
import { getResourceTags, listDataLakeRoleProperty, listDataPermissions } from 'src/api/permissions';
import { scheduleBaseline, submitBaseline } from 'src/api/notifications';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
} from 'src/commons/constants';
import { CardsProps } from '@amzn/awsui-components-react-v3/polaris/cards/interfaces';
import { Box, Button, PaginationProps, SpaceBetween } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

const RESOURCE_USER_BASELINE = 'RESOURCE_USER_BASELINE';

export const handleSelectedItem = (items, selectedItems) => {
  if (selectedItems.length == 0) {
    return [];
  } else {
    var realSelectedItems = [];
    for (var i = 0; i < selectedItems.length; i++) {
      for (var j = 0; j < items.length; j++) {
        if (_.isEqual(selectedItems[i], items[j])) {
          realSelectedItems.push(items[j]);
        }
      }
    }
    return realSelectedItems;
  }
};

export const getFieldOnChange =
  (fieldKey, onChangeFn) =>
  ({ detail: { selectedItems } }) => {
    onChangeFn({
      [fieldKey]: selectedItems,
    });
  };

export const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

export const escapeHtml = (string) => {
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };
  return String(string).replace(/[&<>"'`=\/]/g, function (s) {
    return entityMap[s];
  });
};

export const TABLE_EMPTY_STATE = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant='p' color='inherit'>
          No {resourceName.toLowerCase()}s found.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);

export const TABLE_NO_MATCH_STATE = (props) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No matches</b>
        <Box variant='p' color='inherit'>
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const ARIA_LABELS: CardsProps.AriaLabels<{ id: string }> = {
  itemSelectionLabel: (_data, row) => `select ${row.id}`,
  selectionGroupLabel: 'Items selection',
};

export const PAGINATION_ARIA_LABELS: (totalPages?: number) => PaginationProps.Labels = (totalPages) => ({
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: (pageNumber) => `Page ${pageNumber} of ${totalPages || 'all pages'}`,
});

export const getAllResourceTags = async (resource) => {
  const tags = [];
  let result = await getResourceTags({ resourceType: 'Database' });
  tags.push(...result.tags);
  result = await getResourceTags({ resourceType: 'Dataset' });
  tags.push(...result.tags);
  result = await getResourceTags({ resourceType: 'Schema' });
  tags.push(...result.tags);

  return tags.filter(function (tag) {
    return (
      tag.catalogId === resource.accountId &&
      tag.region === resource.region &&
      tag.statusCustomerType === 'Active:Publisher'
    );
  });
};

export const recursiveFetchDataPermissions = async (request) => {
  const dps = [];
  let result = await listDataPermissions(request);
  dps.push(...result.dataPermissionList);
  while (result.nextToken) {
    request.nextToken = result.nextToken;
    result = await listDataPermissions(request);
    dps.push(...result.dataPermissionList);
  }
  return dps;
};

export const fetchBaselineNeededConsumerDataPermissions = async (ownerIds) => {
  const dps = [];
  for (let ownerId of ownerIds) {
    dps.push(
      ...(await recursiveFetchDataPermissions({
        ownerId: ownerId,
        option: DATA_PERMISSION_CONSUMER_OPTION,
        baselineNeededOnly: true,
      })),
    );
  }
  return dps;
};

export const fetchBaselineNeededConsumerIAMDataPermissions = async (groupId) => {
  let role;
  try {
    role = await listDataLakeRoleProperty({ groupId: groupId });
  } catch (error) {
    // Group doesn't have any IAM data permissions
    if ('NotFoundException' != error.code || 404 != error.status) throw error;
  }
  const iamDps = await recursiveFetchDataPermissions({
    ownerId: groupId,
    option: DATA_PERMISSION_CONSUMER_OPTION,
    type: DATA_PERMISSION_IAM_TYPE,
  });

  if (role && role.approvedPermissions.length > 0) {
    for (let iamDp of iamDps) {
      if (!iamDp.resource || !iamDp.resource.table || !iamDp.region) continue;
      const tableResource = iamDp.resource.table;
      const datasetId = `DS-glue|A-${tableResource.catalogId}|DN-${tableResource.databaseName}|TN-${tableResource.name}|R-${iamDp.region}`;
      const index = role.approvedPermissions.indexOf(datasetId);
      // IAM data permission already baselined and backfilled as DataPermission.
      if (index > -1) role.approvedPermissions.splice(index, 1);
    }

    // IAM data permissions that still require a baseline review.
    // Represent each table as single DataPermission
    const dps = [];
    for (let datasetId of role.approvedPermissions) {
      const subs = datasetId.split('|');
      const catalogId = subs[1].slice(2);
      const databaseName = subs[2].slice(3);
      const tableName = subs[3].slice(3);
      const region = subs[4].slice(2);

      dps.push({
        dataPermissionId: groupId,
        ownerId: groupId,
        region: region,
        resource: {
          table: {
            catalogId: catalogId,
            databaseName: databaseName,
            name: tableName,
          },
        },
        type: DATA_PERMISSION_IAM_TYPE,
        hcDatasetId: datasetId,
        baselineInfo: {
          baselineDueDate: undefined,
          baselineStatus: undefined,
          baselineStatusReason: undefined,
          lastBaselineBy: undefined,
          lastBaselineTimestamp: undefined,
        },
      });
    }
    return dps;
  }
  return [];
};

export const fetchActiveConsumerDataPermissions = async (resource) => {
  if (!resource.accountId || !resource.region || !resource.dpType) return [];

  let dps = [];
  const request = {
    region: resource.region,
    status: DATA_PERMISSION_STATUS_ACTIVE,
    option: DATA_PERMISSION_CONSUMER_OPTION,
    type: resource.dpType,
    resource: null,
  };
  const resourceType = resource.type;

  switch (resourceType) {
    case 'DATABASE':
      request['resource'] = {
        table: {
          catalogId: resource.accountId,
          databaseName: resource.dataCatalogObjectDetails.databaseName,
        },
      };
      break;
    case 'TABLE':
      request['resource'] = {
        table: {
          catalogId: resource.accountId,
          databaseName: resource.dataCatalogObjectDetails.databaseName,
          name: resource.dataCatalogObjectDetails.tableName,
        },
      };
      const allTablesResource = { ...resource };
      allTablesResource.type = 'ALL_TABLES';
      dps.push(...(await fetchActiveConsumerDataPermissions(allTablesResource)));
      break;
    case 'ALL_TABLES':
      request['resource'] = {
        table: {
          catalogId: resource.accountId,
          databaseName: resource.dataCatalogObjectDetails.databaseName,
          tableWildcard: {},
        },
      };
      break;
    case 'TAG':
      if (resource.dpType === 'LF') {
        request['resource'] = {
          lFTagPolicy: {
            expression: [
              {
                tagKey: resource.tagDetails.tagKey,
                tagValues: [resource.tagDetails.tagValue],
              },
            ],
            catalogId: resource.accountId,
          },
        };
      } else {
        request['resource'] = {
          redshiftTagPolicy: {
            tagKey: resource.tagDetails.tagKey,
            tagValue: resource.tagDetails.tagValue,
            catalogId: resource.accountId,
          },
        };
      }
      break;
  }

  if (
    DATA_PERMISSION_LAKE_FORMATION_TYPE == resource.dpType ||
    (DATA_PERMISSION_REDSHIFT_TYPE == resource.dpType && request.resource && request.resource.redshiftTagPolicy)
  ) {
    dps.push(...(await recursiveFetchDataPermissions(request)));
  }
  dps = dps.filter(function (dp) {
    return dp.resourceType && dp.resourceType === 'EXTERNAL';
  });

  if (resourceType === 'DATABASE' || resourceType === 'TABLE' || resourceType === 'SCHEMA') {
    const finalTags = [];
    let resourceTags = await getAllResourceTags(resource);
    finalTags.push(
      ...resourceTags.filter(function (tag) {
        return tag.resourceId.startsWith(resource.tagResourceId) && tag.resourceId.endsWith(resource.region);
      }),
    );
    if (resourceType === 'TABLE') {
      finalTags.push(
        ...resourceTags.filter(function (tag) {
          return tag.resourceId === resource.tagUpperLevelResourceId;
        }),
      );
    }

    for (let tag of finalTags) {
      const tagResource = {
        type: 'TAG',
        accountId: resource.accountId,
        region: resource.region,
        dpType: resource.dpType,
        tagDetails: {
          tagKey: tag.tagKey,
          tagValue: tag.tagValue,
        },
      };
      dps.push(...(await fetchActiveConsumerDataPermissions(tagResource)));
    }
  }
  return dps;
};

export const submitResourceUserBaseline = async (baselineAction, resourceType, resourcesToBaseline) => {
  return await submitBaseline({
    baselineAction: baselineAction,
    baselineType: RESOURCE_USER_BASELINE,
    resourceType: resourceType,
    resourcesToBaseline: resourcesToBaseline,
  });
};

export const scheduleResourceUserBaseline = async (resource, endDate, message) => {
  return await scheduleBaseline({
    baselineType: RESOURCE_USER_BASELINE,
    resource: resource,
    scheduleInfo: {
      endDate: endDate,
      message: message,
    },
  });
};
