import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColumnLayout, Header, Icon, Table, Tabs } from '@amzn/awsui-components-react-v3';
import { CopiableText, getFoundryRoleARN } from 'src/commons/common';

import { getTaggedResources, getTags, listDataPermissions } from 'src/api/permissions';
import { PageHeader } from 'src/components/notifications/common';
import { createWSDatasetDetailLink, createWSGlueDatabaseDetailLink, createWSSchemaDetailLink } from 'src/routes';
import { Link } from 'react-router-dom';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  DATABASE_RESOURCE_TYPE,
  DATASET_RESOURCE_TYPE,
  LAKE_FORMATION_TAG_ITEM,
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_TAG_ITEM,
  REDSHIFT_TAG_TYPE,
  SCHEMA_RESOURCE_TYPE,
  TABLE_CONTENT_TYPE,
  TABLE_RESOURCE_TYPE,
} from 'src/commons/constants';

export interface WorkspaceTagDetailProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  cartItemIds: any;
  addToCart: any;
  catalogMap: any;
}

export const WorkspaceTagDetail = (props: WorkspaceTagDetailProps) => {
  const [tag, setTag] = useState(undefined);
  const [allItems, setItems] = useState([]);
  const [consumed, setConsumed] = useState(true);
  const [permissionId, setPermissionId] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchTag = async () => {
    let getTagsResult = await getTags({ ownerId: props.match.params.ownerid });
    let res = getTagsResult.tags.filter((item) => item.tagId == props.match.params.tagid);
    let tag = res[0];
    if (tag != null && tag.tagType == null) {
      tag.tagType = LAKEFORMATION_TAG_TYPE;
    }
    setTag(tag);
    let resourceList = [];
    let getTaggedResourcesResponse = await getTaggedResources({
      tagPair: tag.tagPair,
      catalogId: tag.catalogId,
      region: tag.region,
      type: tag.tagType != null ? tag.tagType : LAKEFORMATION_TAG_TYPE,
    });
    for (let resource of getTaggedResourcesResponse.resources) {
      if (resource.clusterId != null) {
        if (resource.resourceType == DATASET_RESOURCE_TYPE) {
          resourceList.push({
            type: resource.resourceType,
            resource: `${props.catalogMap.get(`${tag.catalogId}:${resource.clusterId}:${tag.region}`)}.${
              resource.clusterId
            }.${resource.databaseName}.${resource.schemaName}.${resource.resourceName}`,
            link: createWSDatasetDetailLink(
              `DS-redshift|A-${tag?.catalogId}|CI-${resource?.clusterId}|DN-${resource?.databaseName}|SN-${resource?.schemaName}|TN-${resource?.resourceName}|R-${tag?.region}`,
            ),
          });
        } else if (resource.resourceType == SCHEMA_RESOURCE_TYPE) {
          resourceList.push({
            type: resource.resourceType,
            resource: `${props.catalogMap.get(`${tag.catalogId}:${resource.clusterId}:${tag.region}`)}.${
              resource.clusterId
            }.${resource.databaseName}.${resource.resourceName}`,
            link: createWSSchemaDetailLink(
              tag.catalogId,
              resource.clusterId,
              resource.databaseName,
              resource.resourceName,
              tag.region,
            ),
          });
        }
      } else {
        if (resource.resourceType == DATASET_RESOURCE_TYPE) {
          resourceList.push({
            type: resource.resourceType,
            resource: `${props.catalogMap.get(`${tag.catalogId}:${tag.region}`)}/${resource.databaseName}/${
              resource.resourceName
            }`,
            link: createWSDatasetDetailLink(
              `DS-glueLF|A-${tag?.catalogId}|DN-${resource?.databaseName}|TN-${resource?.resourceName}|R-${tag?.region}`,
            ),
          });
        } else if (resource.resourceType == DATABASE_RESOURCE_TYPE) {
          resourceList.push({
            type: resource.resourceType,
            resource: `${props.catalogMap.get(`${tag.catalogId}:${tag.region}`)}/${resource.databaseName}`,
            link: createWSGlueDatabaseDetailLink(tag.catalogId, resource.databaseName, tag.region),
          });
        }
      }
    }
    setItems(resourceList);
    let permissionData = await verifyRegisteredTag(tag);
    setPermissionId(permissionData.permissionId);
    setConsumed(permissionData.consumed);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await fetchTag();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setLoading(false);
  };

  const getDatabaseDetailsMetadata = () => {
    const databaseDetails = [];
    if (tag?.catalogId) {
      databaseDetails.push(<CopiableText name={'Catalog Id'} key={'Catalog Id'} value={tag?.catalogId} />);
    }
    if (tag?.region) {
      databaseDetails.push(<CopiableText name={'Region'} key={'Region'} value={tag?.region} />);
    }
    if (tag?.status) {
      databaseDetails.push(<CopiableText name={'Status'} key={'Status'} value={tag?.status} />);
    }
    if (tag?.tagKey) {
      databaseDetails.push(<CopiableText name={'Key'} key={'Key'} value={tag?.tagKey} />);
    }
    if (tag?.tagValue) {
      databaseDetails.push(<CopiableText name={'Value'} key={'Value'} value={tag?.tagValue} />);
    }
    if (tag?.tagType) {
      databaseDetails.push(<CopiableText name={'Type'} key={'Type'} value={tag?.tagType} />);
    }
    return databaseDetails;
  };

  const databaseDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <div className='awsui-util-container'>
            <div className='awsui-util-container-header'>
              <div className='awsui-util-action-stripe'>
                <div className='awsui-util-action-stripe-title'>
                  <h2>Tag details</h2>
                </div>
              </div>
            </div>
            <ColumnLayout columns={3} borders='horizontal'>
              {getDatabaseDetailsMetadata()}
            </ColumnLayout>
          </div>
        ),
      },
      {
        label: 'Resources',
        id: 'resources',
        content: (
          <Table
            columnDefinitions={[
              {
                id: 'resource',
                header: 'Resource',
                cell: (item) => <Link to={item.link}>{item.resource}</Link>,
              },
              {
                id: 'type',
                header: 'Resource type',
                cell: (item) => item.type,
              },
            ]}
            items={allItems}
            loadingText='Loading resources'
            sortingDisabled
            header={<Header> Resources </Header>}
            empty={
              <div className='awsui-util-t-c'>
                <p className='awsui-util-mb-s'>No resources</p>
              </div>
            }
          />
        ),
      },
    ];

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const verifyRegisteredTag = async (tag) => {
    let listTagPermissionPublisherRequest;

    let listTagPermissionConsumerRequest;

    if (tag.tagType != LAKEFORMATION_TAG_TYPE) {
      listTagPermissionPublisherRequest = {
        ownerId: props.match.params.ownerid,
        region: tag.region,
        resource: {
          redshiftTagPolicy: {
            tagKey: tag.tagKey,
            tagValue: tag.tagValue,
            catalogId: tag.catalogId,
          },
        },
        option: DATA_PERMISSION_PUBLISHER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: REDSHIFT_TAG_TYPE,
        nextToken: null,
      };
      listTagPermissionConsumerRequest = {
        ownerId: props.activeWorkspace.workspaceId,
        region: tag.region,
        resource: {
          redshiftTagPolicy: {
            tagKey: tag.tagKey,
            tagValue: tag.tagValue,
            catalogId: tag.catalogId,
          },
        },
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: REDSHIFT_TAG_TYPE,
        nextToken: null,
      };
    } else {
      listTagPermissionPublisherRequest = {
        ownerId: props.match.params.ownerid,
        region: tag.region,
        resource: {
          lFTagPolicy: {
            resourceType: TABLE_RESOURCE_TYPE,
            expression: [
              {
                tagKey: tag.tagKey,
                tagValues: [tag.tagValue],
              },
            ],
            catalogId: tag.catalogId,
          },
        },
        option: DATA_PERMISSION_PUBLISHER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: LAKEFORMATION_TAG_TYPE,
        nextToken: null,
      };
      listTagPermissionConsumerRequest = {
        ownerId: props.match.params.ownerid,
        region: tag.region,
        resource: {
          lFTagPolicy: {
            resourceType: TABLE_RESOURCE_TYPE,
            expression: [
              {
                tagKey: tag.tagKey,
                tagValues: [tag.tagValue],
              },
            ],
            catalogId: tag.catalogId,
          },
        },
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: LAKEFORMATION_TAG_TYPE,
        nextToken: null,
      };
    }

    let consumed = props.cartItemIds.includes(`${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`);
    let permissionId = undefined;
    try {
      let listTagPermissionPublisherResponse = await listDataPermissions(listTagPermissionPublisherRequest);

      let listTagPermissionConsumersResponse = await listDataPermissions(listTagPermissionConsumerRequest);

      if (
        listTagPermissionPublisherResponse?.dataPermissionList !== undefined &&
        listTagPermissionPublisherResponse?.dataPermissionList.length != 0
      ) {
        permissionId = listTagPermissionPublisherResponse.dataPermissionList[0].dataPermissionId;
      }
      if (
        listTagPermissionConsumersResponse?.dataPermissionList !== undefined &&
        listTagPermissionConsumersResponse?.dataPermissionList.length != 0
      ) {
        consumed = true;
      }
    } catch (err) {
      console.log('Exception list tag permission', err);
      return permissionId;
    }
    return { permissionId: permissionId, consumed: consumed };
  };

  const flattenTagAccess = async (tag, permissionId) => {
    let item = {
      key: tag.tagKey,
      value: tag.tagValue,
      tag: tag.tagKey + '.' + tag.tagValue,
      owner: props.match.params.ownerid,
      // id: key + "." + value + "." + catalogId,
      permissionId: permissionId,
      itemType: tag.tagType == LAKEFORMATION_TAG_TYPE ? LAKE_FORMATION_TAG_ITEM : REDSHIFT_TAG_ITEM,
      // permissions: ['select', 'describe'],
      resources: allItems.map((item) => item.resource),
      catalogDisplayName: tag.catalogId,
      dataOwnerRole: getFoundryRoleARN(tag.region, tag.catalogId),
      catalogId: tag.catalogId,
      catalogName: tag.catalogId,
      id: `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`,
      region: tag.region,
    };
    return item;
  };

  const handleAddToCart = async () => {
    let cartItem = await flattenTagAccess(tag, permissionId);
    await fetchTag();
    setButtonDisabled(true);
    props.addToCart([cartItem]);
  };

  let lakeFormationIcon = require('src/components/common/LakeFormation.png');
  let redshiftIcon = require('src/components/common/Redshift.png');

  if (!tag && !loading) {
    return (
      <>
        <h2>Tag not found</h2>
        The given tag is not valid, or you do not have permission to view it. Please check the URL for mistakes and try
        again.
      </>
    );
  }

  return (
    <>
      <PageHeader
        buttons={[
          {
            text: 'Add tag to cart',
            onItemClick: handleAddToCart,
            disabled: consumed || permissionId == null || buttonDisabled,
          },
        ]}
        header={
          <Header variant={'h1'}>
            <Icon url={tag?.tagType == LAKEFORMATION_TAG_TYPE ? lakeFormationIcon : redshiftIcon} size='big' />{' '}
            {tag?.tagPair}
          </Header>
        }
      />
      {databaseDetail()}
    </>
  );
};
