import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
  Spinner,
  Flashbar,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { largePageSizePreference, defaultWrapLinesPreference, i18nStrings, paginationLabels } from 'src/commons/tables';

import { listCatalogs, listDatabases } from '../../../src/api/catalog';
import { Link } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { compareBy } from '../utils/sorting';
import {
  DATA_LOAD_LIMIT,
  DATABASE_LOAD_LIMIT,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { createCatalogDetailLink, createDatabaseDetailLink } from 'src/routes';
import { DefaultRouteProps } from 'src/commons/common';

export interface BrowseDatabasesTableProps extends DefaultRouteProps {
  setContentType: any;
  title: string;
  loading?: boolean;
  filter?: object[];
  doNotLinkCatalogs?: boolean;
}

const BrowseDatabasesTable = (props: BrowseDatabasesTableProps) => {
  const [allItems, setItems] = useState([]);
  const [, setCatalogs] = useState([]);
  const [catalogsMap] = useState(new Map()); // Will use <catalogId>:<region> -> catalogInfo
  const [notifications, setNotifications] = useState([]);
  const [redirect] = useState(undefined);
  const [databasesLoading, setDatabasesLoading] = useState(true);
  const [catalogsLoading, setCatalogsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link to={createDatabaseDetailLink(item?.catalogId, item?.databaseName)}>{item.databaseName}</Link>
      ),
      minWidth: 200,
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
    {
      id: 'catalogDisplayName',
      header: 'Catalog name',
      cell: (item) =>
        props.doNotLinkCatalogs ? (
          getCatalogNameUnlessLoading(item.catalogId, item.region)
        ) : (
          <Link to={createCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogDisplayName}</Link>
        ),
      minWidth: 100,
      sortingField: 'catalogDisplayName',
      sortingComparator: compareBy('catalogDisplayName', 'databaseName'),
    },
  ];

  // simplifies the database objects in order to make the rest of the code a bit cleaner
  const flatten = (items) => {
    const flat = items.map((item) => ({
      databaseName: item.DatabaseName,
      catalogId: item.CatalogId,
      region: item.Region,
      catalogDisplayName: getCatalogName(item.CatalogId, item.Region),
      description: item.Description,
    }));
    return flat;
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [props.filter]);

  // Fetch databasaes and then their catalogs
  const fetchAllData = async () => {
    await fetchDatabases();
    await fetchCatalogsForDatabases();
  };

  // Gets the catalogs relevant to the databases currently in state.items
  const fetchCatalogsForDatabases = async () => {
    setCatalogsLoading(true);

    // Use a set to make them unique
    const catalogIdRegions = new Set<string>();
    for (const item of allItems) {
      if (item != null && item.CatalogId != null && item.Region != null) {
        const key: string = item.CatalogId + ':' + item.Region;
        catalogIdRegions.add(key);
      }
    }

    // Start to build a list of catalog keys for the request
    const catalogKeys = [];
    for (const idRegion of catalogIdRegions) {
      const catalogIdAndRegion = idRegion.split(':');
      const catalogId = catalogIdAndRegion[0];
      const region = catalogIdAndRegion[1];
      // Non-null if there's a match
      if (/^\d{12}$/.exec(catalogId) != null && /^\w(\w|-)*$/.exec(region) != null) {
        catalogKeys.push({
          CatalogId: catalogId,
          Region: region,
        });
      }
    }
    let catalogInfoList = [];
    const request = {
      Filter: {
        CatalogKeyList: catalogKeys,
      },
      NextToken: null,
    };
    let result = await listCatalogs(request);
    catalogInfoList = result.CatalogInfoList;
    while (result.NextToken != null) {
      request.NextToken = result.NextToken;
      result = await listCatalogs(request);
      catalogInfoList.push(...result.CatalogInfoList);
    }
    // Populate a map with our new catalogs
    catalogsMap.clear();
    for (let i = 0; i < catalogInfoList.length; i++) {
      addCatalogItemToMap(catalogInfoList[i]);
    }

    setCatalogs(catalogInfoList);
    setCatalogsLoading(false);
  };

  const fetchDatabases = async () => {
    setDatabasesLoading(true);
    try {
      if (props.filter) {
        let databaseList = [];
        setTotalCount(props.filter.length);
        for (let index = 0; index < props.filter.length; index += DATABASE_LOAD_LIMIT) {
          let databases = await listDatabases({
            DatabaseKeyList: props.filter.slice(index, index + DATABASE_LOAD_LIMIT),
            Limit: DATABASE_LOAD_LIMIT,
            IncludeTotalCount: true,
          });
          databaseList.push(
            ...databases.DatabaseInfoList.filter((item) => item.DataSourceId != REDSHIFT_DATASOURCE_ID),
          );
        }
        setNextToken(null);
        setAllDataLoaded(true);
        setItems(databaseList);
      } else {
        let databases = await listDatabases({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
        setNextToken(databases.NextToken);
        if (databases.NextToken == null) {
          setAllDataLoaded(true);
        }
        let databaseList = databases.DatabaseInfoList.filter((item) => item.DataSourceId != REDSHIFT_DATASOURCE_ID);
        setTotalCount(databases.TotalCount);
        setItems(databaseList);
      }
    } catch (e) {
      setDatabasesLoading(false);
      console.log('Exception when fetch databases', e);
      setNotifications([
        {
          type: 'error',
          content: `Error when trying to fetch databases: ${e.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setDatabasesLoading(false);
  };

  const loadMoreData = async () => {
    if (props.filter) {
      return;
    }
    setPaginationDisabled(true);
    setDatabasesLoading(true);
    let databases = await listDatabases({ Limit: DATA_LOAD_LIMIT, NextToken: nextToken });
    setNextToken(databases.NextToken);
    let databaseList = databases.DatabaseInfoList.filter((item) => item.DataSourceId != REDSHIFT_DATASOURCE_ID);
    let currentItems = allItems;
    currentItems.push(...databaseList);
    setItems(currentItems);
    if (databases.NextToken == null) {
      setAllDataLoaded(true);
    }
    setDatabasesLoading(false);
    setPaginationDisabled(false);
  };

  const getCatalogNameUnlessLoading = (catalogId: string, region: string) => {
    if (catalogsLoading) {
      return <Spinner size='normal' />;
    } else {
      return getCatalogName(catalogId, region);
    }
  };

  const getCatalogName = (catalogId: string, region: string) => {
    const catalog = getCatalogFromMap(catalogId, region);
    if (catalog == null) {
      return String(catalogId);
    } else {
      return String(catalog.Name);
    }
  };

  const addCatalogItemToMap = (item) => {
    const catalogId: string = item.CatalogId;
    const region: string = item.Region;
    catalogsMap.set(catalogId + ':' + region, item);
  };

  const getCatalogFromMap = (catalogId: string, region: string) => {
    return catalogsMap.get(catalogId + ':' + region);
  };

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      filtering: {
        noMatch: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No matches</b>
            </div>
            <p className='awsui-util-mb-s'>We can’t find a match.</p>
          </div>
        ),
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No databases</b>
            </div>
            <p className='awsui-util-mb-s'>No databases were found in the catalog.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogDisplayName',
            groupValuesLabel: 'Catalog names',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            {props.title}
            <span className='awsui-util-header-counter'>
              {` (${totalCount}${!allDataLoaded && props.filter ? '+' : ''})`}
            </span>
          </h2>
        }
        loading={props.loading || databasesLoading || catalogsLoading}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover databases, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            openEnd={!allDataLoaded}
            disabled={paginationDisabled}
          />
        }
      />
    </>
  );
};

export default BrowseDatabasesTable;
