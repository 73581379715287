import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  SpaceBetween,
  Button,
  AlertProps,
  Modal,
  Box,
  Alert,
  FormField,
  DatePicker,
  Input,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Link, Redirect } from 'react-router-dom';
import {
  ARIA_LABELS,
  escapeHtml,
  fetchActiveConsumerDataPermissions,
  getFilterCounterText,
  PAGINATION_ARIA_LABELS,
  scheduleResourceUserBaseline,
  TABLE_EMPTY_STATE,
  TABLE_NO_MATCH_STATE,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

export interface DataConsumersTableProps {
  setContentType: any;
  resource: object;
}

export const DataConsumersTable = (props: DataConsumersTableProps) => {
  const rawColumns = [
    {
      id: 'dataPermissionId',
      sortingField: 'dataPermissionId',
      header: 'Data permission ID',
      cell: (item) => (
        <div>
          <Link to={getInfoFromDP(item, 'dataPermissionLink')}>{item.dataPermissionId}</Link>
        </div>
      ),
      minWidth: 250,
    },
    {
      id: 'consumerId',
      header: 'Consumer ID',
      cell: (item) => <Link to={getInfoFromDP(item, 'ownerIdLink')}>{item.ownerId}</Link>,
      minWidth: 250,
    },
    {
      id: 'consumerPrincipal',
      header: 'Consumer principal',
      cell: (item) => item.dataLakePrincipal,
      minWidth: 200,
    },
    {
      id: 'permissionType',
      cell: (item) => getInfoFromDP(item, 'permissionType'),
      header: 'Permission type',
      minWidth: 190,
    },
    {
      id: 'baselineDueDate',
      cell: (item) => item.baselineInfo.baselineDueDate,
      header: 'Baseline due date',
      minWidth: 200,
    },
    {
      id: 'baselineStatus',
      header: 'Baseline status',
      cell: (item) => item.baselineInfo.baselineStatus,
      minWidth: 100,
    },
    {
      id: 'baselineStatusReason',
      header: 'Baseline status reason',
      cell: (item) => item.baselineInfo.baselineStatusReason,
      minWidth: 200,
    },
    {
      id: 'lastBaselinedDate',
      header: 'Last baselined date',
      cell: (item) => item.baselineInfo.lastBaselineTimestamp,
      minWidth: 100,
    },
    {
      id: 'lastBaselinedBy',
      header: 'Last baselined by',
      cell: (item) => item.baselineInfo.lastBaselineBy,
      minWidth: 100,
    },
  ];

  function createTableSortLabelFn(
    column: TableProps.ColumnDefinition<unknown>,
  ): TableProps.ColumnDefinition<unknown>['ariaLabel'] {
    if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
      return;
    }
    return ({ sorted, descending }) => {
      return `${column.header}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
    };
  }

  const COLUMN_DEFINITIONS = rawColumns.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const DEFAULT_PREFERENCES = {
    pageSize: 30,
    contentDisplay: [
      { id: 'dataPermissionId', visible: true },
      { id: 'consumerId', visible: true },
      { id: 'consumerPrincipal', visible: true },
      { id: 'permissionType', visible: true },
      { id: 'baselineDueDate', visible: true },
      { id: 'baselineStatus', visible: true },
      { id: 'baselineStatusReason', visible: true },
      { id: 'lastBaselinedDate', visible: false },
      { id: 'lastBaselinedBy', visible: false },
    ],
    wrapLines: false,
    stripedRows: false,
    stickyColumns: { first: 0, last: 0 },
  };

  const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Consumers' },
    { value: 30, label: '30 Consumers' },
    { value: 50, label: '50 Consumers' },
  ];

  const [allItems, setAllItems] = useState([]);
  const [loadingDataConsumers, setLoadingDataConsumers] = useState(true);
  const [loadingScheduleBaseline, setLoadingScheduleBaseline] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success' as AlertProps.Type);
  const [redirect] = useState(undefined);
  const [baselineScheduleDueDate, setBaselineScheduleDueDate] = React.useState('');
  const [baselineScheduleMessage, setBaselineScheduleMessage] = React.useState('');

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TABLE_EMPTY_STATE resourceName='Consumer' />,
        noMatch: <TABLE_NO_MATCH_STATE onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[0] } },
      selection: {},
    },
  );

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.resource]);

  const getInfoFromDP = (dp, infoName) => {
    if (!dp) return null;

    switch (infoName) {
      case 'dataPermissionLink':
        if (dp.ownerId.startsWith('wks-'))
          return `/workspaces-permission/${dp.type.toLowerCase()}/${dp.dataPermissionId}`;
        else return `/mydatasets/${dp.dataPermissionId}`;

      case 'ownerIdLink':
        if (dp.ownerId.startsWith('wks-')) return `/search/workspaces/${dp.ownerId}`;
        else return `/groups/${dp.ownerId}`;

      case 'permissionType':
        if (dp.resource.lFTagPolicy || dp.resource.redshiftTagPolicy) return 'Tag permission';
        return 'Resource permission';
    }
  };

  const handleRefresh = async () => {
    try {
      setLoadingDataConsumers(true);
      if (!props.resource) return;
      const dps = await fetchActiveConsumerDataPermissions(props.resource);
      setAllItems(dps);
      setLoadingDataConsumers(false);
    } catch (err) {
      console.error(err);
      setLoadingDataConsumers(false);
    }
  };

  const handleScheduleBaseline = async () => {
    setModalVisible(true);
    const nowZ = new Date();
    const nowZPlus21Days = new Date().setDate(nowZ.getDate() + 21);
    const nowZPlus90Days = new Date().setDate(nowZ.getDate() + 90);
    const baselineDueDate = Date.parse(baselineScheduleDueDate);
    if (Number.isNaN(baselineDueDate) || baselineDueDate < nowZPlus21Days || baselineDueDate > nowZPlus90Days) {
      changeAlert('error', 'Invalid baseline due date found.');
      return;
    }

    if (!baselineScheduleMessage.trim().length) {
      changeAlert('error', 'Invalid message found.');
      return;
    }

    try {
      setLoadingScheduleBaseline(true);
      const resource = { ...props.resource };
      // delete keys use in frontend only
      delete resource['dpType'];
      delete resource['tagResourceId'];
      delete resource['tagUpperLevelResourceId'];

      await scheduleResourceUserBaseline(resource, baselineScheduleDueDate, escapeHtml(baselineScheduleMessage));
      changeAlert('success', 'Successfully scheduled baseline.');
    } catch (err) {
      console.error(err);
      changeAlert('error', err.message);
    }
    setLoadingScheduleBaseline(false);
  };

  const getScheduleBaselineModal = () => {
    return (
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel='Close modal'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setModalVisible(false)} disabled={loadingScheduleBaseline}>
                Cancel
              </Button>
              <Button variant='primary' onClick={handleScheduleBaseline} loading={loadingScheduleBaseline}>
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Are you sure you want to schedule?'
      >
        <div style={{ marginBottom: '10px' }}>
          <Alert type={alertType} dismissible={true} visible={alertVisible} onDismiss={() => setAlertVisible(false)}>
            {alertMessage}
          </Alert>
        </div>

        <Alert type='warning'>
          {`Proceeding with this action will override all ${allItems.length} consumers' Omni quarterly baseline schedule. Do you want to continue?`}
        </Alert>
        <FormField
          label='Select a baseline due date (UTC) with at least 21 days and at most 90 days from now'
          constraintText='Use YYYY/MM/DD format.'
        >
          <DatePicker
            onChange={({ detail }) => setBaselineScheduleDueDate(detail.value)}
            value={baselineScheduleDueDate}
            openCalendarAriaLabel={(selectedDate) =>
              'Choose baseline due date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
            }
            nextMonthAriaLabel='Next month'
            placeholder='YYYY/MM/DD'
            previousMonthAriaLabel='Previous month'
            todayAriaLabel='Today'
          />
        </FormField>
        <FormField label='Provide a brief message to describe why this baseline is needed'>
          <Input value={baselineScheduleMessage} onChange={(event) => setBaselineScheduleMessage(event.detail.value)} />
        </FormField>
      </Modal>
    );
  };

  const changeAlert = (type, message) => {
    setAlertType(type);
    setAlertVisible(true);
    setAlertMessage(message);
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      {getScheduleBaselineModal()}
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={COLUMN_DEFINITIONS}
        ariaLabels={ARIA_LABELS}
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <Button
                onClick={() => {
                  setModalVisible(true);
                  setAlertVisible(false);
                }}
                disabled={loadingDataConsumers || !allItems.length}
              >
                Schedule baseline
              </Button>
            }
            counter={`(${items.length})`}
          >
            Consumers
          </Header>
        }
        loadingText='Loading consumers'
        loading={loadingDataConsumers}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter consumers'
            filteringPlaceholder='Find consumers'
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            disabled={loadingDataConsumers}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SIZE_OPTIONS,
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Select to see all the text and wrap the lines',
            }}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_ARIA_LABELS(paginationProps.pagesCount)} />}
      />
    </>
  );
};
