import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';

export interface monthlyViewProps {
  setContentType: any;
  match: any;
}

export const MonthlyView = (props: monthlyViewProps) => {
  return <MetricTableView props={props} contentType='MONTHLY' />;
};
