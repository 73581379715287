import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { ManageRoles } from 'src/components/groups/manageRoles';
import { getGroupInfo, getGroupMembers, getWorkspace } from 'src/api/permissions';
import {
  GetGroupMembersResult,
  GetGroupResult,
  GetWorkspaceResult,
  RoleMappingMap,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import {
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  Link,
  Spinner,
  Wizard,
} from '@amzn/awsui-components-react-v3';
import { BaselineDataAccessPermission } from 'src/components/permissions/myBaselining/baselineDataAccessPermission';
import { EmptyState } from 'src/commons/EmptyState';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  MembershipMap,
  Reason,
  UserMembershipModal,
} from 'src/components/permissions/myBaselining/userMembershipModal';
import { createOwnerLink, Page } from 'src/routes';
import { getWorkspaceDisplayName } from 'src/commons/common';

export interface BaselineMembershipProps {
  catalogMap: any;
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const BaselineOwner = (props: BaselineMembershipProps) => {
  const [loadingBaseline, setLoadingBaseline] = useState(true);
  const [redirect, setRedirect] = useState(undefined);
  const [groupInfo, setGroupInfo] = useState<GetGroupResult | undefined>(undefined);
  const [workspace, setWorkspace] = useState<GetWorkspaceResult | undefined>(undefined);
  const [groupMembers, setGroupMembers] = useState<GetGroupMembersResult | undefined>(undefined);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const ownerId = props.match.params.ownerId;

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.match.params.ownerId]);

  const handleRefresh = async () => {
    try {
      setLoadingBaseline(true);
      if (!ownerId) return;
      let groupId = ownerId;

      if (isValidWorkspace(ownerId)) {
        let getWorkspaceResponse = await getWorkspace({
          workspaceId: ownerId,
        });
        groupId = getWorkspaceResponse?.workspace?.groupId;
        setWorkspace(getWorkspaceResponse);
      }

      const groupPromise = getGroupInfo({
        groupId: groupId,
      });
      const groupMembersPromise = getGroupMembers({
        groupId: groupId,
      });
      const [group, groupMembers] = await Promise.all([groupPromise, groupMembersPromise]);

      setGroupMembers(groupMembers);
      setGroupInfo(group);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBaseline(false);
    }
  };

  const getHumanMembers = () => {
    const humanMembers = groupMembers?.groupMembers
      ?.filter(
        (user) =>
          (user?.memberWorkspaceIds?.includes(ownerId) || user?.memberGroupIds?.includes(ownerId)) &&
          user?.userId?.indexOf(':') === -1,
      )
      .map((user) => user.userId)
      .sort()
      .map((user) => (
        <div key={user}>
          {
            <Button
              variant='inline-link'
              iconName='user-profile-active'
              onClick={() => {
                setSelectedUser(user);
                setUserModalVisible(true);
              }}
            >
              {user}
            </Button>
          }
        </div>
      ));
    return humanMembers?.length ? (
      <ColumnLayout borders='all' columns={4}>
        {humanMembers}
      </ColumnLayout>
    ) : (
      <EmptyState subtitle={'No human users found'} title={'No human users'} />
    );
  };

  let groupings: MembershipMap = {};
  let targetRoleMapping: RoleMappingMap;
  let additionalReasons: Reason[] = [];

  const addOrUpdate = (obj: MembershipMap, key: string, val: Reason) => {
    if (key in obj) {
      obj[key].push(val);
    } else {
      obj[key] = [val];
    }
  };

  const addRoleMembership = (mapping: RoleMappingMap, ownerId: string) => {
    if (mapping) {
      for (const [name, role] of Object.entries(mapping)) {
        if (role?.groupingMechanismIds) {
          role.groupingMechanismIds.forEach((grp) =>
            addOrUpdate(
              groupings,
              grp,
              <>
                Is associated to Omni role <b>{name.split('#').pop()}</b> of {ownerId}
              </>,
            ),
          );
        }
        if (role?.roleMembers && role.roleMembers.includes(selectedUser)) {
          additionalReasons.push(
            <>
              Is added directly to Omni role <b>{name.split('#').pop()}</b> of {ownerId}
            </>,
          );
        }
      }
    }
  };

  if (isValidWorkspace(ownerId)) {
    if (workspace?.workspace?.owner) {
      addOrUpdate(
        groupings,
        workspace.workspace.owner,
        <>
          Is owner of workspace{' '}
          {
            <Link external={true} href={createOwnerLink(ownerId)}>
              {getWorkspaceDisplayName(workspace.workspace.workspaceName, ownerId)}
            </Link>
          }
        </>,
      );
    }
    targetRoleMapping = workspace?.workspace?.roleMapping;
    addRoleMembership(targetRoleMapping, ownerId);
  } else {
    // group
    targetRoleMapping = groupInfo?.roleMapping;
  }

  if (groupInfo?.groupingMechanismId) {
    addOrUpdate(
      groupings,
      groupInfo.groupingMechanismId,
      <>
        Is owner of group{' '}
        {
          <Link external={true} href={createOwnerLink(groupInfo.groupId)}>
            {groupInfo.groupId}
          </Link>
        }
      </>,
    );
    addRoleMembership(groupInfo.roleMapping, groupInfo.groupId);
  }

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <UserMembershipModal
        // groupings={[...new Set(groupings)]}
        onClose={() => {
          setUserModalVisible(false);
        }}
        userId={selectedUser}
        visible={userModalVisible}
        membership={groupings}
        additionalReasons={additionalReasons}
      />
      <Wizard
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          navigationAriaLabel: 'Steps',
          cancelButton: 'Cancel',
          previousButton: 'Previous',
          nextButton: 'Next',
          optional: 'optional',
        }}
        submitButtonText='Finish'
        onSubmit={() => setRedirect(Page.BASELINING)}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: 'Review users',
            info: <Link variant='info'>Info</Link>,
            description:
              'Review the list of users that have access to your group/workspace. If you see any user that should not have access, you can remove it in the next steps',
            content: (
              <ContentLayout
                header={
                  <Container
                    header={
                      <Header
                        variant='h2'
                        description='This is the list of human users that have access to at least one of your Omni roles or POSIX/Teams.'
                      >
                        Human users with access to {ownerId}
                      </Header>
                    }
                  >
                    {loadingBaseline ? <Spinner /> : getHumanMembers()}
                  </Container>
                }
              ></ContentLayout>
            ),
          },
          {
            title: 'Review Omni roles',
            description:
              'Review the Omni roles from this group/workspace. You can edit individual roles to remove users/groups.',
            content: (
              <ManageRoles
                ownerId={ownerId}
                roleMapping={targetRoleMapping}
                allowAdd={false}
                allowEdit={false}
                headerText={`Baseline Omni roles for ${ownerId}`}
                description={
                  'Review that the right people and IAM principals have the right access for each of the Omni roles. Click on each of the roles and remove any people that should no longer have access. ' +
                  'For each of the grouping mechanisms (POSIX, Teams), click on the link and review that the right people belong to the POSIX/Team.'
                }
              />
            ),
          },
          {
            title: 'Review data access permissions',
            description:
              'Select the datasets you wish to keep or relinquish, then click the corresponding "Keep" or "Relinquish" button.',
            content: <BaselineDataAccessPermission {...props} />,
          },
        ]}
      />
    </>
  );
};
