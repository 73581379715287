import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { getTaggedResources, listDataPermissions } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  DATASET_RESOURCE_TYPE,
  REDSHIFT_RESOURCE,
  REDSHIFT_TAG_ITEM,
  REDSHIFT_TAG_TYPE,
  SCHEMA_RESOURCE_TYPE,
} from 'src/commons/constants';
import { Link } from 'react-router-dom';
import { createWSDatasetDetailLink, createWSSchemaDetailLink } from 'src/routes';
import { getConsumerDataLakePrincipal, getFoundryRoleARN } from 'src/commons/common';

export interface AccessSelectModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  dismiss: any;
  schema: any;
  addToCart: any;
  catalogName: any;
  tags: any[];
  cartItemIds: any;
}

export const SchemaAccessSelectModal = (props: AccessSelectModalProps) => {
  const [selectedPermission, setSelectedPermission] = useState(undefined);
  const [loadingResources, setLoadingResources] = useState(false);
  const [allItems, setItems] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  let flattenTagAccess = async (catalogId, catalogName, permissionId, key, value, owner, id) => {
    let resources = [];
    let getTaggedResourcesResponse = await getTaggedResources({
      tagPair: key + ':' + value,
      catalogId: catalogId,
      region: props.schema['Region'],
      type: REDSHIFT_TAG_TYPE,
    });
    for (let resource of getTaggedResourcesResponse.resources) {
      if (resource['resourceType'] == DATASET_RESOURCE_TYPE) {
        resources.push(
          `${catalogName}.${resource['databaseName']}.${resource['schemaName']}.${resource['resourceName']}`,
        );
      } else if (resource['resourceType'] == SCHEMA_RESOURCE_TYPE) {
        resources.push(`${catalogName}.${resource['databaseName']}.${resource['resourceName']}`);
      }
    }
    let item = {
      key: key,
      value: value,
      tagKey: key,
      tagValue: value,
      tag: key + '.' + value,
      owner: owner,
      permissionId: permissionId,
      itemType: REDSHIFT_TAG_ITEM,
      resources: resources,
      catalogDisplayName: catalogName,
      dataOwnerRole: getFoundryRoleARN(props.schema?.Region, catalogId),
      catalogId: catalogId,
      catalogName: catalogName,
      id: id,
      region: props.schema?.Region,
      dataLakePrincipal: getConsumerDataLakePrincipal(REDSHIFT_RESOURCE, undefined, props.activeWorkspace.accountId),
    };
    return item;
  };

  const checkIfRedshiftTagIsAlreadyConsumedAndGetPublisherTag = async (
    tagKey,
    tagValue,
    tagAccountId,
    tagWorkspaceId,
    id,
  ) => {
    let listSchemaTagPermissionPublisherRequest = {
      ownerId: tagWorkspaceId,
      region: props.schema['Region'],
      resource: {
        redshiftTagPolicy: {
          tagKey: tagKey,
          tagValue: tagValue,
          catalogId: tagAccountId,
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_REDSHIFT_TYPE,
      nextToken: null,
    };
    let listSchemaTagPermissionConsumerRequest = {
      ownerId: props.activeWorkspace.workspaceId,
      region: props.schema['Region'],
      resource: {
        redshiftTagPolicy: {
          tagKey: tagKey,
          tagValue: tagValue,
          catalogId: tagAccountId,
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_REDSHIFT_TYPE,
      nextToken: null,
    };
    let consumed = props.cartItemIds.includes(id);
    let permissionId = undefined;

    try {
      let listTagPermissionPublisherResponse = await listDataPermissions(listSchemaTagPermissionPublisherRequest);
      let listTagPermissionConsumersResponse = await listDataPermissions(listSchemaTagPermissionConsumerRequest);
      if (
        listTagPermissionPublisherResponse?.dataPermissionList !== undefined &&
        listTagPermissionPublisherResponse?.dataPermissionList.length != 0
      ) {
        permissionId = listTagPermissionPublisherResponse.dataPermissionList[0].dataPermissionId;
      }
      // check if the tag is already consumed
      consumed =
        listTagPermissionConsumersResponse?.dataPermissionList?.filter(
          (dp) => dp.ownerId === props.activeWorkspace.workspaceId,
        )?.length != 0;
    } catch (err) {
      console.log('Exception list tag permission', err);
      return permissionId;
    }
    return { permissionId: permissionId, consumed: consumed };
  };

  const setupDropdownItems = async (tags, schema) => {
    let dropdownItems = [];
    for (let tag of tags) {
      let tagId = `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`;
      let res = await checkIfRedshiftTagIsAlreadyConsumedAndGetPublisherTag(
        tag.tagKey,
        tag.tagValue,
        tag.catalogId,
        schema.Owners[0],
        tagId,
      );
      let tagPermissionId = res.permissionId;
      let consumed = res.consumed;
      if (tagPermissionId !== undefined) {
        dropdownItems.push({
          label: `Request ${tag.tagKey}.${tag.tagValue} access`,
          id: tagId,
          type: REDSHIFT_TAG_ITEM,
          key: tag.tagKey,
          value: tag.tagValue,
          catalogId: tag.catalogId,
          owner: schema.Owners[0],
          catalogName: props.catalogName,
          permissionId: tagPermissionId,
          disabled: consumed,
        });
      }
    }
    setDropdownOptions(dropdownItems);
  };

  const handleRefresh = async () => {
    setLoadingResources(true);
    await setupDropdownItems(props.tags, props.schema);
    setLoadingResources(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleConfirm = async () => {
    if (selectedPermission == undefined) {
      return;
    }
    if (selectedPermission.type == REDSHIFT_TAG_ITEM) {
      let cartItem = await flattenTagAccess(
        selectedPermission.catalogId,
        selectedPermission.catalogName,
        selectedPermission.permissionId,
        selectedPermission.key,
        selectedPermission.value,
        selectedPermission.owner,
        selectedPermission.id,
      );
      props.addToCart([cartItem]);
    }
    props.dismiss();
  };

  const transferResource = async (selectedPermission) => {
    setLoadingResources(true);

    let res = [];
    if (selectedPermission == undefined) {
      return res;
    }
    if (selectedPermission.type == REDSHIFT_TAG_ITEM) {
      //tag resource
      let getTaggedResourcesResponse = await getTaggedResources({
        tagPair: selectedPermission.key + ':' + selectedPermission.value,
        catalogId: selectedPermission.catalogId,
        region: props.schema['Region'],
        type: REDSHIFT_TAG_TYPE,
      });
      for (let resource of getTaggedResourcesResponse.resources) {
        if (resource.resourceType == DATASET_RESOURCE_TYPE) {
          res.push({
            type: resource.resourceType,
            resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.schemaName}.${resource.resourceName}`,
            resourceLink: createWSDatasetDetailLink(resource.resourceId),
          });
        } else if (resource.resourceType == SCHEMA_RESOURCE_TYPE) {
          res.push({
            type: resource.resourceType,
            resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.resourceName}`,
            resourceLink: createWSSchemaDetailLink(
              resource.catalogId,
              resource.clusterId,
              resource.databaseName,
              resource.schemaName,
              resource.region,
            ),
          });
        }
      }
      setItems(res);
    }
    setLoadingResources(false);
  };

  const { items } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>no resources attached</b>
          </div>
          <p className='awsui-util-mb-s'>No resources to display.</p>
        </div>
      ),
    },

    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const displayResource = () => {
    return (
      <Table
        columnDefinitions={[
          {
            id: 'resource',
            header: 'Resource',
            cell: (item) => <Link to={item.resourceLink}>{item.resource}</Link>,
          },
          {
            id: 'type',
            header: 'Resource type',
            cell: (item) => item.type,
          },
        ]}
        items={items}
        loading={loadingResources}
        loadingText='Loading resources'
        sortingDisabled
        header={<Header> Resources </Header>}
      />
    );
  };

  const handleChange = (e) => {
    setSelectedPermission(e.detail.selectedOption);
    transferResource(e.detail.selectedOption);
  };

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirm}>
              Add to cart
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Select data access approach'
    >
      <ColumnLayout>
        <FormField label='Select Redshift access permission type'>
          <Select
            selectedOption={selectedPermission}
            onChange={handleChange}
            options={dropdownOptions}
            selectedAriaLabel='Selected'
            empty={'No published tags attached'}
            statusType={loadingResources ? 'loading' : 'finished'}
            loadingText='Loading access options'
          />
        </FormField>
        {selectedPermission !== undefined && displayResource()}
      </ColumnLayout>
    </Modal>
  );
};
