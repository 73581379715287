import * as React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Spinner,
  Tabs,
} from '@amzn/awsui-components-react-v3';
import { editCatalog, listCatalogs, listDatabases } from '../../api/catalog';
import {
  convertToDgsHCResourceArn,
  convertToDgsHCResourceId,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  getCtiUrlFromCti,
  getGroupOrWorkspaceName,
  isDGSAdmin,
} from '../../commons/common';
import { PageHeader } from '../subscriptions/common';
import { LabeledText } from '../resourcesmanager/components';
import BrowseDatabasesTable from './browsedatabases';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { createGroupDetailLink, createWorkspaceDetailLink } from 'src/routes';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import BusinessGlossaries from 'src/components/workspaces/common/businessGlossaries';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import _ from 'lodash';
import { ContactInfo } from '../workspaces/common/ContactInfo';

export interface CatalogDetailProps extends DefaultRouteProps {
  setContentType: any;
  setActiveCatalogName: any;
  activeGroup: string;
  match: any;
  userInfo: any;
  allowlisted: boolean;
}

export interface CatalogDetailState {
  notifications: FlashbarProps.MessageDefinition[];
  loading: boolean;
  catalog: object;
  databases: object[];
  redirect: string;
  contentType: string;
  templates: object[];
  editCatalogModalVisible: boolean;
  saveEditCatalogLoading: boolean;
  editableCatalogDescription: string;
  editableCatalogName: string;
  editableCatalogSuccess: boolean;
  editableCTI: string;
}

export default class CatalogDetail extends Component<CatalogDetailProps, CatalogDetailState> {
  state = {
    notifications: [],
    loading: true,
    catalog: null,
    databases: [],
    redirect: undefined,
    contentType: undefined,
    templates: [],
    editCatalogModalVisible: false,
    saveEditCatalogLoading: false,
    editableCatalogDescription: undefined,
    editableCatalogName: undefined,
    editableCatalogSuccess: false,
    editableCTI: '',
  };

  componentDidMount = async () => {
    this.props.setContentType(TABLE_CONTENT_TYPE);
    // This is for Save button, after editing the Catalog on clicking this will invoke api
    this.handleEditCatalog = this.handleEditCatalog.bind(this);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const catalogs = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: this.props.match.params.catalogid,
              Region: this.props.match.params.region,
            },
          ],
        },
      });
      await this.fetchTemplatesForCatalog(catalogs.CatalogInfoList[0]);
      this.setState({
        catalog: catalogs.CatalogInfoList[0],
      });

      // here we pull all databases and filter them by catalog ID and region.
      // this will not scale but should work fine until a GetDatabasesForCatalog API exists.
      let request = { NextToken: null };
      let databases = await listDatabases(request);
      let databaseInfoList = databases.DatabaseInfoList;
      while (databases.NextToken != null) {
        request.NextToken = databases.NextToken;
        databases = await listDatabases(request);
        databaseInfoList.push(...databases.DatabaseInfoList);
      }
      var databasesInCatalog = [];
      for (const database of databaseInfoList) {
        if (database.CatalogId == this.state.catalog.CatalogId && database.Region == this.state.catalog.Region) {
          databasesInCatalog.push({
            CatalogId: database.CatalogId,
            DatabaseName: database.DatabaseName,
          });
        }
      }
      this.setState({
        databases: databasesInCatalog,
      });
    } catch (err) {
      console.log(err);
      this.setState({ catalog: null, databases: [] });
    }

    if (this.state.catalog) {
      this.props.setActiveCatalogName(this.state.catalog.Name);
    }

    this.setState({ loading: false });
  };

  fetchTemplatesForCatalog = async (catalog) => {
    let resourceId = convertToDgsHCResourceId(
      catalog?.CatalogId,
      catalog?.ClusterIdentifier,
      undefined,
      undefined,
      undefined,
      undefined,
      catalog?.DataAccessRole,
    );
    let templates = await fetchTemplatesForResourceId(resourceId);
    this.setState({
      templates: templates,
    });
  };

  getCatalogTabs() {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: this.getDetailsContainer(),
      },
      {
        label: 'Databases',
        id: 'Databases',
        content: this.getCatalogDatabases(),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: this.getTemplatesForCatalog(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: this.getMetadataForCatalog(),
      },
    ];

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (!this.state.catalog && !this.state.loading) {
      return (
        <>
          <h2>Catalog not found</h2>
          The given catalog at {this.props.match.params.catalogid} is not valid, or you do not have permission to view
          it. Please check the URL for mistakes and try again.
        </>
      );
    }

    let header;
    if (this.state.catalog?.Name) {
      header = this.state.catalog.Name;
    } else {
      header = <Spinner size='big' />;
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />
        <PageHeader buttons={[]} header={header} />
        <Modal
          onDismiss={() => {
            this.setState({ editCatalogModalVisible: false });
          }}
          visible={this.state.editCatalogModalVisible}
          size='medium'
          footer={
            <span className='awsui-util-f-r'>
              <Button
                variant='link'
                onClick={() => {
                  this.setState({ editCatalogModalVisible: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={this.disableEditCatalogSaveButton()}
                loading={this.state.saveEditCatalogLoading}
                onClick={this.handleEditCatalog}
              >
                Save
              </Button>
            </span>
          }
          header='Edit catalog'
        >
          <ColumnLayout>
            <FormField label='Catalog ID'>
              <Input disabled={true} value={this.state.catalog?.CatalogId} />
            </FormField>
            <FormField label='Catalog name'>
              <Input
                ariaRequired={true}
                placeholder={'Name for the catalog.'}
                value={this.state.editableCatalogName}
                onChange={(e) =>
                  this.setState({
                    editableCatalogName: e.detail.value,
                  })
                }
              />
            </FormField>
            <FormField label='Catalog description' description=''>
              <Input
                ariaRequired={true}
                placeholder={'Description for the catalog.'}
                value={this.state.editableCatalogDescription}
                onChange={(e) =>
                  this.setState({
                    editableCatalogDescription: e.detail.value,
                  })
                }
              />
            </FormField>
            <FormField label='CTI' description=''>
              <Input
                ariaRequired={true}
                placeholder={'CTI for the catalog.'}
                value={this.state.editableCTI}
                onChange={(e) =>
                  this.setState({
                    editableCTI: e.detail.value,
                  })
                }
              />
            </FormField>
          </ColumnLayout>
        </Modal>
        <div>{this.getCatalogTabs()}</div>
      </>
    );
  }

  getCatalogDetailsMetadata() {
    const catalog_metadata = [];
    if (this.state.catalog?.Name) {
      catalog_metadata.push(
        <CopiableText name={'Catalog name'} key={'Catalog name'} value={this.state.catalog?.Name} />,
      );
    }
    if (this.state.catalog?.Description) {
      catalog_metadata.push(
        <CopiableText name={'Description'} key={'Description'} value={this.state.catalog?.Description} />,
      );
    }
    if (this.state.catalog?.Region) {
      catalog_metadata.push(<CopiableText name={'Region'} key={'Region'} value={this.state.catalog?.Region} />);
    }
    if (this.state.catalog?.CatalogId) {
      catalog_metadata.push(
        <CopiableText name={'Catalog ID'} key={'Catalog ID'} value={this.state.catalog?.CatalogId} />,
      );
    }
    if (this.state.catalog?.Owner) {
      catalog_metadata.push(
        <CopiableText
          name={'Owner'}
          key={'Owner'}
          value={getGroupOrWorkspaceName(this.state.catalog?.Owner, this.props.workspaceNameMap)}
          url={
            isValidWorkspace(this.state.catalog?.Owner)
              ? createWorkspaceDetailLink(this.state.catalog?.Owner)
              : createGroupDetailLink(this.state.catalog?.Owner)
          }
        />,
      );
    }

    if (this.state.catalog?.CTI) {
      catalog_metadata.push(
        <LabeledText
          label='CTI'
          value={this.state.catalog?.CTI}
          url={getCtiUrlFromCti(this.state.catalog?.CTI)}
          copiable={true}
        />,
      );
    }
    return catalog_metadata;
  }

  getDetailsContainer() {
    return (
      <>
        <div className='awsui-util-container'>
          <div className='awsui-util-container-header'>
            <div className='awsui-util-action-stripe'>
              <div className='awsui-util-action-stripe-title'>
                <h2>Catalog details</h2>
              </div>
              <div className='awsui-util-action-stripe-group'>
                <div style={{ paddingTop: 5, paddingRight: 10 }} hidden={this.hideEditCatalogButton()}>
                  <Button
                    variant='normal'
                    onClick={() => {
                      this.editCatalogForm();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ColumnLayout columns={3} borders='horizontal'>
            {this.getCatalogDetailsMetadata()}
          </ColumnLayout>
        </div>
        <SpaceBetween size={'l'}>
          {!this.state.loading && this.state.catalog && (
            <ContactInfo
              resource={convertToDgsHCResourceArn(
                this.state.catalog?.CatalogId,
                this.state.catalog?.ClusterIdentifier,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.catalog?.DataAccessRole,
              )}
            />
          )}
          {!this.state.loading && this.state.catalog && (
            <BusinessGlossaries
              activeGroup={this.props.activeGroup}
              match={''}
              setContentType={this.props.setContentType}
              resource={convertToDgsHCResourceArn(
                this.state.catalog?.CatalogId,
                this.state.catalog?.ClusterIdentifier,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.catalog?.DataAccessRole,
              )}
              isOwner={this.userOwnsCatalog()}
            />
          )}
        </SpaceBetween>
      </>
    );
  }

  getTemplatesForCatalog() {
    return <TemplatesForResourceDetailsTable items={this.state.templates} userOwnsResource={this.userOwnsCatalog()} />;
  }

  userOwnsCatalog() {
    if (!this.state.catalog) return false;
    let owner = this.state.catalog?.Owner;
    if (!this.props.userInfo) return false;
    if (this.props.userInfo && isDGSAdmin(this.props.userInfo.memberGroupIds)) return true;
    const groupOwner =
      !!this.props.userInfo.memberGroupIds && _.find(this.props.userInfo.memberGroupIds, (grp) => grp === owner);
    return groupOwner
      ? true
      : !!this.props.userInfo.memberWorkspaceIds &&
          _.find(this.props.userInfo.memberWorkspaceIds, (wks) => wks === owner);
  }

  getCatalogDatabases() {
    return (
      <BrowseDatabasesTable
        {...this.props}
        setContentType={(type) => this.setState({ contentType: type })}
        title='Databases'
        filter={this.state.databases}
        loading={this.state.loading}
        doNotLinkCatalogs={true}
      />
    );
  }

  getMetadataForCatalog() {
    return (
      <MetadataDetails
        resourceOwnerIds={this.state.catalog?.Owner}
        resource={convertToDgsHCResourceArn(
          this.state.catalog?.CatalogId,
          this.state.catalog?.ClusterIdentifier,
          undefined,
          undefined,
          undefined,
          undefined,
          this.state.catalog?.DataAccessRole,
        )}
        activeGroup={this.props.activeGroup}
        setContentType={this.props.setContentType}
        setNotification={this.setNotification}
      />
    );
  }

  setNotification = async (header, message) => {
    if (header === 'error') {
      this.setState({
        notifications: [
          {
            header: header,
            type: 'error',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            type: 'success',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  // make sures that it is visible only for the owners and only if allowlisted
  hideEditCatalogButton() {
    return !this.props.allowlisted || !this.state.catalog?.Owner.includes(this.props.activeGroup);
  }

  // on clicking Edit button this method is triggered
  editCatalogForm() {
    this.clearEditableParametersForCatalog();
    this.initializeEditableParametersForCatalog();
    this.setState({ editCatalogModalVisible: true });
  }

  // clear all the editable fields before assigning
  clearEditableParametersForCatalog() {
    this.setState({
      editableCatalogDescription: undefined,
      editableCatalogName: undefined,
      editableCTI: '',
    });
  }

  // this will initialize editable fields with the current fields, so they can be prefilled in form
  initializeEditableParametersForCatalog() {
    this.setState({
      editableCatalogSuccess: true,
      editableCatalogDescription: this.state.catalog.Description,
      editableCatalogName: this.state.catalog.Name,
      editableCTI: this.state.catalog.CTI,
    });
  }

  // will validate Editable parameters provided and changes the visibility of Save Button
  disableEditCatalogSaveButton() {
    return !this.isActiveGroupExists();
  }

  // make sures that the current ActiveGroup is not edited in the Edit Form
  isActiveGroupExists() {
    return !!this.state.catalog?.Owner.includes(this.props.activeGroup);
  }

  // On clicking Save Catalog this will make an api call and stores the Data
  async handleEditCatalog() {
    this.setState({
      saveEditCatalogLoading: true,
    });
    let editableCatalogMessage = '';
    try {
      let editCatalogResponse = await editCatalog({
        CatalogId: this.state.catalog.CatalogId,
        ClusterIdentifier: this.state.catalog.ClusterIdentifier,
        Region: this.state.catalog.Region,
        Name: this.state.editableCatalogName,
        Owner: this.state.catalog.Owner,
        Description: this.state.editableCatalogDescription,
        CTI: this.state.editableCTI,
      });
      if (!editCatalogResponse.Message.includes('Success')) {
        this.setState({
          editableCatalogSuccess: false,
        });
      }
    } catch (e) {
      editableCatalogMessage = e.message;
      this.setState({
        editableCatalogSuccess: false,
      });
      console.log(e);
    } finally {
      if (this.state.editableCatalogSuccess) {
        // to get the edited details displayed on the page without refresh
        await this.handleRefresh();
        this.setState({
          notifications: [
            {
              type: 'success' as FlashbarProps.Type,
              content: `Successfully edited the catalog.`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      } else {
        this.setState({
          notifications: [
            {
              type: 'error' as FlashbarProps.Type,
              content: `Failed to edit the catalog. Reason: ${editableCatalogMessage}`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      }
      this.setState({
        saveEditCatalogLoading: false,
        editCatalogModalVisible: false,
      });
    }
  }
}
