import * as React from 'react';
import { Button, Icon, Popover, Spinner } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_ID_PREFIX,
  ADMIN_GROUPS,
  DATABASE_NAME_PREFIX,
  LAKEFORMATION_RESOURCE,
  REDSHIFT_RESOURCE,
  REGION_NAME_PREFIX,
  TABLE_NAME_PREFIX,
  TEMPLATE_TYPE_ACCESS_MANAGEMENT,
  OMNI_ONCALL_URL,
  EXP_12_HOURS_MINS,
  GROUP_WKS_ID_DELIMITER,
} from 'src/commons/constants';
import { isValidDataSetIdFormat, isValidWorkspace } from 'src/commons/validationUtils';
import { StatusIndicator } from '@amzn/awsui-components-react-v3';
import { getHCAdminGroup, getRegion } from 'src/api/config';
import { getTemplateForResource, listWorkspaces, listFgaPolicies } from 'src/api/permissions';
import _ from 'lodash';
import { LocalStorageItem, LocalStorageItemKey, StorageService } from 'src/components/utils/storage-service';
import { listCatalogs } from 'src/api/catalog';

export const getAWSPartition = (region) => {
  switch (region) {
    case 'us-isob-east-1':
      return 'aws-iso-b';
    case 'us-iso-east-1':
      return 'aws-iso';
    case 'cn-northwest-1':
      return 'aws-cn';
    default:
      return 'aws';
  }
};

export interface DefaultRouteProps {
  catalogMap?: Map<string, string>;
  workspaceNameMap?: Map<string, string>;
  userInfo?: any;
  // Active group object
  activeGroupInfo?: any;
  // active workspace object
  activeWorkspace?: any;
}

export const generateCatalogMap = async (storageInstance: StorageService): Promise<Map<string, string>> => {
  const catalogMapItem = await storageInstance.getItem(LocalStorageItemKey.CatalogNameMap);
  if (catalogMapItem) {
    return catalogMapItem.data as Map<string, string>;
  } else {
    let catalogNameMap = new Map();
    let request = { NextToken: null };
    let result = await listCatalogs(request);
    let catalogInfoList = result.CatalogInfoList;
    while (result.NextToken != null) {
      request.NextToken = result.NextToken;
      result = await listCatalogs(request);
      catalogInfoList.push(...result.CatalogInfoList);
    }
    for (let i = 0; i < catalogInfoList.length; i++) {
      let item = catalogInfoList[i];
      const catalogId: string = item.CatalogId;
      const region: string = item.Region;
      const clusterIdentifier: string = item.ClusterIdentifier;
      if (clusterIdentifier != null) {
        catalogNameMap.set(catalogId + ':' + clusterIdentifier + ':' + region, item.Name);
      } else {
        catalogNameMap.set(catalogId + ':' + region, item.Name);
      }
    }
    await storageInstance.setItem(
      LocalStorageItemKey.CatalogNameMap,
      new LocalStorageItem(catalogNameMap, EXP_12_HOURS_MINS),
    );
    return catalogNameMap;
  }
};

export const generateWorkspaceNameMap = async (storageInstance: StorageService): Promise<Map<string, string>> => {
  const wksNameMapItem = await storageInstance.getItem(LocalStorageItemKey.WorkspaceNameMap);
  if (wksNameMapItem) {
    return wksNameMapItem.data as Map<string, string>;
  } else {
    let wksNameMap = new Map();
    let request = { workspaceIds: [], includeAllDetails: false, nextToken: null };
    let result = await listWorkspaces(request);
    let allWorkspaces = result.workspaces;
    while (result.nextToken != null) {
      request.nextToken = result.nextToken;
      result = await listWorkspaces(request);
      allWorkspaces.push(...result.workspaces);
    }
    allWorkspaces
      .filter((wks) => !!wks.workspaceName)
      .forEach((wks) => wksNameMap.set(wks.workspaceId, wks.workspaceName));
    await storageInstance.setItem(
      LocalStorageItemKey.WorkspaceNameMap,
      new LocalStorageItem(wksNameMap, EXP_12_HOURS_MINS),
    );
    return wksNameMap;
  }
};

export const getGroupOrWorkspaceName = (ownerId: string, wksNameMap: Map<string, string>): string => {
  if (isValidWorkspace(ownerId) && wksNameMap) {
    return wksNameMap.has(ownerId) ? getWorkspaceDisplayName(wksNameMap.get(ownerId), ownerId) : ownerId;
  } else {
    return ownerId;
  }
};

export const getWorkspaceDisplayName = (wksName: string, wksId: string): string => {
  // eg: akaanjWks, wks-061754192931-dc64db2 => akaanjWks (061754192931)
  return `${wksName} (${wksId.substring(4, 16)})`;
};

export const getDropdownItemId = (groupId: string, workspaceId: string): string => {
  // akaanjGroup, wks-061754192931-dc64db2 => akaanjGroup##wks-061754192931-dc64db2
  return `${groupId}${GROUP_WKS_ID_DELIMITER}${workspaceId}`;
};

export const getGroupAndWksFromDropdownItemId = (dropdownItemId: string): string[] => {
  // akaanjGroup##wks-061754192931-dc64db2 => akaanjGroup, wks-061754192931-dc64db2
  const grpAndWks = dropdownItemId?.split(GROUP_WKS_ID_DELIMITER) ?? '';
  return [grpAndWks[0] ?? '', grpAndWks[1] ?? ''];
};

export const getGroupFromDropdownItemId = (dropdownItemId: string): string => {
  return getGroupAndWksFromDropdownItemId(dropdownItemId)[0];
};

export const getWksFromDropdownItemId = (dropdownItemId: string): string => {
  return getGroupAndWksFromDropdownItemId(dropdownItemId)[1];
};

export const getOwnerIdName = async (ownerId: string): Promise<string> => {
  const wksNameMapItem = await StorageService.getInstance().getItem(LocalStorageItemKey.WorkspaceNameMap);
  let wksNameMap = wksNameMapItem?.data as Map<string, string>;
  if (isValidWorkspace(ownerId) && wksNameMap) {
    return wksNameMap.has(ownerId) ? `${wksNameMap.get(ownerId)} (Wks)` : ownerId;
  } else {
    return ownerId;
  }
};

export const getGroupingMechanismType = (groupingId: string) => {
  if (groupingId.includes('.')) {
    return 'Teams';
  } else {
    return 'Posix';
  }
};

export const getPhoneToolLink = (userId: string) => {
  const url = 'https://phonetool.amazon.com/users/';
  let userUrl = url.concat(userId);
  return (
    <a href={userUrl} target={'_blank'} rel='noopener noreferrer'>
      {userId}
    </a>
  );
};

export const getRequestSubmittedWithSLAInDaysMessage = (num) => {
  return `The request was submitted successfully. Omni oncall has been notified and will process it within ${num} business day(s).`;
};

export const getRequestSubmittedWithAutoApproval = () => {
  return (
    <>
      The request was submitted successfully. In most cases, the request will be auto-approved in few minutes. <br />
      Please reach out to {getOmniOncallLink()} if not approved within an hour!
    </>
  );
};

export const getFoundryRoleARN = (region, catalogId) => {
  return `arn:${getAWSPartition(region)}:iam::${catalogId}:role/AWSDGSFoundry-ManagementRole-DO-NOT-DELETE`;
};

export const getOmniOncallLink = () => {
  return (
    <Link
      to={{ pathname: OMNI_ONCALL_URL }}
      target={'_blank'}
      rel='noopener noreferrer'
      style={{ textDecoration: 'underline' }}
    >
      Omni oncall
    </Link>
  );
};

export const getOmniGroupLink = (groupId) => {
  return <a href={'/groups/' + groupId}>{groupId}</a>;
};

export const getWikiLink = (wiki) => {
  return (
    <a href={wiki} target={'_blank'}>
      {wiki}
    </a>
  );
};

export const getGroupMechanismIdLink = (groupingMechanismId: string, includeType: boolean = false) => {
  let isTeam: boolean = groupingMechanismId && groupingMechanismId.includes('amzn1.');
  let isPosix: boolean = groupingMechanismId && /^[-_a-z]+$/.test(groupingMechanismId);

  let groupingMechUrl = isTeam
    ? `https://permissions.amazon.com/a/team/${groupingMechanismId}`
    : isPosix
    ? `https://permissions.amazon.com/group.mhtml?group=${groupingMechanismId}&group_type=posix`
    : groupingMechanismId;
  return (
    <>
      {includeType && <b>{isTeam ? 'Team ' : 'POSIX group '}</b>}
      <a href={groupingMechUrl} target={'_blank'}>
        {groupingMechanismId}
      </a>
    </>
  );
};

export const getCtiUrl = (c, t, i) => {
  return (
    'https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=' +
    c +
    '&type=' +
    t +
    '&item=' +
    i
  );
};

export const getCtiUrlFromCti = (cti) => {
  if (!cti) return;
  const arr = cti.split('/');
  const c = arr[0];
  const t = arr[1];
  const i = arr[2];
  return getCtiUrl(c, t, i);
};

export const getAccountIdFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[1].substring(ACCOUNT_ID_PREFIX.length);
  }
  return '';
};

export const getDatabaseNameFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[2].substring(DATABASE_NAME_PREFIX.length);
  }
  return '';
};

export const getTableNameFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[3].substring(TABLE_NAME_PREFIX.length);
  }
  return '';
};

export const getRegionFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[4].substring(REGION_NAME_PREFIX.length);
  }
  return '';
};

export const convertToDgsHCResourceArn = (
  accountId,
  clusterId,
  databaseName,
  schemaName,
  tableName,
  columnName,
  dataAccessRole,
) => {
  let region = getRegion();
  let partition = getAWSPartition(region);
  let resourceArn = `arn:${partition}:dgs-hc:${region}:${accountId}:`;
  if (clusterId) {
    // redshift resource
    resourceArn = resourceArn + `redshift/cluster/${clusterId}`;
  } else {
    // glue resource
    if (dataAccessRole) {
      resourceArn = resourceArn + 'glueLF';
    } else {
      resourceArn = resourceArn + 'glue';
    }
  }
  if (databaseName) {
    resourceArn = resourceArn + `/database/${databaseName}`;
  }
  if (schemaName) {
    resourceArn = resourceArn + `/schema/${schemaName}`;
  }
  if (tableName) {
    resourceArn = resourceArn + `/table/${tableName}`;
  }
  if (columnName) {
    resourceArn = resourceArn + `/column/${columnName}`;
  }
  return resourceArn;
};

export const convertToDgsHCResourceId = (
  accountId,
  clusterId,
  databaseName,
  schemaName,
  tableName,
  columnName,
  dataAccessRole,
) => {
  let region = getRegion();
  let resourceId = `DS-`;
  if (clusterId) {
    // redshift resource
    resourceId = resourceId + `redshift|A-${accountId}|CI-${clusterId}`;
  } else {
    // glue resource
    if (dataAccessRole) {
      resourceId = resourceId + `glueLF|A-${accountId}`;
    } else {
      resourceId = resourceId + `glue|A-${accountId}`;
    }
  }
  if (databaseName) {
    resourceId = resourceId + `|DN-${databaseName}`;
  }
  if (schemaName) {
    resourceId = resourceId + `|SN-${schemaName}`;
  }
  if (tableName) {
    resourceId = resourceId + `|TN-${tableName}`;
  }
  if (columnName) {
    resourceId = resourceId + `|CN-${columnName}`;
  }
  return resourceId + `|R-${region}`;
};

export const isDataLakeAdmin = (group) => {
  return ADMIN_GROUPS.includes(group);
};

export const isDGSAdmin = (groups) => {
  if (!groups) return false;
  const dgsAdminGroupIds = ['AWSDL_Services', 'AWS_DGS'];
  return groups.filter((i) => dgsAdminGroupIds.includes(i)).length;
};

export const isHCAdmin = (group) => {
  return group == getHCAdminGroup();
};

export const registerStatus = (registered: boolean) => {
  return registered ? (
    <StatusIndicator>Registered</StatusIndicator>
  ) : (
    <StatusIndicator type='info'>Not Registered</StatusIndicator>
  );
};

export const CopiableText = ({ name = '', value = '', loading = false, url = null, copiable = true }) => {
  value ? value + ' ' : '';
  return (
    <div>
      <div className='awsui-util-label'>{name}</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='awsui-util-copy-text'>
          {url == null ? value : <Link to={`${url}`}>{value}</Link>}
          {copiable && value && (
            <Popover
              size='small'
              position='top'
              dismissButton={false}
              triggerType='custom'
              content={
                <span className='awsui-util-status-positive'>
                  <Icon variant='success' name='status-positive' /> Copied
                </span>
              }
            >
              <Button
                variant='icon'
                icon='copy'
                iconAlt='Copy'
                label='Copy ARN'
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export const fetchTemplatesForResourceId = async (resourceId) => {
  let templateList = [];
  if (resourceId) {
    try {
      let getTemplatesForResourceResponse = await getTemplateForResource({
        resourceId: resourceId,
        templateType: TEMPLATE_TYPE_ACCESS_MANAGEMENT,
      });
      templateList.push(...getTemplatesForResourceResponse.templates);
      return _.uniqBy(templateList, 'templateId');
    } catch (err) {
      console.log('Failed to fetch templates' + err.message);
    }
  }
  return [];
};

export const getConsumerDataLakePrincipal = (dataAssetType, consumerPrincipal, consumerAccount) => {
  if (dataAssetType == REDSHIFT_RESOURCE) {
    return consumerAccount;
  } else if (dataAssetType == LAKEFORMATION_RESOURCE) {
    return consumerPrincipal ?? consumerAccount;
  }
};

export const fetchFineGrainedAccessPolicies = async (ownerId, status, type, tableData) => {
  if (!ownerId && !tableData) return [];

  const fgaps = [];
  const request = {
    ownerId: ownerId,
    status: status,
    templateType: type,
    tableData: {
      tableCatalogId: tableData.CatalogId,
      clusterId: tableData.ClusterIdentifier,
      databaseName: tableData.DatabaseName,
      schemaName: tableData.SchemaName,
      tableName: tableData.TableName,
      region: tableData.Region,
    },
    nextToken: null,
  };

  try {
    do {
      const result = await listFgaPolicies(request);
      request.nextToken = result.nextToken;
      fgaps.push(...result.FineGrainAccess);
    } while (request.nextToken);
    return fgaps;
  } catch (err) {
    console.error('Failed to fetch fine grained access policy: ' + err.message);
    return [];
  }
};
