import * as React from 'react';
import { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { MetricStorePageHeaderWithCSV } from 'src/components/metricstore/components';
import { ErrorAlert } from 'src/components/resourcesmanager/components';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import {
  getColumnDefinitionCollectionView,
  handleRefresh,
} from 'src/components/metricstore/metriccollection/metricCollectionUtils';

export const MetricTableView = ({ props, contentType }) => {
  const [error, setError] = useState(undefined);
  const [allMetricItems, setAllMetricItems] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const tableMessage = 'No metric collections';
  const [metricData, setMetricData] = useState<string[]>([]);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  let columnDefinitions = [];
  let csvColumnDefinition = [];
  if (allMetricItems.length > 0) {
    [columnDefinitions, csvColumnDefinition] = getColumnDefinitionCollectionView(metricData);
  } else {
    console.log('No data found hence no processing is required..');
  }
  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    refreshData();
  }, []);

  const refreshData = async () => {
    await handleRefresh(
      props.match.params.id,
      setMetricData,
      setLoadingTableData,
      setAllMetricItems,
      setError,
      contentType,
    );
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allMetricItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const refForCSVDownloader: React.RefObject<any> = React.createRef();

  const onClickDownload = () => {
    refForCSVDownloader.current.handleClick();
  };

  return (
    <>
      {ErrorAlert(error)}

      <Table
        {...collectionProps}
        loadingText='Loading metric collections catalog...'
        loading={loadingTableData}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <MetricStorePageHeaderWithCSV
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: refreshData,
                },
              ]}
              subheader={
                <>
                  Metric Collections Catalog
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
              rowItems={allMetricItems}
              columnDefinition={csvColumnDefinition}
              refForCSVDownloader={refForCSVDownloader}
              onClickDownload={onClickDownload}
              fileName={'view.csv'}
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Search metric collections'
            filteringPlaceholder='Search metric collections'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No metric collections to display' />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
    </>
  );
};
