import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { PageHeader } from 'src/components/notifications/common';
import { defaultWrapLinesPreference, i18nStrings, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { listCatalogs } from 'src/api/catalog';
import {
  DATA_LOAD_LIMIT,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  createGroupDetailLink,
  createWorkspaceDetailLink,
  createWSGlueCatalogDetailLink,
  createWSRedshiftCatalogDetailLink,
} from 'src/routes';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import { DefaultRouteProps, getGroupOrWorkspaceName } from 'src/commons/common';

export interface WSBrowseCatalogsProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
}

export const WSBrowseCatalogs = (props: WSBrowseCatalogsProps) => {
  const [allItems, setItems] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [nextToken, setNextToken] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const handleRefresh = async () => {
    setTableLoading(true);
    let request = { Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true };
    let catalogs = await listCatalogs(request);
    setTotalCount(catalogs.TotalCount);
    setNextToken(catalogs.NextToken);
    if (catalogs.NextToken == null) {
      setAllDataLoaded(true);
    }
    let catalogList = catalogs.CatalogInfoList;
    setItems(catalogList);
    setTableLoading(false);
  };

  const loadMoreData = async () => {
    setTableLoading(true);
    let request = {
      NextToken: nextToken,
      Limit: DATA_LOAD_LIMIT,
    };
    let catalogs = await listCatalogs(request);
    setNextToken(catalogs.NextToken);
    let catalogList = catalogs.CatalogInfoList;

    let currentItems = allItems;
    currentItems.push(...catalogList);
    setItems(currentItems);
    if (catalogs.NextToken == null) {
      setAllDataLoaded(true);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const flatten = (items) => {
    let list = [];
    list.push(
      ...items.map((item) => ({
        catalogId: item?.CatalogId,
        description: item?.Description,
        catalogName: item?.Name,
        owner: item?.Owner,
        region: item?.Region,
        clusterIdentifier: item?.ClusterIdentifier,
      })),
    );
    return list;
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) =>
        item.clusterIdentifier == undefined ? (
          <Link to={createWSGlueCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogName}</Link>
        ) : (
          <Link to={createWSRedshiftCatalogDetailLink(item?.catalogId, item?.clusterIdentifier, item?.region)}>
            {item.catalogName}
          </Link>
        ),
      sortingField: 'catalogName',

      minWidth: '200px',
    },
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => item.catalogId,
      sortingField: 'catalogId',
      minWidth: '100px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: '200px',
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => (
        <Link
          to={isValidWorkspace(item.owner) ? createWorkspaceDetailLink(item.owner) : createGroupDetailLink(item.owner)}
        >
          {getGroupOrWorkspaceName(item.owner, props.workspaceNameMap)}
        </Link>
      ),
      sortingField: 'owner',
      minWidth: '200px',
    },
    {
      id: 'clusterName',
      header: 'Cluster identifier',
      cell: (item) => item.clusterIdentifier,
      sortingField: 'clusterName',
      minWidth: '100px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) =>
        item.clusterIdentifier == undefined
          ? typeToHumanReadableObject[LAKE_FORMATION_DATASOURCE_ID]
          : typeToHumanReadableObject[REDSHIFT_DATASOURCE_ID],
      minWidth: '100px',
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogName',
            groupValuesLabel: 'Catalog names',
          },
          {
            propertyLabel: 'Catalog Owner',
            key: 'owner',
            groupValuesLabel: 'Catalog Owners',
          },
          {
            propertyLabel: 'Catalog ID',
            key: 'catalogId',
            groupValuesLabel: 'Catalog IDs',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading catalogs...'
        loading={tableLoading}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Catalogs
                  <span className='awsui-util-header-counter'>{` (${totalCount})`}</span>
                </>
              }
            />
          </>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            openEnd={!allDataLoaded}
            disabled={tableLoading}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover catalogs, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
