import { AWSError } from 'aws-sdk/lib/error';
import { Request } from 'aws-sdk/lib/request';
import { getCredentials } from './auth';
import { initCatalogClient } from './catalog';
import { initDependencyClient } from './dependency';
import { initDatasetServiceClient } from './ingestion';
import { initNotificationsClient } from './notifications';
import { initGladstoneClient } from './permissions';
import { initPublisherClient } from './publisher';
import { initResourcesManagerClient } from './resourcesmanager';
import { initSubscriptionsClient } from './subscriptions';
import { initAccessLogCollectorClient } from './usageStats';
import { initMetricStoreClient } from 'src/api/metricstore';

export default function basicCallWrapper<I, O, E = AWSError>(
  client: any,
  apiFunc: (input: I, callback?: (err: E, data: O) => void) => Request<O, E>,
) {
  return (input: I) => {
    return new Promise<O>((resolve, reject) => {
      apiFunc.call(client, input, (err, createData: O) => {
        if (err) {
          reject(err);
        } else {
          resolve(createData);
        }
      });
    });
  };
}

export async function initAllClients() {
  const credentials = await getCredentials();
  initCatalogClient(credentials);
  initDependencyClient(credentials);
  initDatasetServiceClient(credentials);
  initNotificationsClient(credentials);
  initGladstoneClient(credentials);
  initPublisherClient(credentials);
  initResourcesManagerClient(credentials);
  initSubscriptionsClient(credentials);
  initAccessLogCollectorClient(credentials);
  initMetricStoreClient(credentials);
}
