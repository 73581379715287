import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';

export interface weeklyViewProps {
  setContentType: any;
  match: any;
}

export const WeeklyView = (props: weeklyViewProps) => {
  return <MetricTableView props={props} contentType='WEEKLY' />;
};
