import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { getTaggedResources, listDataPermissions } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isValidWorkspace, isValidPrincipalNotRoot } from 'src/commons/validationUtils';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  DATABASE_RESOURCE_TYPE,
  DATASET_RESOURCE_TYPE,
  LAKE_FORMATION_TAG_ITEM,
  LAKEFORMATION_RESOURCE,
  LAKEFORMATION_TAG_TYPE,
  RESOURCE_ITEM,
  TABLE_RESOURCE_TYPE,
  TAG_ITEM,
} from 'src/commons/constants';
import { createWSDatasetDetailLink, createWSGlueDatabaseDetailLink } from 'src/routes';
import { Link } from 'react-router-dom';
import { getConsumerDataLakePrincipal, getFoundryRoleARN } from 'src/commons/common';
import { isMPData } from 'src/components/workspaces/common/common';

export interface AccessSelectModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  dismiss: any;
  database: any;
  addToCart: any;
  catalogName: any;
  tags: any[];
  cartItemIds: any;
  cartItems: any;
}

export const AccessSelectModal = (props: AccessSelectModalProps) => {
  const [selectedPermission, setSelectedPermission] = useState(undefined);
  const [loadingResources, setLoadingResources] = useState(false);
  const [allItems, setItems] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedPrincipalType, setSelectedPrincipalType] = useState(undefined);
  const [principal, setPrincipal] = useState(undefined);
  const [principalErrorText, setPrincipalErrorText] = useState(undefined);
  const [dropdownPrincipalOptions, setDropDownPrincipalOptions] = useState([]);
  const flattenDatasetAllTableAccess = async (
    catalogId,
    catalogName,
    permissionId,
    owner,
    datasetName,
    databaseName,
    id,
    publisherRole,
    dataLakePrincipal,
  ) => {
    return {
      datasetName: 'All Tables',
      databaseName: databaseName,
      tableName: datasetName,
      catalogId: catalogId,
      region: props.database['Region'],
      catalogDisplayName: catalogName !== undefined ? catalogName : catalogId,
      dataOwnerRole: isValidWorkspace(owner) ? getFoundryRoleARN(props.database['Region'], catalogId) : publisherRole,
      owner: owner,
      itemType: RESOURCE_ITEM,
      permissionId: permissionId,
      catalogName: catalogName !== undefined ? catalogName : catalogId,
      id: id,
      dataLakePrincipal: getConsumerDataLakePrincipal(
        LAKEFORMATION_RESOURCE,
        dataLakePrincipal,
        props.activeWorkspace.accountId,
      ),
    };
  };

  let flattenTagAccess = async (
    catalogId,
    catalogName,
    permissionId,
    tagKey,
    tagValue,
    owner,
    id,
    dataLakePrincipal,
  ) => {
    let resources = [];
    let getTaggedResourcesResponse = await getTaggedResources({
      tagPair: tagKey + ':' + tagValue,
      catalogId: catalogId,
      region: props.database['Region'],
      type: LAKEFORMATION_TAG_TYPE,
    });
    for (let resource of getTaggedResourcesResponse.resources) {
      if (resource['ResourceType'] == DATASET_RESOURCE_TYPE) {
        resources.push(resource['CatalogId'] + '/' + resource['DatabaseName'] + '/' + resource['ResourceName']);
      } else if (resource['ResourceType'] == DATABASE_RESOURCE_TYPE) {
        resources.push(resource['CatalogId'] + '/' + resource['DatabaseName']);
      }
    }
    let item = {
      tagKey: tagKey,
      tagValue: tagValue,
      tag: tagKey + '.' + tagValue,
      owner: owner,
      permissionId: permissionId,
      itemType: LAKE_FORMATION_TAG_ITEM,
      resources: resources,
      catalogDisplayName: catalogName !== undefined ? catalogName : catalogId,
      dataOwnerRole: getFoundryRoleARN(props.database?.Region, catalogId),
      catalogId: catalogId,
      catalogName: catalogName !== undefined ? catalogName : catalogId,
      id: id,
      region: props.database?.Region,
      dataLakePrincipal: getConsumerDataLakePrincipal(
        LAKEFORMATION_RESOURCE,
        dataLakePrincipal,
        props.activeWorkspace.accountId,
      ),
    };
    return item;
  };

  const verifyRegisteredTag = async (tagKey, tagValue, tagAccountId, tagWorkspaceId, tagId) => {
    let listDatabaseTagPermissionPublisherRequest = {
      ownerId: tagWorkspaceId,
      region: props.database['Region'],
      resource: {
        lFTagPolicy: {
          resourceType: TABLE_RESOURCE_TYPE,
          expression: [
            {
              tagKey: tagKey,
              tagValues: [tagValue],
            },
          ],
          catalogId: tagAccountId,
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    let listDatabaseTagPermissionConsumerRequest = {
      ownerId: props.activeWorkspace.workspaceId,
      region: props.database['Region'],
      resource: {
        lFTagPolicy: {
          resourceType: TABLE_RESOURCE_TYPE,
          expression: [
            {
              tagKey: tagKey,
              tagValues: [tagValue],
            },
          ],
          catalogId: tagAccountId,
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };

    let accountConsumed = undefined;
    let dataLakePrincipals = [];
    let dataLakeIAMPrincipals = [];
    let permissionId = undefined;

    await listDataPermissions({
      ownerId: tagWorkspaceId,
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    });

    try {
      let listTagPermissionPublisherResponse = await listDataPermissions(listDatabaseTagPermissionPublisherRequest);
      let listTagPermissionConsumersResponse = await listDataPermissions(listDatabaseTagPermissionConsumerRequest);
      if (
        listTagPermissionPublisherResponse?.dataPermissionList !== undefined &&
        listTagPermissionPublisherResponse?.dataPermissionList.length != 0
      ) {
        permissionId = listTagPermissionPublisherResponse.dataPermissionList[0].dataPermissionId;
      }
      if (
        listTagPermissionConsumersResponse?.dataPermissionList !== undefined &&
        listTagPermissionConsumersResponse?.dataPermissionList.length != 0
      ) {
        //Get list of datalake Principals from data permissions
        dataLakePrincipals = listTagPermissionConsumersResponse.dataPermissionList.map(
          (dataPermission) => dataPermission.dataLakePrincipal,
        );
      }
      //Add in principals from cart
      dataLakePrincipals = dataLakePrincipals.concat(
        props.cartItems
          .filter((dataPermission) => dataPermission.id === tagId)
          .map((dataPermission) => dataPermission.dataLakePrincipal),
      );
      dataLakeIAMPrincipals = dataLakePrincipals.filter((dataLakePrincipal) =>
        isValidPrincipalNotRoot(dataLakePrincipal),
      );
      //Current workspace already consuming the all tables in database
      accountConsumed =
        dataLakePrincipals.filter((datalakePrincipal) => props.activeWorkspace.workspaceId.includes(datalakePrincipal))
          .length !== 0;
    } catch (err) {
      console.log('Exception list tag permission', err);
      return permissionId;
    }
    return {
      permissionId: permissionId,
      accountConsumed: accountConsumed,
      iamPrincipals: dataLakeIAMPrincipals,
    };
  };

  const verifyAllTableAccess = async (databaseName, catalogId, id) => {
    // check if there is a publisher with All Table permission
    let listDatasetPermissionRequestPublisherWithAllTables = {
      region: props.database['Region'],
      resource: {
        table: {
          databaseName: databaseName,
          name: null,
          catalogId: catalogId,
          tableWildcard: {},
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    // check if the current workspace is already consuming the data
    let listDatasetPermissionRequestConsumerWithAllTables = {
      ownerId: props.activeWorkspace.workspaceId, // workspace owner
      region: props.database['Region'],
      resource: {
        table: {
          databaseName: databaseName,
          name: null,
          catalogId: catalogId,
          tableWildcard: {},
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    let accountConsumed = undefined;
    let permissionId = undefined;
    let publisherRole = undefined;
    let dataLakePrincipals = [];
    let dataLakeIAMPrincipals = [];
    try {
      let listDatasetPermissionResponsePublisher = await listDataPermissions(
        listDatasetPermissionRequestPublisherWithAllTables,
      );
      let listDatasetPermissionResponseConsumer = await listDataPermissions(
        listDatasetPermissionRequestConsumerWithAllTables,
      );
      if (
        listDatasetPermissionResponsePublisher?.dataPermissionList !== undefined &&
        listDatasetPermissionResponsePublisher.dataPermissionList.length != 0
      ) {
        permissionId = listDatasetPermissionResponsePublisher.dataPermissionList[0].dataPermissionId;
        publisherRole = listDatasetPermissionResponsePublisher.dataPermissionList[0].audit.PublisherRole;
      }
      if (
        listDatasetPermissionResponseConsumer?.dataPermissionList !== undefined &&
        listDatasetPermissionResponseConsumer.dataPermissionList.length != 0
      ) {
        //Get list of datalake Principals
        dataLakePrincipals = listDatasetPermissionResponseConsumer.dataPermissionList.map(
          (dataPermission) => dataPermission.dataLakePrincipal,
        );
      }
      //Add in principals from cart
      dataLakePrincipals = dataLakePrincipals.concat(
        props.cartItems
          .filter((dataPermission) => dataPermission.id === id)
          .map((dataPermission) => dataPermission.dataLakePrincipal),
      );
      dataLakeIAMPrincipals = dataLakePrincipals.filter((dataLakePrincipal) =>
        isValidPrincipalNotRoot(dataLakePrincipal),
      );
      //Current workspace already consuming the all tables in database
      accountConsumed =
        dataLakePrincipals.filter((datalakePrincipal) => props.activeWorkspace.workspaceId.includes(datalakePrincipal))
          .length !== 0;
    } catch (err) {
      console.log('Exception list tag permission', err);
      return undefined;
    }
    return {
      permissionId: permissionId,
      accountConsumed: accountConsumed,
      publisherRole: publisherRole,
      iamPrincipals: dataLakeIAMPrincipals,
    };
  };

  const setupDropdownItems = async (tags, database) => {
    let dropdownItems = [];
    for (let tag of tags) {
      let tagId = `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`;
      let permissionData = await verifyRegisteredTag(
        tag.tagKey,
        tag.tagValue,
        tag.catalogId,
        database.Owners[0],
        tagId,
      );
      let tagPermissionId = permissionData.permissionId;
      let accountConsumed = permissionData.accountConsumed;
      if (tagPermissionId !== undefined) {
        dropdownItems.push({
          label: `Request ${tag.tagKey}.${tag.tagValue} access`,
          id: tagId,
          type: TAG_ITEM,
          value: tagId,
          tagKey: tag.tagKey,
          tagValue: tag.tagValue,
          catalogId: tag.catalogId,
          owner: database.Owners[0],
          catalogName: props.catalogName !== undefined ? props.catalogName : tag.catalogId,
          permissionId: tagPermissionId,
          workspaceAccess: accountConsumed,
          principals: permissionData.iamPrincipals,
        });
      }
    }
    let resourceId = `All Tables.${database?.DatabaseName}.${database?.CatalogId}`;
    let permissionData = await verifyAllTableAccess(database?.DatabaseName, database?.CatalogId, resourceId);
    if (permissionData !== undefined) {
      let databasePermissionId = permissionData.permissionId;
      let accountConsumed = permissionData.accountConsumed;
      let publisherRole = permissionData.publisherRole;
      if (databasePermissionId !== undefined) {
        if (!isMPData(database?.CatalogId, database?.DatabaseName, 'All Tables')) {
          dropdownItems.push({
            label: `Request "All Tables" access from ` + `"${database?.DatabaseName}"`,
            id: resourceId,
            value: resourceId,
            type: RESOURCE_ITEM,
            datasetName: 'All Tables',
            databaseName: database?.DatabaseName,
            owner: database?.Owners[0],
            catalogId: database?.CatalogId,
            catalogName: props.catalogName !== undefined ? props.catalogName : database?.CatalogId,
            permissionId: databasePermissionId,
            publisherRole: publisherRole,
            workspaceAccess: accountConsumed,
            principals: permissionData.iamPrincipals,
          });
        }
      }
    }
    setDropdownOptions(dropdownItems);
  };

  const handleRefresh = async () => {
    setLoadingResources(true);
    await setupDropdownItems(props.tags, props.database);
    setLoadingResources(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleConfirm = async () => {
    if (
      selectedPermission == undefined ||
      (selectedPrincipalType.value === 'iamRoleUserAccess' && !validateIAMPrincipal())
    ) {
      return;
    }
    if (selectedPermission.type == RESOURCE_ITEM) {
      let ds = await flattenDatasetAllTableAccess(
        selectedPermission.catalogId,
        selectedPermission.catalogName,
        selectedPermission.permissionId,
        selectedPermission.owner,
        selectedPermission.datasetName,
        selectedPermission.databaseName,
        selectedPermission.id,
        selectedPermission.publisherRole,
        principal,
      );
      props.addToCart([ds]);
    } else if (selectedPermission.type == TAG_ITEM) {
      let cartItem = await flattenTagAccess(
        selectedPermission.catalogId,
        selectedPermission.catalogName,
        selectedPermission.permissionId,
        selectedPermission.tagKey,
        selectedPermission.tagValue,
        selectedPermission.owner,
        selectedPermission.id,
        principal,
      );
      props.addToCart([cartItem]);
    }
    props.dismiss();
  };

  const transferResource = async (selectedPermission) => {
    setLoadingResources(true);
    let res = [];
    if (selectedPermission == undefined) {
      return res;
    }
    //named resource
    if (selectedPermission?.type == RESOURCE_ITEM) {
      res.push({
        type: DATASET_RESOURCE_TYPE,
        resource: `${selectedPermission.catalogName}/${selectedPermission.databaseName}/${selectedPermission.datasetName}`,
        resourceLink: createWSGlueDatabaseDetailLink(
          selectedPermission.catalogId,
          selectedPermission.databaseName,
          props.database['Region'],
        ),
      });
      setItems(res);
    } else if (selectedPermission.type == TAG_ITEM) {
      //tag resource
      let getTaggedResourcesResponse = await getTaggedResources({
        tagPair: selectedPermission.tagKey + ':' + selectedPermission.tagValue,
        catalogId: selectedPermission.catalogId,
        region: props.database['Region'],
        type: LAKEFORMATION_TAG_TYPE,
      });
      for (let resource of getTaggedResourcesResponse.resources) {
        if (resource.resourceType == DATASET_RESOURCE_TYPE) {
          res.push({
            type: DATASET_RESOURCE_TYPE,
            resource: `${selectedPermission.catalogName}/${resource.databaseName}/${resource.resourceName}`,
            resourceLink: createWSDatasetDetailLink(resource.resourceId),
          });
        } else if (resource.resourceType == DATABASE_RESOURCE_TYPE) {
          res.push({
            type: DATABASE_RESOURCE_TYPE,
            resource: `${selectedPermission.catalogName}/${resource.databaseName}`,
            resourceLink: createWSGlueDatabaseDetailLink(resource.catalogId, resource.databaseName, resource.region),
          });
        }
      }
      setItems(res);
    }
    setLoadingResources(false);
  };

  const { items } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>no resources attached</b>
          </div>
          <p className='awsui-util-mb-s'>No resources to display.</p>
        </div>
      ),
    },

    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const validateIAMPrincipal = () => {
    let errorText = !isValidPrincipalNotRoot(principal)
      ? 'Invalid IAM principal ARN'
      : selectedPermission.principals.filter((dataLakePrincipal) => dataLakePrincipal === principal).length > 0
      ? 'Principal already consumed or is in your cart'
      : undefined;
    setPrincipalErrorText(errorText);
    return errorText === undefined;
  };

  const displayResource = () => {
    return (
      <Table
        columnDefinitions={[
          {
            id: 'resource',
            header: 'Resource',
            cell: (item) => <Link to={item.resourceLink}>{item.resource}</Link>,
          },
          {
            id: 'type',
            header: 'Resource type',
            cell: (item) => item.type,
          },
        ]}
        items={items}
        loading={loadingResources}
        loadingText='Loading resources'
        sortingDisabled
        header={<Header> Resources </Header>}
      />
    );
  };

  const handleChange = (e) => {
    setSelectedPermission(e.detail.selectedOption);
    setSelectedPrincipalType(undefined);
    setDropDownPrincipalOptions([
      {
        label: 'Workspace access (Entire account)',
        value: 'workspaceAccess',
        disabled: e.detail.selectedOption.workspaceAccess,
      },
      {
        label: 'IAM role/user access',
        value: 'iamRoleUserAccess',
      },
    ]);
    transferResource(e.detail.selectedOption);
  };

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirm}>
              Add to cart
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Select data access approach'
    >
      <ColumnLayout>
        <FormField label='Select Lake Formation access permission type'>
          <Select
            selectedOption={selectedPermission}
            onChange={handleChange}
            options={dropdownOptions}
            selectedAriaLabel='Selected'
            empty={'All Tables access not supported for ' + `"${props.database.DatabaseName}"`}
            statusType={loadingResources ? 'loading' : 'finished'}
            loadingText='Loading access options'
          />
        </FormField>
        {selectedPermission !== undefined && (
          <FormField label='Select Lake Formation access principal type'>
            <Select
              selectedOption={selectedPrincipalType}
              onChange={(e) => {
                setSelectedPrincipalType(e.detail.selectedOption);
              }}
              options={dropdownPrincipalOptions}
              statusType={loadingResources ? 'loading' : 'finished'}
              loadingText='Loading access options'
            />
          </FormField>
        )}
        {selectedPermission !== undefined &&
          selectedPrincipalType !== undefined &&
          selectedPrincipalType.value === 'iamRoleUserAccess' && (
            <FormField
              label='IAM User/Role'
              description='Enter the ARN for the IAM user or role'
              errorText={principalErrorText}
            >
              <Input
                ariaRequired={true}
                placeholder={'arn:aws:iam::123456789012:role/AWSDGSFoundry-ManagementRole-DO-NOT-DELETE'}
                value={principal}
                onChange={(e) => {
                  setPrincipalErrorText(undefined);
                  setPrincipal(e.detail.value);
                }}
              />
            </FormField>
          )}
        {selectedPermission !== undefined && displayResource()}
      </ColumnLayout>
    </Modal>
  );
};
