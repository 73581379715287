import {
  Box,
  Link,
  Pagination,
  Table,
  CollectionPreferences,
  CollectionPreferencesProps,
  PropertyFilter,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { getWorkspacesCredentials, listDataPermissions } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { Link as RRLink } from 'react-router-dom';
import { createWSDatasetDetailLink, createWSGlueDatabaseDetailLink } from 'src/routes';
import { EmptyState } from 'src/commons/EmptyState';
import {
  ANALYTICS_FEDERATION_TIME_OUT,
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  OMNI_ROLES_WIKI_URL,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { FlashbarProps } from '@amzn/awsui-components-react-v3/polaris/flashbar/interfaces';

export interface WorkspaceResourceLinksProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  catalogMap: any;
  setNotifications?: (notifications: ReadonlyArray<FlashbarProps.MessageDefinition>) => void;
}

export const WorkspaceResourceLinks = (props: WorkspaceResourceLinksProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });
  const columnDefinitions = [
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => item.databaseName,
      minWidth: 250,
    },
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) => (item.tableName == undefined ? '*' : item.tableName),
      minWidth: 250,
    },
    {
      id: 'sharedResource',
      header: '' + 'Source resource',
      cell: (item) =>
        item.tableName == undefined ? (
          <RRLink to={createWSGlueDatabaseDetailLink(item?.sourceCatalogId, item?.sourceDatabase, item?.region)}>
            {item.sourceResource}
          </RRLink>
        ) : (
          <RRLink
            to={createWSDatasetDetailLink(
              `DS-glueLF|A-${item?.sourceCatalogId}|DN-${item?.sourceDatabase}|TN-${item?.sourceDataset}|R-${item?.region}`,
            )}
          >
            {item.sourceResource}
          </RRLink>
        ),
      minWidth: 250,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);
    let listDataPermissionsRequest = {
      ownerId: props.activeWorkspace.workspaceId,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      option: DATA_PERMISSION_CONSUMER_OPTION,
      nextToken: null,
    };

    let listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
    let permissionList = [...listDataPermissionsResult.dataPermissionList];
    //Loop and get remaining tables
    while (listDataPermissionsResult.nextToken != null) {
      listDataPermissionsRequest.nextToken = listDataPermissionsResult.nextToken;
      listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
      permissionList.push(...listDataPermissionsResult.dataPermissionList);
    }

    let rlList = [];
    for (let permission of permissionList) {
      if (permission.resource?.glueTable !== undefined) {
        let glueTable = permission.resource?.glueTable;
        let sourceResource = glueTable?.targetTable;
        let catalogName = props.catalogMap.get(`${glueTable?.catalogId}:${permission.region}`);
        let sourceCatalogName = props.catalogMap.get(`${sourceResource?.catalogId}:${permission.region}`);
        rlList.push({
          catalogId: glueTable?.catalogId,
          databaseName: glueTable?.databaseName,
          sourceCatalogId: sourceResource?.catalogId,
          tableName: glueTable?.name,
          sourceResource: `${sourceCatalogName}/${sourceResource?.databaseName}/${sourceResource?.name}`,
          catalogName: catalogName,
          region: permission?.region,
        });
      } else if (permission.resource?.glueDatabase !== undefined) {
        let glueDatabase = permission.resource?.glueDatabase;
        let sourceResource = glueDatabase?.targetDatabase;
        let catalogName = props.catalogMap.get(`${glueDatabase?.catalogId}:${permission.region}`);
        let sourceCatalogName = props.catalogMap.get(`${sourceResource?.catalogId}:${permission.region}`);

        rlList.push({
          catalogId: glueDatabase?.catalogId,
          databaseName: glueDatabase?.name,
          sourceCatalogId: sourceResource?.catalogId,
          sourceResource: `${sourceCatalogName}/${sourceResource?.databaseName}/*`,
          catalogName: catalogName,
          region: permission.region,
        });
      }
    }

    for (let resource of rlList) {
      let source = resource.sourceResource;
      const arr = source.split('/');
      resource.sourceDatabase = arr[1];
      resource.sourceDataset = arr[2] == '*' ? undefined : arr[2];
    }
    setItems(rlList);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No resource links</b>
          </div>
          <p className='awsui-util-mb-s'>No resource links found</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Database name',
          key: 'databaseName',
          groupValuesLabel: 'Database names',
        },
        {
          propertyLabel: 'Dataset name',
          key: 'datasetName',
          groupValuesLabel: 'Dataset names',
        },
        {
          propertyLabel: 'Source resource',
          key: 'sourceResource',
          groupValuesLabel: 'Source resources',
        },
      ],
    },
  });

  const federateIntoWorkspace = async () => {
    if (props.activeWorkspace !== undefined) {
      let response;
      try {
        response = await getWorkspacesCredentials({
          workspaceId: props.activeWorkspace.workspaceId,
          federatedService: 'Athena',
          expirationTimeMins: ANALYTICS_FEDERATION_TIME_OUT,
          getConsoleURL: true,
          getIAMCredentials: false,
        });
        console.log('isengard url:', response);
        window.open(response.consoleURL);
      } catch (err) {
        if (err.name == 'InsufficientPermissionException') {
          props.setNotifications?.([
            {
              type: 'error',
              content: (
                <Box>
                  Insufficient permissions to federate to Athena, make sure you are member of an{' '}
                  <Link external href={OMNI_ROLES_WIKI_URL}>
                    Omni role
                  </Link>{' '}
                  that allows querying data.
                </Box>
              ),
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => props.setNotifications?.([]),
              id: 'insufficientAthenaPermissions',
            },
          ]);
        }
        console.log('Failed to federate into workspace. Reason:', err.message);
      }
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resource links...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        empty={<EmptyState title='No Resource links' subtitle='No Resource links were found.' />}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Athena query',
                  onItemClick: () => {
                    federateIntoWorkspace();
                  },
                },
              ]}
              header={
                <>
                  Resource links created
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
