import * as React from 'react';
import { Component } from 'react';
import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';

import { getBootstrapAction, deleteBootstrapAction } from '../../../api/resourcesmanager';
import { labeledTextFromList, RMPageHeader, ErrorAlert, Guardrail_UNBOUND, AuditLogsCard, s3url } from '../components';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../routes/Paths';
import { dateString } from '../helpers';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';

export interface bootstrapActionDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface bootstrapActionDetailState {
  loading: boolean;
  bootstrapAction: object;
  error: object;
  redirect: string;
  contentType: string;
  redirectParams: object;
  guardrail: any;
}

export class BootstrapActionDetails extends Component<bootstrapActionDetailProps, bootstrapActionDetailState> {
  state = {
    loading: true,
    error: undefined,
    bootstrapAction: null,
    redirect: undefined,
    contentType: undefined,
    redirectParams: {},
    guardrail: null,
  };

  componentDidMount = async () => {
    this.props.setContentType(CARDS_CONTENT_TYPE);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  /**
   * Asynchronously fetches the bootstrapactions from the RM API and updates the state.
   */
  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const bootstrapaction = await getBootstrapAction({
        id: this.props.match.params.id,
      });
      this.setState({
        bootstrapAction: bootstrapaction,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: { ...err, while: 'LOADING BOOTSTRAP ACTION' },
        bootstrapAction: null,
        loading: false,
      });
    }
  };

  /**
   * [UNGUARDED]: Asynchronously calls the DELETE API for the given bootstrap action.
   */
  deleteBSA_UNGUARDED = async () => {
    try {
      const response = await deleteBootstrapAction({
        id: this.props.match.params.id,
      });

      if (response.successful) {
        this.setState({ redirect: Page.BOOTSTRAPACTIONS });
      } else {
        this.setState({
          error: {
            ...response,
            code: 'ResponseNotSuccessful',
            while: 'DELETING BOOTSTRAP ACTION',
          },
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: { ...err, while: 'DELETING BOOTSTRAP ACTION' } });
    }
  };

  /**
   * A wrapper which requires the user to confirm before submitting a DeleteBootstrapAction request to the API.
   */
  deleteBSA = async () => {
    this.setState({
      guardrail: {
        header: `DELETE ${this.props.match.params.id}?`,
        action: this.deleteBSA_UNGUARDED,
      },
    });
  };

  /**
   * Redirects the user to the Update Bootstrap Action page.
   */
  updateBSA = () => {
    this.setState({
      redirect: Page.UPDATE_BOOTSTRAPACTION.replace(':id', this.props.match.params.id),
    });
  };

  /**
   * Redirects the user to the Create Bootstrap Action page, prefilled with the configuration of the current BootstrapAction.
   */
  cloneBSA = () => {
    this.setState({
      redirect: Page.CREATE_BOOTSTRAPACTION,
      redirectParams: { values: this.state.bootstrapAction },
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    return (
      <>
        {ErrorAlert(this.state.error)}
        {Guardrail_UNBOUND.bind(this)()}

        <SpaceBetween size='l'>
          <RMPageHeader
            buttons={[
              {
                text: 'Delete',
                onItemClick: this.deleteBSA,
              },
              {
                text: 'Edit',
                onItemClick: this.updateBSA,
              },
              {
                text: 'Clone',
                onItemClick: this.cloneBSA,
              },
            ]}
            subheader={this.state.bootstrapAction?.name}
          />

          <Container header={<Header variant='h2'> Bootstrap action details </Header>}>
            <ColumnLayout columns={2} borders='horizontal'>
              {labeledTextFromList(
                [
                  {
                    label: 'Bootstrap action ID',
                    value: this.state.bootstrapAction?.id,
                    copiable: true,
                  },
                  {
                    label: 'Description',
                    value: this.state.bootstrapAction?.description,
                  },
                  {
                    label: 'S3 location',
                    value: this.state.bootstrapAction?.s3FileLocation,
                    url: s3url(this.state.bootstrapAction?.s3FileLocation),
                    copiable: true,
                  },
                  {
                    label: 'Args',
                    value: this.state.bootstrapAction?.args
                      ? this.state.bootstrapAction?.args.join(', ')
                      : 'no arguments',
                  },
                  {
                    label: 'Owner (Group ID)',
                    value: this.state.bootstrapAction?.groupId,
                    copiable: true,
                  },
                  {
                    label: 'Is default?',
                    value: this.state.bootstrapAction?.isDefaultBootstrap ? 'Yes' : 'No',
                  },
                  {
                    label: 'Created by',
                    value: this.state.bootstrapAction?.createdBy,
                    copiable: true,
                  },
                  {
                    label: 'Create date',
                    value: dateString(this.state.bootstrapAction?.createDate),
                  },
                  {
                    label: 'Updated by',
                    value: this.state.bootstrapAction?.updatedBy,
                    copiable: true,
                  },
                  {
                    label: 'Update date',
                    value: dateString(this.state.bootstrapAction?.updateDate),
                  },
                ],
                this.state.loading,
              )}
            </ColumnLayout>
          </Container>

          <AuditLogsCard auditLogs={this.state.bootstrapAction?.auditLogs} loading={this.state.loading} />
        </SpaceBetween>
      </>
    );
  }
}
