import * as React from 'react';
import { Component } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Toggle,
  Checkbox,
  Icon,
} from '@amzn/awsui-components-react-v3';
import * as validate from 'src/commons/validationUtils';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  dataClassificationOptions,
  refreshCadenceOptions,
  TABLE_STATUS_DEPRECATED,
  tableStatusOptions,
} from 'src/commons/constants';
import { editDataset } from 'src/api/catalog';
import { isValidGroup, isValidWorkspace } from 'src/commons/validationUtils';
import { fetchActiveConsumerDataPermissions } from 'src/components/permissions/myBaselining/baseliningUtils';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';

export interface DatasetDetailsEditModalProps {
  visible: boolean;
  dismiss: any;
  notifySuccess: any;
  notifyFailure: any;
  dataset: any;
  activeGroup: string;
}

export interface DatasetDetailsEditModalState {
  // used for edit dataset(store the values after editing)
  editSuccess: boolean;
  editableConfidenceFileLocation: string;
  editablePII: boolean;
  editableSLA: string;
  editableDataSetName: string;
  editableOwners: string;
  editableKmsKey: string;
  dataClassificationOption: SelectProps.Option;
  editableDataClassification: string;
  refreshCadenceOption: SelectProps.Option;
  editableRefreshCadence: string;
  tableStatusOption: SelectProps.Option;
  editableTableStatus: string;
  editableTableDescription: string;
  saveEditLoading: boolean;
  deprecationConfirmed: boolean;
  deprecationConfirmationContent: string;
}

export class DatasetDetailsEditModal extends Component<DatasetDetailsEditModalProps, DatasetDetailsEditModalState> {
  state = {
    editSuccess: true,
    editableConfidenceFileLocation: undefined,
    editablePII: false,
    editableSLA: undefined,
    editableDataSetName: undefined,
    editableOwners: '',
    editableKmsKey: undefined,
    dataClassificationOption: undefined,
    editableDataClassification: undefined,
    refreshCadenceOption: undefined,
    editableRefreshCadence: undefined,
    tableStatusOption: undefined,
    editableTableStatus: undefined,
    editableTableDescription: undefined,
    saveEditLoading: false,
    deprecationConfirmed: undefined,
    deprecationConfirmationContent: undefined,
  };

  componentDidMount() {
    this.resetForm();
  }

  resetForm() {
    this.clearEditableParameters();
    this.initializeEditableParameters();
  }
  // clear all the editable fields before assigning
  clearEditableParameters() {
    this.setState({
      editablePII: false,
      editableDataSetName: undefined,
      editableSLA: undefined,
      editableConfidenceFileLocation: undefined,
      editableKmsKey: undefined,
      editableOwners: '',
      editableDataClassification: undefined,
      editableRefreshCadence: undefined,
      editableTableStatus: undefined,
      editableTableDescription: undefined,
    });
  }
  // this will initialize editable fields with the current fields, so they can be prefilled in form
  initializeEditableParameters() {
    let dataClassificationIndex, refreshCadenceIndex, tableStatusIndex;
    const currentDataClassification = this.props.dataset?.DataClassification;
    const currentRefreshCadence = this.props.dataset?.RefreshCadence;
    const currentTableStatus = this.props.dataset?.TableState;
    // setting up the retrieved value for DataClassification to be displayed
    dataClassificationIndex = this.getDataClassificationIndex(currentDataClassification);
    refreshCadenceIndex = this.getRefreshCadenceIndex(currentRefreshCadence);
    tableStatusIndex = this.getTableStatusIndex(currentTableStatus);
    // converting Array type of Owners to comma seperated GroupIds
    for (const item of this.props.dataset.Owners) {
      if (this.state.editableOwners === '') {
        this.state.editableOwners = this.state.editableOwners + item;
      } else {
        this.state.editableOwners = this.state.editableOwners + ',' + item;
      }
    }
    const currentOwners = this.props.dataset?.Owners.join(',');
    this.setState({
      editSuccess: true,
      editableConfidenceFileLocation: this.props.dataset?.WheeljackConfidenceFileLocation,
      editableSLA: this.props.dataset?.SLA ? this.props.dataset.SLA : '07:00 (PST)',
      editableDataSetName: this.props.dataset?.DataSetName,
      editableTableDescription: this.props.dataset?.DataSetDesc,
      editableKmsKey: this.props.dataset?.KmsKey,
      dataClassificationOption: dataClassificationOptions[dataClassificationIndex],
      editableDataClassification: dataClassificationOptions[dataClassificationIndex].label,
      refreshCadenceOption: refreshCadenceOptions[refreshCadenceIndex],
      editableRefreshCadence: refreshCadenceOptions[refreshCadenceIndex].label,
      tableStatusOption: tableStatusOptions[tableStatusIndex],
      editableTableStatus: tableStatusOptions[tableStatusIndex].label,
      editablePII: this.props.dataset?.PII ? this.props.dataset.PII : false,
      editableOwners: currentOwners,
      deprecationConfirmed: undefined,
      deprecationConfirmationContent: undefined,
    });
  }

  async fetchActiveConsumerData(dataset) {
    let dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
    let resource = {
      accountId: dataset.IdInfo.CatalogId,
      region: dataset.IdInfo.Region,
      type: 'TABLE',
      dataCatalogObjectDetails: {
        dataSourceId: dataSourceId,
        clusterIdentifier: dataset.IdInfo.ClusterIdentifier,
        databaseName: dataset.IdInfo.DatabaseName,
        schemaName: dataset.IdInfo.SchemaName,
        tableName: dataset.IdInfo.TableName,
      },
      dpType: dataSourceId === 'GlueLF' ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_REDSHIFT_TYPE,
      tagResourceId: dataset.Id,
      tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
    };
    let activeDataPermissions = await fetchActiveConsumerDataPermissions(resource);
    this.setState({
      deprecationConfirmationContent: `This dataset has ${activeDataPermissions.length} active consumer(s). Confirm deprecation? (Existing consumer permissions will not be revoked)`,
    });
  }

  // gets the index from dataClassificationOptions based on dataClassification
  getDataClassificationIndex(dataClassification) {
    for (const item of dataClassificationOptions) {
      if (item.label === dataClassification) {
        return parseInt(item.id) - 1;
      }
    }
    return dataClassificationOptions.length - 1; // default is Restricted(last item)
  }
  // gets the index from refreshCadenceOptions based on refreshCadence
  getRefreshCadenceIndex(refreshCadence) {
    for (const item of refreshCadenceOptions) {
      if (item.label === refreshCadence) {
        return parseInt(item.id) - 1;
      }
    }
    return refreshCadenceOptions.length - 1; // default is Never(last item)
  }
  // gets the index from tableStatusOptions based on tableState
  getTableStatusIndex(tableState) {
    for (const item of tableStatusOptions) {
      if (item.label === tableState) {
        return parseInt(item.id) - 1;
      }
    }
    return tableStatusOptions.length - 1; // default is Active(last item)
  }
  // On clicking Save Dataset this will make an api call and stores the Data
  handleEditDataSet = async () => {
    this.setState({
      saveEditLoading: true,
    });
    // converting string type into Array to pass through Edit API
    const ownersArray = this.state.editableOwners.split(',').map((item) => item.trim());
    try {
      let request = {
        Id: this.props.dataset?.Id,
        DataSetName: this.state.editableDataSetName,
        KmsKey: this.state.editableKmsKey,
        WheeljackConfidenceFileLocation: this.state.editableConfidenceFileLocation,
        DatalakeSLA: this.state.editableSLA,
        DataClassification: this.state.editableDataClassification,
        RefreshCadence: this.state.editableRefreshCadence,
        PII: this.state.editablePII,
        TableState: this.state.editableTableStatus,
        Description: this.state.editableTableDescription,
      };
      // add owners if edited. Todo: HC owners validation to be updated
      const currentOwners = this.props.dataset?.Owners.join(',');
      if (this.state.editableOwners !== currentOwners) {
        request['Owners'] = ownersArray;
      }
      const editResponse = await editDataset(request);
      if (editResponse.Message.includes('Failed') || editResponse.Message.includes('Exception')) {
        this.setState({
          editSuccess: false,
        });
      }
    } catch (e) {
      this.setState({
        editSuccess: false,
      });
      console.log('Error msg:');
      console.log(e);
    } finally {
      this.setState({
        saveEditLoading: false,
      });
      if (this.state.editSuccess) {
        this.props.notifySuccess();
      } else {
        this.props.notifyFailure();
      }
      this.props.dismiss();
    }
  };

  // will validate Editable parameters provided and changes the visibility of Save Button
  disableEditSaveButton() {
    return !(
      validate.isValidTimeFormat(this.state.editableSLA) &&
      (validate.isFalsy(this.state.editableConfidenceFileLocation) ||
        validate.isValidS3Path(this.state.editableConfidenceFileLocation)) &&
      (validate.isFalsy(this.state.editableKmsKey) || validate.isValidKmsKey(this.state.editableKmsKey)) &&
      // can remove this line when started supporting PII data
      !this.state.editablePII
    );
  }

  render() {
    return (
      <Modal
        onDismiss={() => {
          this.props.dismiss();
        }}
        visible={this.props.visible}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  this.props.dismiss();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={
                  (this.state.tableStatusOption?.label == TABLE_STATUS_DEPRECATED &&
                    !this.state.deprecationConfirmed) ||
                  this.disableEditSaveButton()
                }
                loading={this.state.saveEditLoading}
                onClick={this.handleEditDataSet}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Edit dataset'
      >
        <ColumnLayout>
          <FormField label='Dataset ID'>
            <Input value={this.props.dataset?.Id} disabled={true} />
          </FormField>
          <FormField label='Dataset name' description='To be displayed on Omni.'>
            <Input
              ariaRequired={true}
              placeholder={'Name of the dataset'}
              value={this.state.editableDataSetName}
              onChange={(e) =>
                this.setState({
                  editableDataSetName: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField label='Description' description='Description of the dataset'>
            <Input
              ariaRequired={true}
              placeholder={'Description of the dataset'}
              value={this.state.editableTableDescription}
              onChange={(e) =>
                this.setState({
                  editableTableDescription: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField
            label='SLA'
            description='Time at which the data is updated.'
            errorText={validate.isValidTimeFormat(this.state.editableSLA) ? false : 'Invalid Format. Should be hh:mm.'}
          >
            <Input
              ariaRequired={true}
              placeholder={'hh:mm (PST)'}
              value={this.state.editableSLA}
              onChange={(e) =>
                this.setState({
                  editableSLA: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField
            label='Confidence file location'
            description='S3 file indicating the data is ready to be read.'
            errorText={
              validate.isFalsy(this.state.editableConfidenceFileLocation)
                ? false
                : validate.isValidS3Path(this.state.editableConfidenceFileLocation)
                ? false
                : 'The S3 path is invalid.'
            }
          >
            <Input
              ariaRequired={true}
              placeholder={'s3://bucket-name/path/to/confidence_file.cfd'}
              value={this.state.editableConfidenceFileLocation}
              invalid={
                validate.isFalsy(this.state.editableConfidenceFileLocation)
                  ? false
                  : !validate.isValidS3Path(this.state.editableConfidenceFileLocation)
              }
              onChange={(e) =>
                this.setState({
                  editableConfidenceFileLocation: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField
            label='KMS key ARN'
            description='Required if the dataset is encrypted using KMS.'
            errorText={
              validate.isFalsy(this.state.editableKmsKey)
                ? false
                : validate.isValidKmsKey(this.state.editableKmsKey)
                ? false
                : 'The key ARN format is invalid.'
            }
          >
            <Input
              ariaRequired={true}
              placeholder={'arn:aws:kms:us-east-1:accountId:key/value'}
              value={this.state.editableKmsKey}
              invalid={
                validate.isFalsy(this.state.editableKmsKey) ? false : !validate.isValidKmsKey(this.state.editableKmsKey)
              }
              onChange={(e) =>
                this.setState({
                  editableKmsKey: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField
            label='Owners'
            description="Comma-separated Omni Group or Workspace ID's"
            errorText={
              validate.isFalsy(this.state.editableOwners)
                ? 'Owners field cannot be empty.'
                : this.state.editableOwners
                    .split(',')
                    .map((id) => id.trim())
                    .filter((id) => !(isValidWorkspace(id) || isValidGroup(id))).length === 0
                ? false
                : 'Invalid Group or WorkspaceIds!'
            }
          >
            <Input
              ariaRequired={true}
              placeholder={'GroupId1,GroupId2,wks1'}
              value={this.state.editableOwners}
              onChange={(e) =>
                this.setState({
                  editableOwners: e.detail.value,
                })
              }
            />
          </FormField>
          <FormField label='Data visibility' description='Set to Private to hide the dataset on Omni.'>
            <Select
              selectedOption={this.state.dataClassificationOption}
              options={dataClassificationOptions}
              selectedAriaLabel='Selected'
              onChange={(e) =>
                this.setState({
                  dataClassificationOption: e.detail.selectedOption,
                  editableDataClassification: e.detail.selectedOption.label,
                })
              }
            />
          </FormField>
          <FormField label='Refresh cadence' description='Set to Never if not required.'>
            <Select
              selectedOption={this.state.refreshCadenceOption}
              options={refreshCadenceOptions}
              selectedAriaLabel='Selected'
              onChange={(e) =>
                this.setState({
                  refreshCadenceOption: e.detail.selectedOption,
                  editableRefreshCadence: e.detail.selectedOption.label,
                })
              }
            />
          </FormField>
          <FormField label='Table status' description='Current status of the dataset.'>
            <Select
              selectedOption={this.state.tableStatusOption}
              options={tableStatusOptions}
              selectedAriaLabel='Selected'
              onChange={(e) => {
                this.setState({
                  tableStatusOption: e.detail.selectedOption,
                  editableTableStatus: e.detail.selectedOption.label,
                });
                if (e.detail.selectedOption.label == TABLE_STATUS_DEPRECATED) {
                  this.fetchActiveConsumerData(this.props.dataset);
                }
              }}
            />
          </FormField>
          <FormField
            label='Contains PII data'
            errorText={this.state.editablePII ? 'PII data is only supported when using Omni workspaces.' : false}
          >
            <Toggle
              checked={this.state.editablePII}
              onChange={(e) =>
                this.setState({
                  editablePII: e.detail.checked,
                })
              }
            />
          </FormField>
          {this.state.tableStatusOption?.label == TABLE_STATUS_DEPRECATED &&
            this.state.deprecationConfirmationContent && (
              <Checkbox
                checked={this.state.deprecationConfirmed}
                onChange={(e) =>
                  this.setState({
                    deprecationConfirmed: e.detail.checked,
                  })
                }
              >
                <Icon name='status-warning' variant='warning' /> {this.state.deprecationConfirmationContent}
              </Checkbox>
            )}
        </ColumnLayout>
      </Modal>
    );
  }
}
