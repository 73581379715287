import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Badge,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { listDatasetsForDatabase } from 'src/api/catalog';
import { Link } from 'react-router-dom';
import { createWSDatasetDetailLink } from 'src/routes';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface WSDatasetListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogId: any;
  databaseName: any;
  datasets: [];
  match: any;
}

export const WSDatasetList = (props: WSDatasetListProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);

  const handleRefresh = async () => {
    setLoadingDatasets(true);

    let nextToken = '';
    let datasetList = [];
    while (nextToken != null) {
      try {
        let datasets;
        if (nextToken != '') {
          datasets = await listDatasetsForDatabase({
            CatalogId: props.match.params.catalogid,
            DatabaseName: props.match.params.databasename,
            NextToken: nextToken,
          });
        } else {
          datasets = await listDatasetsForDatabase({
            CatalogId: props.match.params.catalogid,
            DatabaseName: props.match.params.databasename,
          });
        }
        datasetList = datasetList.concat(datasets.DataSetList);
        nextToken = datasets.NextToken;
      } catch (err) {
        setItems([]);
        setLoadingDatasets(false);
        break;
      }
    }
    setItems(datasetList);
    setLoadingDatasets(false);
  };

  useEffect(() => {
    handleRefresh();
  }, [props.catalogId]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => (
        <SpaceBetween size={'xs'} direction={'horizontal'}>
          <Link to={createWSDatasetDetailLink(item?.Id)}>{item.DataSetName}</Link>
          {item?.PII ? <Badge color='red'>PII dataset</Badge> : ''}
        </SpaceBetween>
      ),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Dataset name',
          key: 'DataSetName',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });
  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No registered datasets</p>
          </div>
        }
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Datasets
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
