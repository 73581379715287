import { Flashbar } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { auditDataPermission, getDataLink, getDataPermission, listDataPermissions } from 'src/api/permissions';
import { PageHeader } from 'src/components/notifications/common';
import { Link } from 'react-router-dom';
import {
  createGroupDetailLink,
  createLFPermissionDetailLink,
  createRedshiftPermissionDetailLink,
  createWorkspaceDetailLink,
} from 'src/routes';
import { DATA_PERMISSION_CONSUMER_OPTION, REDSHIFT_PERMISSION_TYPE, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { DataPermissionConsumers } from 'src/components/permissions/myDatasets/common';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';
import { dataPermissionDetail, flattenDataPermission } from 'src/components/workspaces/common/common';

export interface PublishedPermissionDetailProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  match: any;
  dismiss: any;
  dataset: any;
  addToCart: any;
  catalogName: any;
  catalogMap: any;
}

export const PublishedPermissionDetail = (props: PublishedPermissionDetailProps) => {
  const [loadingResource, setLoadingResource] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [consumedPermissions, setConsumedPermissions] = useState([]);
  const [dataPermission, setDataPermission] = useState(undefined);

  const consumerPermissionColumnDefinitions = [
    {
      id: 'dataPermissionId',
      header: 'Dataset Permission ID',
      cell: (item) =>
        item.type == REDSHIFT_PERMISSION_TYPE ? (
          <Link to={createRedshiftPermissionDetailLink(item?.dataPermissionId)}>{item.dataPermissionId}</Link>
        ) : (
          <Link to={createLFPermissionDetailLink(item?.dataPermissionId)}>{item.dataPermissionId}</Link>
        ),
      minWidth: 200,
    },
    {
      id: 'dataLakePrincipal',
      header: 'DataLake principal',
      cell: (item) => item.dataLakePrincipal,
      minWidth: 120,
      sortingField: 'dataLakePrincipal',
    },
    {
      id: 'ownerId',
      header: 'Owner Id',
      cell: (item) => (
        <Link
          to={
            isValidWorkspace(item.ownerId)
              ? createWorkspaceDetailLink(item.ownerId)
              : createGroupDetailLink(item.ownerId)
          }
        >
          {item.ownerId}
        </Link>
      ),
      minWidth: 120,
      sortingField: 'ownerId',
    },
    {
      id: 'resource',
      header: 'Resource',
      cell: (item) => item.resource,
      minWidth: 120,
      sortingField: 'resource',
    },
    {
      id: 'activatedDate',
      header: 'Activated date',
      cell: (item) => item.dateActive,
      minWidth: 210,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => <StatusIcon status={item.status} />,
      minWidth: 100,
    },
    {
      id: 'auditDate',
      header: 'Audit date',
      cell: (item) => item.dateOfLastAudit,
      minWidth: 210,
    },
    {
      id: 'auditStatus',
      header: 'Audit status',
      cell: (item) => <StatusIcon status={item.auditStatus} />,
      minWidth: 100,
    },
  ];

  const fetchDataPermission = async () => {
    try {
      const dataPermissionResult = await getDataPermission({
        dataPermissionId: props.match.params.permissionId,
        metadata: props.match.params.permissionId,
      });
      setDataPermission(dataPermissionResult?.DataPermission);
      await fetchConsumerPermissions(dataPermissionResult?.DataPermission);
      await getDataLink({
        dataPermissionId: props.match.params.permissionId,
      });
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to get data permission ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const fetchConsumerPermissions = async (publisherDataPermission) => {
    let resource = undefined;
    if (publisherDataPermission.resource.table !== undefined) {
      resource = {
        table: {
          databaseName: publisherDataPermission.resource.table.databaseName,
          name: publisherDataPermission.resource.table.name,
          catalogId: publisherDataPermission.resource.table.catalogId,
          tableWildcard: null,
        },
      };
    } else if (publisherDataPermission.resource.tableWithColumns !== undefined) {
      resource = {
        tableWithColumns: {
          databaseName: publisherDataPermission.resource.tableWithColumns.databaseName,
          name: publisherDataPermission.resource.tableWithColumns.name,
          catalogId: publisherDataPermission.resource.tableWithColumns.catalogId,
          columnNames: publisherDataPermission.resource.tableWithColumns.columnNames,
          columnWildcard: publisherDataPermission.resource.tableWithColumns.columnWildcard,
        },
      };
    } else if (publisherDataPermission.resource.lFTagPolicy !== undefined) {
      resource = publisherDataPermission.resource.lFTagPolicy;

      resource = {
        lFTagPolicy: {
          resourceType: publisherDataPermission.resource.lFTagPolicy.resourceType,
          expression: publisherDataPermission.resource.lFTagPolicy.expression,
          catalogId: publisherDataPermission.resource.lFTagPolicy.catalogId,
        },
      };
    } else if (publisherDataPermission.resource.redshiftTagPolicy !== undefined) {
      resource = {
        redshiftTagPolicy: publisherDataPermission.resource.redshiftTagPolicy,
      };
    } else if (publisherDataPermission.resource.dataCellsFilter !== undefined) {
      resource = {
        dataCellsFilter: publisherDataPermission.resource.dataCellsFilter,
      };
    }

    let listDataPermissionsRequest = {
      resource: resource,
      region: publisherDataPermission.region,
      status: publisherDataPermission.status,
      option: DATA_PERMISSION_CONSUMER_OPTION,
      type: publisherDataPermission.type,
      nextToken: null,
    };

    let listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
    let dataPermissions = [...listDataPermissionsResult.dataPermissionList];
    //Loop and get remaining tables
    while (listDataPermissionsResult.nextToken != null) {
      listDataPermissionsRequest.nextToken = listDataPermissionsResult.nextToken;
      listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
      dataPermissions.push(...listDataPermissionsResult.dataPermissionList);
    }
    if (publisherDataPermission.fgapId) {
      dataPermissions = dataPermissions.filter((dp) => {
        return publisherDataPermission.fgapId === dp.fgapId;
      });
    }

    setConsumedPermissions(dataPermissions.map((item) => flattenDataPermission(item)).filter((item) => item != null));
  };

  const handleRefresh = async () => {
    setLoadingResource(true);
    await fetchDataPermission();
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const auditCurrentDataPermission = async () => {
    setLoadingButton(true);
    try {
      const response = await auditDataPermission({
        dataPermissionId: props.match.params.permissionId,
      });
      if (response.auditStatus === 'SUCCEEDED') {
        setNotifications([
          {
            type: 'success',
            content: `Audit of ${props.match.params.permissionId} succeeded`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        setNotifications([
          {
            type: 'error',
            content: `Audit of ${props.match.params.permissionId} failed`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
      await handleRefresh();
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
      setNotifications([
        {
          type: 'error',
          content: `Audit of ${props.match.params.permissionId} failed because ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <>
      <Flashbar items={notifications}></Flashbar>

      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Audit data permission',
            onItemClick: auditCurrentDataPermission,
            loading: loadingButton,
          },
        ]}
        header={`Dataset Permission ID: ${props.match.params.permissionId}`}
      />

      {dataPermission != null && dataPermissionDetail(dataPermission)}

      <DataPermissionConsumers
        dataPermissions={consumedPermissions}
        columnDefinitions={consumerPermissionColumnDefinitions}
        dataPermissionsLoading={loadingResource}
      />
    </>
  );
};
