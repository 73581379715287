import * as React from 'react';
import { useState, useEffect } from 'react';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  Button,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { getResourceTypeFromArn, linkForResource, signedInWithWorkspace } from 'src/components/dataadvisory/constants';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { CopiableText } from 'src/commons/common';

export interface ImpactedResourceTableProps {
  advisory: Advisory;
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const ImpactedResourceTable = (props: ImpactedResourceTableProps) => {
  const [allItems, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableMessage, setTableMessage] = useState('No advisories');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceName',
      header: 'Resource name',
      cell: (item) => linkForResource(item, signedInWithWorkspace(props)),
      width: 250,
      sortingField: 'resourceName',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => getResourceTypeFromArn(item.resourceArn),
      width: 120,
      sortingField: 'type',
    },
    {
      id: 'arn',
      header: 'ARN',
      cell: (item) => <CopiableText value={item.resourceArn} />,
      width: 330,
      sortingField: 'arn',
    },
    {
      id: 'comment',
      header: 'Comment',
      cell: (item) => item.comment,
      width: 550,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      setItems(props.advisory.impactedResources);
      setLoading(false);
    } catch (err) {
      setTableMessage(`Unable to load resources: ${err.message}`);
      setLoading(false);
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No resources to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resources...'
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={'(' + items.length + ')'}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button iconName='refresh' variant='icon' />
              </SpaceBetween>
            }
          >
            Impacted resources
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
