import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getTaggedResources } from 'src/api/permissions';
import { Link } from 'react-router-dom';
import {
  createFgaPolicyViewLink,
  createWSDatasetDetailLink,
  createWSRedshiftCatalogDetailLink,
  createWSRedshiftDatabaseDetailLink,
  createWSSchemaDetailLink,
} from 'src/routes';
import {
  DATASET_RESOURCE_TYPE,
  REDSHIFT_TAG_TYPE,
  SCHEMA_RESOURCE_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';

export interface GlueDatabasesProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  selectedWorkspace: string;
  catalogMap: any;
}

export const RedshiftWSResources = (props: GlueDatabasesProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);

  const getWorkspaceResources = async (selectedWorkspace) => {
    let res = [];
    let fineGrainResources = selectedWorkspace?.resourcesFound.filter((resource) => resource.fgapId);
    let tagResources = selectedWorkspace?.resourcesFound.filter((resource) => resource.tagKey);
    for (let fgaResource of fineGrainResources) {
      res.push({
        type: DATASET_RESOURCE_TYPE,
        cluster: fgaResource.clusterId,
        databaseName: fgaResource.databaseName,
        schemaName: fgaResource.schemaName,
        tableName: fgaResource.tableName,
        catalogId: fgaResource.catalogId,
        catalogName: props.catalogMap.get(
          `${selectedWorkspace.catalogId}:${fgaResource.clusterId}:${selectedWorkspace.region}`,
        ),
        Id: `DS-redshift|A-${selectedWorkspace.catalogId}|CI-${fgaResource.clusterId}|DN-${fgaResource.databaseName}|SN-${fgaResource.schemaName}|TN-${fgaResource.tableName}|R-${selectedWorkspace.region}`,
        region: selectedWorkspace.region,
        fgapName: fgaResource.fgapName,
        fgapId: fgaResource.fgapId,
      });
    }
    for (let tag of tagResources) {
      let getTaggedResourcesResponse = await getTaggedResources({
        tagPair: tag.tagKey + ':' + tag.tagValue,
        catalogId: selectedWorkspace.catalogId,
        region: selectedWorkspace.region,
        type: REDSHIFT_TAG_TYPE,
      });
      for (let resource of getTaggedResourcesResponse.resources) {
        if (resource.resourceType == DATASET_RESOURCE_TYPE) {
          res.push({
            type: resource.resourceType,
            cluster: resource.clusterId,
            databaseName: resource.databaseName,
            schemaName: resource.schemaName,
            tableName: resource.resourceName,
            catalogId: selectedWorkspace.catalogId,
            catalogName: props.catalogMap.get(
              `${selectedWorkspace.catalogId}:${resource.clusterId}:${selectedWorkspace.region}`,
            ),
            Id: `DS-redshift|A-${selectedWorkspace.catalogId}|CI-${resource.clusterId}|DN-${resource.databaseName}|SN-${resource.schemaName}|TN-${resource.resourceName}|R-${selectedWorkspace.region}`,
            region: getTaggedResourcesResponse.region,
          });
        } else if (resource.resourceType == SCHEMA_RESOURCE_TYPE) {
          res.push({
            type: resource.resourceType,
            cluster: resource.clusterId,
            databaseName: resource.databaseName,
            schemaName: resource.schemaName,
            catalogId: selectedWorkspace.catalogId,
            catalogName: props.catalogMap.get(
              `${selectedWorkspace.catalogId}:${resource.clusterId}:${selectedWorkspace.region}`,
            ),
            tableName: '*',
            region: getTaggedResourcesResponse.region,
          });
        }
      }
    }
    setItems(res);
  };

  const handleRefresh = async () => {
    setLoadingResources(true);
    try {
      await getWorkspaceResources(props.selectedWorkspace);
    } catch (err) {
      setLoadingResources(false);
      console.log('Exception fetch tag resources', err);
    }
    setLoadingResources(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, [props.selectedWorkspace]);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceType',
      header: 'Resource type',
      cell: (item) => item.type,
      minWidth: 200,
    },
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) => (
        <Link to={createWSRedshiftCatalogDetailLink(item?.catalogId, item?.cluster, item?.region)}>
          {item.catalogName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'cluster',
      header: 'Cluster',
      cell: (item) => (
        <Link to={createWSRedshiftCatalogDetailLink(item?.catalogId, item?.cluster, item?.region)}>{item.cluster}</Link>
      ),
      minWidth: 200,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link to={createWSRedshiftDatabaseDetailLink(item?.catalogId, item?.cluster, item?.databaseName, item?.region)}>
          {item.databaseName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'schemaName',
      header: 'Schema name',
      cell: (item) => (
        <Link
          to={createWSSchemaDetailLink(
            item?.catalogId,
            item?.cluster,
            item?.databaseName,
            item?.schemaName,
            item?.region,
          )}
        >
          {item.schemaName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) =>
        item.datasetName != '*' ? (
          <Link to={createWSDatasetDetailLink(item?.Id)}>{item.tableName}</Link>
        ) : (
          item.tableName
        ),
      minWidth: 200,
    },
    {
      id: 'finrGrainPolicy',
      header: 'Fine grain policy',
      cell: (item) =>
        item.fgapId ? <Link to={createFgaPolicyViewLink(item?.fgapId)}>{item.fgapName}</Link> : undefined,
      minWidth: 200,
    },
  ];
  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resources...'
        loading={loadingResources}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Redshift resources
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        isItemDisabled={(item) => item.status == 'registered'}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No resources</p>
          </div>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResources}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
