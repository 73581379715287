import { Button, Form, Container, SpaceBetween, Flashbar, FlashbarProps } from '@amzn/awsui-components-react-v3';

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import {
  createResourceGroup,
  getResourceGroup,
  listBootstrapActions,
  updateResourceGroup,
} from '../../../api/resourcesmanager';
import * as validate from '../../../commons/validationUtils';
import { createBootstrapError, Guardrail_UNBOUND, renderInputsFromList, someInputInvalid } from '../components';
import { set, get, pop, clusterRegionDescription } from '../helpers';
import * as constants from '../constants';
import * as utils from './resourceGroupUtils';
import { Page } from '../../../routes/Paths';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';
import { getRegion } from 'src/api/config';
import { cluster_region_options } from '../constants';

export interface ResourceGroupFormProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  isUpdate: boolean;
  match: any;
}

export interface ResourceGroupFormState {
  loading: boolean;
  submitting: boolean;
  attempted: boolean;
  redirect: string;
  redirectParams: object;
  values: object;
  notifications: FlashbarProps.MessageDefinition[];
  select_options: any;
  guardrail: object;
  selected: object;
  loadingBootstrapActions: boolean;
  loadingResourceGroup: boolean;
}

const optional = true;

export class ResourceGroupForm extends React.Component<ResourceGroupFormProps, ResourceGroupFormState> {
  state = {
    notifications: [],
    loading: false,
    submitting: false,
    attempted: false,
    loadingBootstrapActions: true,
    loadingResourceGroup: true,
    redirect: undefined,
    redirectParams: undefined,
    select_options: {},
    selected: {},
    values: {},
    guardrail: null,
  };

  updateOptionsWithBootstrapActions = async (select_options) => {
    var bootstrapactions;

    try {
      bootstrapactions = await listBootstrapActions({
        groupId: this.props.activeGroup,
      });

      select_options.bootstrapActions = bootstrapactions.bootstrapActions.map((bsa, ix) =>
        Object({
          id: ix,
          label: bsa.name,
          value: bsa.id,
          description: bsa.s3FileLocation,
          labelTag: bsa.description,
        }),
      );
      return select_options;
    } catch (err) {
      this.setState({
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'LOADING BOOTSTRAP ACTIONS',
        }),
      });
      return {};
    }
  };

  fromBlank = () => {
    return get(this, ['props', 'location', 'state', 'values']) == undefined && !this.props.isUpdate;
  };

  /**
   * The logic for getting, merging, and cleaning the values to populate the Resource Group Form.
   * Currently supports
   *      * Creating a New Resource Group (values populated by defaults)
   *      * Cloning an existing Resource Group (values populated from this.props.location.state.values)
   *      * Updating an Existing Resource Group (values populated from async getRG)
   */
  getValues = async () => {
    // From Default
    var newValues = {};

    // From Clone
    if (get(this, ['props', 'location', 'state', 'values']) != undefined) {
      newValues = this.props.location.state.values;
    }

    //From Update
    var resourcegroup;
    if (this.props.isUpdate) {
      try {
        resourcegroup = await getResourceGroup({
          id: this.props.match.params.id,
        });
        newValues = { ...resourcegroup };
      } catch (err) {
        this.setState({
          notifications: createBootstrapError({
            ...err,
            errorWhile: 'LOADING RESOURCE GROUP',
          }),
          guardrail: null,
        });
      }
    }

    //Selectively merge values into single object
    var values = Object.fromEntries(
      Object.entries(constants.values_default).map(([key, defaultValue]) => [
        key,
        key in newValues ? newValues[key] : defaultValue,
      ]),
    );

    values['emrGroupConfig'] = Object.fromEntries(
      Object.entries(constants.values_default.emrGroupConfig).map(([key, defaultValue]) => [
        key,
        key in values['emrGroupConfig'] ? values['emrGroupConfig'][key] : defaultValue,
      ]),
    );

    values = utils.valuesJsonToForm(values);

    if (this.props.isUpdate) {
      values.status = resourcegroup.status;
    }
    return values;
  };

  /**
   * Fetches the options for the <Select>/<Multiselect> components
   * Loads the values to populate the form (New, Update, Clone)
   * Retrieves the selected values
   */
  componentDidMount = async () => {
    this.props.setContentType(FORM_CONTENT_TYPE);

    const select_options = await this.updateOptionsWithBootstrapActions(constants.select_options);

    const values = await this.getValues();

    const selected = utils.getSelectedFromValues(values, select_options);

    this.setState({
      values,
      select_options,
      selected,
      loadingBootstrapActions: false,
      loadingResourceGroup: false,
    });
  };

  updateRG = async (values) => {
    values.id = this.props.match.params.id;
    delete values.type;

    try {
      const output = await updateResourceGroup(values);
      this.setState({
        redirect: Page.RESOURCEGROUP_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'UPDATING RESOURCE GROUP',
        }),
        guardrail: null,
        submitting: false,
      });
    }
  };

  createRG = async (values) => {
    values.groupId = this.props.activeGroup;
    try {
      const output = await createResourceGroup(values);
      this.setState({
        redirect: Page.RESOURCEGROUP_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'CREATING RESOURCE GROUP',
        }),
        values: utils.valuesJsonToForm(values),
        guardrail: null,
        submitting: false,
      });
    }
  };

  /**
   * [UNGUARDED]: Handles the logic for submitting either a create or update Resource Group API call from this.state.values.
   */
  handleSubmit_UNGUARDED = async () => {
    var values = utils.valuesFormToJson(this.state.values);
    pop(values, ['emrGroupConfig', 'ec2SubnetId'], '');
    pop(values, ['emrGroupConfig', 'configurationsJsonString'], '');
    if (this.props.isUpdate) {
      this.updateRG(values);
    } else {
      this.createRG(values);
    }
    this.setState({ guardrail: null });
  };

  /**
   * Guardrailed wrapper for submitting either a create or update Resource Group API call from this.state.values.
   */
  handleSubmit = async () => {
    this.setState({ submitting: true, attempted: true, notifications: [] });
    if (
      this.someInputInvalid(this.basicInputs()) ||
      this.someInputInvalid(this.emrInputs(), ['emrGroupConfig']) ||
      this.someInputInvalid(this.emrManagedScalingInputs(), ['emrGroupConfig', 'emrManagedScalingConfig'])
    ) {
      this.setState({
        notifications: createBootstrapError({
          message: 'Please verify that all required fields are completed and correct. ',
          code: 'Invalid Input',
          errorWhile: 'SUBMITTING FORM',
        }),
        guardrail: null,
        submitting: false,
      });
      return;
    }

    this.setState({
      guardrail: {
        header: this.props.isUpdate ? `UPDATE ${this.props.match.params.id}?` : `CREATE NEW RESOURCE GROUP?`,
        action: this.handleSubmit_UNGUARDED,
      },
    });
  };

  renderInputs = renderInputsFromList.bind(this);
  someInputInvalid = someInputInvalid.bind(this);

  basicInputs = () => [
    {
      fieldKey: 'name',
      fieldLabel: 'Name',
      placeholder: 'Name of resource group',
      autoFocus: true,
    },
    {
      fieldKey: 'description',
      fieldLabel: 'Description',
      fieldType: 'textarea',
      optional,
    },
    this.props.isUpdate
      ? {
          fieldKey: 'status',
          fieldLabel: `Resource Status: ${this.state.values['status']}`,
          fieldType: 'toggle',
          value: this.state.values['status'] === 'ENABLED',
          optional,
          onAction: (e) => {
            this.setState(set(this.state, ['values', 'status'], e.detail.checked ? 'ENABLED' : 'DISABLED'));
          },
          disabled: !['ENABLED', 'DISABLED'].includes(this.state.values['status']),
        }
      : {
          fieldKey: 'type',
          fieldLabel: 'Resource Type',
          fieldType: 'select',
          fieldDescription: "Only 'EMR_GROUP' is currently supported.",
        },
    {
      fieldKey: 'notification',
      fieldLabel: 'Notification Settings',
      fieldType: 'json',
      validation: validate.isValidJSONString,
      errorText:
        'Notification must be a valid JSON string (no terminal commas, all keys and strings must be "double-quoted").',
      optional,
    },
  ];

  emrInputs1 = () => [
    {
      fieldKey: 'accountId',
      fieldLabel: 'AWS Account ID',
      fieldDescription: 'AWS Account ID which will own and manage the EMR clusters',
      placeholder: 'e.g. 123456789000',
      validation: validate.isValidAccountId,
      errorText: 'Must be a non-empty and valid account ID (12 digits)',
    },
    {
      fieldLabel: 'AWS Region',
      fieldKey: 'region',
      fieldDescription: cluster_region_options.has(getRegion())
        ? clusterRegionDescription(
            getRegion(),
            cluster_region_options.get(getRegion()).map((selectItem) => selectItem.value),
          )
        : 'Resource Manager in this region cannot manage cross region clusters',
      fieldType: 'select',
      placeholder: 'e.g. "us-east-1"',
      validation: validate.isValidRegion,
      errorText: 'Must be valid region!',
    },
    {
      fieldLabel: `EC2 Subnet IDs (${
        (get(this, ['state', 'values', 'emrGroupConfig', 'ec2SubnetIds']) || '').split(',').filter((x) => !!x).length ||
        '0'
      })`,
      fieldKey: 'ec2SubnetIds',
      fieldDescription: 'Comma-separated list of 1 or more EC2 subnet IDs',
      placeholder: 'e.g. subnet-123abc456de789, subnet-098xyz765...',
      validation: validate.isValidSubnetIdList,
      errorText: 'Must be comma-separated list of valid Subnet IDs (e.g. subnet-[5-17 chars]) ',
    },
    {
      fieldLabel: 'EMR Release Label',
      fieldKey: 'releaseLabel',
      fieldDescription: 'The version of EMR you want to run on your clusters.',
      placeholder: 'e.g. emr-01.23.45',
      validation: validate.isValidEmrReleaseLabel,
      errorText: 'Must be valid EMR Release label (e.g. emr-[1-2 digits].[1-2 digits].[1-2 digits][...])',
    },
  ];

  emrInputs2 = () => [
    {
      fieldLabel: 'Cluster Refresh Period (days)',
      fieldKey: 'clusterRefreshDays',
      fieldDescription: 'How often the EMR Clusters in the Resource Group will be refreshed.',
      placeholder: 'e.g. 30',
      fieldType: 'number',
      validation: validate.isPositiveInteger,
      errorText: 'Cluster Refresh days must be positive integer!',
      min: 0,
      step: 1,
      optional,
    },
    {
      fieldLabel: 'Refresh Days',
      fieldKey: 'clusterRefreshWeekDaysInUTC',
      fieldType: 'multiselect',
      fieldDescription: 'Which days of the week the clusters can be refreshed.',
      validation: validate.isValidIntegerList,
      errorText: 'Refresh days must be list of positive integers!',
      optional,
    },
    {
      fieldLabel: 'Refresh Hours',
      fieldKey: 'clusterRefreshDayHoursInUTC',
      fieldType: 'multiselect',
      fieldDescription: 'Which hours of the day the clusters can be refreshed.',
      validation: validate.isValidIntegerList,
      errorText: 'Refresh hours must be list of positive integers!',
      optional,
    },
    {
      fieldLabel: 'EMR Management Role ARN',
      fieldKey: 'emrManagementRoleArn',
      fieldDescription: 'The role ARN to manage the EMR clusters.',
      placeholder: 'e.g. arn:aws:iam::...',
      optional,
    },
    {
      fieldLabel: 'Job Flow Role',
      fieldKey: 'jobFlowRole',
      fieldDescription: 'Default is "EMR_EC2_DefaultRole"',
      placeholder: 'e.g. arn:aws:iam::...',
    },
    {
      fieldLabel: 'Service Role',
      fieldKey: 'serviceRole',
      fieldDescription: 'Default is "EMR_DefaultRole"',
      placeholder: 'e.g. arn:aws:iam::...',
    },
    {
      fieldLabel: 'Min # Clusters',
      fieldKey: 'minClusters',
      fieldDescription: 'The minimum number of clusters that Resources Manager will keep active.',
      placeholder: 'e.g. 0',
      fieldType: 'number',
      errorText: 'Min Clusters must be integer,  0 <= Min Clusters <= Max Clusters',
      min: 0,
      step: 1,
      optional,
    },
    {
      fieldLabel: 'Max # Clusters',
      fieldKey: 'maxClusters',
      fieldDescription: 'The maximum number of clusters that Resources Manager will create.',
      placeholder: 'e.g. 10',
      fieldType: 'number',
      validation: (x) =>
        validate.isPositiveInteger(x) && parseInt(x) >= this.state.values['emrGroupConfig']['minClusters'],
      errorText: 'Min Clusters must be positive integer,  0 <= Min Clusters <= Max Clusters',
      min: 1,
      step: 1,
      disabled: !this.state.values['emrGroupConfig']['isAutoScalingEnabled'],
      optional: !this.state.values['emrGroupConfig']['isAutoScalingEnabled'],
    },
    {
      fieldLabel: 'AutoScaling',
      fieldKey: 'isAutoScalingEnabled',
      fieldDescription:
        'Whether Resources Manager can automatically spin resources up/down. If off, will only create minClusters.',
      fieldType: 'toggle',
      optional,
    },
    {
      fieldLabel: 'Master Instance Type',
      fieldKey: 'masterInstanceType',
      fieldType: 'select',
      filteringType: 'auto',
      fieldDescription: [
        'The EC2 instance type of the master node within each EMR Cluster. For available instance types, ',
        <a
          href='https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-supported-instance-types.html'
          target='_blank'
        >
          click here
        </a>,
      ],
      placeholder: 'e.g. "m4.large"',
    },
    {
      fieldLabel: 'Master Instance Count',
      fieldKey: 'masterInstanceCount',
      fieldDescription: 'The number of master nodes in each EMR cluster.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      validation: validate.isPositiveInteger,
      errorText: 'Master Instance Count must be positive integer!',
      min: 1,
      step: 1,
    },
    {
      fieldLabel: 'Master Market',
      fieldKey: 'masterMarket',
      placeholder: 'e.g. "ON DEMAND"',
    },
    {
      fieldLabel: 'Core Instance Type',
      fieldKey: 'coreInstanceType',
      fieldDescription: [
        'The EC2 instance type of the core node within each EMR Cluster. For available instance types, see: ',
        <a
          href='https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-supported-instance-types.html'
          target='_blank'
        >
          here
        </a>,
      ],
      fieldType: 'select',
      filteringType: 'auto',
      placeholder: 'e.g. "m4.large"',
    },
    {
      fieldLabel: 'Core Instance Count',
      fieldKey: 'coreInstanceCount',
      fieldDescription: 'The number of master nodes in each EMR cluster.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      validation: validate.isPositiveInteger,
      errorText: 'Core Instance Count must be positive integer!',
      min: 1,
      step: 1,
    },
    {
      fieldLabel: 'Core Market',
      fieldKey: 'coreMarket',
      placeholder: 'e.g. "ON_DEMAND"',
    },
    {
      fieldLabel: 'Chronicle Bootstrap',
      fieldKey: 'installChronicle',
      fieldType: 'toggle',
      optional,
    },
    {
      fieldLabel: 'SSM Patch',
      fieldKey: 'installSSMPatch',
      fieldType: 'toggle',
      optional,
    },
  ];

  emrInputs3 = () => [
    {
      fieldLabel: 'Logs Destination (S3 URI)',
      fieldKey: 's3LogUri',
      fieldDescription: 'The S3 file to which Resources Manager will write all logs for this cluster',
      placeholder: 'e.g. s3://dir/file.ext',
      validation: validate.isValidS3Path,
      errorText: 'Must be vaild S3 Path: s3//...',
    },
    {
      fieldLabel: `EMR Applications (${
        (get(this, ['state', 'values', 'emrGroupConfig', 'applications']) || '').split(',').filter((x) => !!x).length ||
        '0'
      })`,
      optional,
      fieldKey: 'applications',
      fieldDescription: 'Comma-separated list of applications',
      placeholder: 'e.g. "Spark,Hive,..."',
    },
    {
      fieldLabel: 'EMR Bootstrap Actions',
      fieldKey: 'bootstrapActions',
      fieldType: 'multiselect',
      optional,
      disable_autosort: true,
      fieldDescription: 'Ordered list of bootstrap action IDs to be executed on EMR Clusters',
      statusType: this.state.loadingBootstrapActions ? 'loading' : 'finished',
      loadingText: 'Loading bootstrap actions',
      placeholder: 'Select Bootstrap Actions from the dropdown below.',
    },
    {
      fieldDescription: 'ID of a custom Amazon Linux AMI',
      fieldLabel: 'Custom AMI ID',
      fieldKey: 'customAmiId',
      optional,
    },
    {
      fieldDescription: 'Name of the EC2 key that can be used to connect to the clusters.',
      fieldLabel: 'EC2 Key Name',
      fieldKey: 'ec2KeyName',
      optional,
    },
    {
      fieldDescription: 'Maximum number of concurrent jobs, by default EMR executes a single job at once.',
      fieldLabel: 'Step Concurrency Level',
      fieldKey: 'stepConcurrencyLevel',
      optional,
    },
    {
      fieldLabel: 'Security Configuration',
      fieldKey: 'securityConfiguration',
      fieldDescription: 'You can input your custom security configuration, or leave the default',
      placeholder: 'e.g. "Default_At_Rest_Encryption_S3"',
    },
    {
      fieldKey: 'emrManagedMasterSecurityGroup',
      fieldLabel: 'Master security group',
      fieldDescription: '',
      placeholder: 'sg-1234aa123456b1d12',
      optional,
    },
    {
      fieldKey: 'emrManagedSlaveSecurityGroup',
      fieldLabel: 'Slave security group',
      fieldDescription: '',
      placeholder: 'sg-1234aa123456b1d12',
      optional,
    },
    {
      fieldLabel: 'EMR Cluster Configuration Map',
      optional,
      fieldDescription:
        'A validly formatted JSON object containing the configuration details of your EMR cluster with map format',
      fieldKey: 'configurations',
      placeholder:
        'e.g. {\n\t key1: { \n\t\t key1a: value1a\n\t }, \n\t key2: { \n\t\t key2a: value2a, \n\t\t key2b: value2b \n\t } \n }',
      fieldType: 'json',
      validation: validate.isValidJSONString,
      errorText:
        'EMR Cluster Configuration must be a valid JSON string (no terminal commas, all keys and strings must be "double-quoted").',
      rows: 10,
    },
    {
      fieldLabel: 'EMR Cluster Configurations String',
      optional,
      fieldDescription:
        'A validly formatted JSON String containing the configuration details of your EMR cluster in list format. Please notice, the final configuration will be the combination of both configuration list and configuration map',
      fieldKey: 'configurationsJsonString',
      placeholder:
        'e.g. [\n\t{\n\t\t key1: value1,\n\t\t key2: [val2a, val2b, val2c]\n\t},  \n\t{\n\t\tkey3: { \n\t\t\t key3a: value3a, \n\t\t\t key3b: value3b \n\t\t } \n\t}\n]',
      fieldType: 'json',
      rows: 10,
    },
  ];

  emrTaskInputs = () => [
    {
      fieldLabel: 'Task Instance Count',
      fieldKey: 'taskInstanceCount',
      fieldDescription: 'The number of task instances.',
      placeholder: 'e.g. 0',
      fieldType: 'number',
      min: 0,
      step: 1,
      optional:
        !this.state.values['emrGroupConfig']['taskInstanceType'] && !this.state.values['emrGroupConfig']['taskMarket'],
    },
    {
      fieldLabel: 'Task Instance Type',
      fieldKey: 'taskInstanceType',
      fieldType: 'select',
      filteringType: 'auto',
      fieldDescription: [
        'The EC2 instance type of the task node within each EMR Cluster. For available instance types, ',
        <a
          href='https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-supported-instance-types.html'
          target='_blank'
        >
          click here
        </a>,
      ],
      placeholder: 'e.g. "m4.large"',
      optional:
        !this.state.values['emrGroupConfig']['taskInstanceCount'] && !this.state.values['emrGroupConfig']['taskMarket'],
    },
    {
      fieldLabel: 'Task Market',
      fieldKey: 'taskMarket',
      placeholder: 'e.g. "ON DEMAND"',
      optional:
        !this.state.values['emrGroupConfig']['taskInstanceCount'] &&
        !this.state.values['emrGroupConfig']['taskInstanceType'],
    },
    {
      fieldLabel: 'ManagedScaling',
      fieldKey: 'isEMRManagedScalingEnabled',
      fieldDescription:
        'Enable this option to let Amazon EMR automatically increase or decrease the number of instances in your cluster based on workload',
      fieldType: 'toggle',
      optional,
    },
  ];

  emrManagedScalingInputs = () => [
    {
      fieldLabel: 'Unit Type',
      fieldKey: 'unitType',
      filteringType: 'auto',
      fieldDescription: 'Unit for specifying EC2 capacity in a cluster.',
      fieldType: 'select',
      disabled: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
      optional: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
    },
    {
      fieldLabel: 'Minimum Capacity Units',
      fieldKey: 'minimumCapacityUnits',
      fieldDescription: 'The lower boundary of allowed EC2 capacity in a cluster.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      validation: validate.isNonNegativeInteger,
      errorText: 'Minimum Capacity Units must be a non-negative integer!',
      min: 0,
      step: 1,
      disabled: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
      optional: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
    },
    {
      fieldLabel: 'Maximum Capacity Units',
      fieldKey: 'maximumCapacityUnits',
      fieldDescription: 'The upper boundary of allowed EC2 capacity in a cluster.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      validation: (x) =>
        validate.isPositiveInteger(x) &&
        parseInt(x) >= this.state.values['emrGroupConfig']['emrManagedScalingConfig']['minimumCapacityUnits'],
      errorText: 'Max capacity units must be a positive integer and more than Min capacity units',
      max: 20,
      step: 1,
      disabled: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
      optional: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
    },
    {
      fieldLabel: 'Maximum Core Capacity Units',
      fieldKey: 'maximumCoreCapacityUnits',
      fieldDescription:
        'The upper boundary of allowed EC2 capacity for code nodes in a cluster. Defaults to the Maximum Capacity Units.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      validation: (x) =>
        validate.isPositiveInteger(x) &&
        parseInt(x) >= this.state.values['emrGroupConfig']['emrManagedScalingConfig']['minimumCapacityUnits'],
      errorText: 'Max core capacity units must be a positive integer and more than Min capacity units',
      max: 10,
      step: 1,
      disabled: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
      optional,
    },
    {
      fieldLabel: 'Maximum On Demand Capacity Units',
      fieldKey: 'maximumOnDemandCapacityUnits',
      fieldDescription:
        'The upper boundary of allowed EC2 capacity for On-Demand market type in a cluster. Defaults to the Maximum Capacity Units.',
      placeholder: 'e.g. 1',
      fieldType: 'number',
      max: 20,
      step: 1,
      validation: (x) =>
        validate.isPositiveInteger(x) &&
        parseInt(x) >= this.state.values['emrGroupConfig']['emrManagedScalingConfig']['minimumCapacityUnits'],
      errorText: 'Max on demand capacity units must be a positive integer and more than Min capacity units',
      disabled: !this.state.values['emrGroupConfig']['isEMRManagedScalingEnabled'],
      optional,
    },
  ];

  emrInputs = () => [...this.emrInputs1(), ...this.emrInputs2(), ...this.emrInputs3(), ...this.emrTaskInputs()];

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    return (
      <div>
        <Flashbar items={this.state.notifications} />
        {Guardrail_UNBOUND.bind(this)()}

        <Form
          header={
            <span>
              <h1 className='awsui-util-d-ib'>
                {this.props.isUpdate ? 'Update Existing ' : 'Create New '} Resource Group
              </h1>
            </span>
          }
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({
                    redirect: this.props.isUpdate
                      ? Page.RESOURCEGROUP_DETAILS.replace(':id', this.props.match.params.id)
                      : Page.RESOURCEGROUPS,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                variant='link'
                onClick={() => {
                  var paramsObject = utils.valuesFormToJson(this.state.values);
                  delete paramsObject['status'];
                  delete paramsObject['id'];

                  const params = {
                    values: {
                      type: 'EMR_GROUP',
                      templateJsonString: JSON.stringify(paramsObject, null, 2),
                    },
                  };
                  this.setState({
                    redirect: Page.CREATE_RG_TEMPLATE,
                    redirectParams: params,
                  });
                }}
              >
                Save as Template
              </Button>
              <Button
                variant='primary'
                onClick={this.handleSubmit}
                loading={this.state.loadingResourceGroup || this.state.submitting}
              >
                {this.props.isUpdate ? 'Update' : 'Create'}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size='l'>
            <Container
              className='custom-screenshot-hide'
              header={<h2>{this.props.isUpdate ? this.props.match.params.id : 'Resource Group Settings'}</h2>}
            >
              {this.renderInputs(this.basicInputs())}
            </Container>
            {this.state.values['type'] == 'EMR_GROUP' && (
              <Container className='custom-screenshot-hide' header={<h2>EMR Configuration</h2>}>
                <SpaceBetween size='m'>
                  {this.renderInputs(this.emrInputs1(), ['emrGroupConfig'])}

                  {this.renderInputs(this.emrInputs2(), ['emrGroupConfig'], {
                    columns: 3,
                    border: 'all',
                  })}

                  {this.renderInputs(this.emrTaskInputs(), ['emrGroupConfig'], {
                    columns: 3,
                    border: 'all',
                  })}

                  {this.renderInputs(this.emrManagedScalingInputs(), ['emrGroupConfig', 'emrManagedScalingConfig'], {
                    columns: 3,
                    border: 'all',
                  })}
                  <br />
                  {this.renderInputs(this.emrInputs3(), ['emrGroupConfig'])}
                </SpaceBetween>{' '}
              </Container>
            )}
          </SpaceBetween>
        </Form>
      </div>
    );
  }
}
