import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';

export interface yearlyViewProps {
  setContentType: any;
  match: any;
}

export const YearlyView = (props: yearlyViewProps) => {
  return <MetricTableView props={props} contentType='YEARLY' />;
};
