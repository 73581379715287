import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { listDataPermissions } from 'src/api/permissions';
import { Link } from 'react-router-dom';
import { EmptyState } from 'src/commons/EmptyState';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  REDSHIFT_TAG_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import {
  createLFPermissionDetailLink,
  createPublishedPermissionDetailLink,
  createRedshiftPermissionDetailLink,
} from 'src/routes';

export interface AllPermissionsProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  setActiveTabId: any;
  activeWorkspace: any;
  catalogMap: any;
}

export const AllPermissions = (props: AllPermissionsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);

  const fetchDataPermissions = async () => {
    setLoadingResource(true);
    let itemList = [];
    let allDataPermissions = [];
    // get both LF and redshift permissions
    let listDataPermissionsRequest = {
      ownerId: props.activeWorkspace.workspaceId,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      nextToken: null,
    };
    let listDataPermissionsResponse = await listDataPermissions(listDataPermissionsRequest);
    allDataPermissions.push(...listDataPermissionsResponse.dataPermissionList);
    //Loop and get remaining tables
    while (listDataPermissionsResponse.nextToken != null) {
      listDataPermissionsRequest.nextToken = listDataPermissionsResponse.nextToken;
      listDataPermissionsResponse = await listDataPermissions(listDataPermissionsRequest);
      allDataPermissions.push(...listDataPermissionsResponse.dataPermissionList);
    }
    // convert to items based on type
    for (let dataPermission of allDataPermissions) {
      if (dataPermission.resource?.lFTagPolicy !== undefined) {
        let policy = dataPermission.resource.lFTagPolicy;
        for (let tagValue of policy.expression[0].tagValues) {
          let catalogName = props.catalogMap.get(policy.catalogId + ':' + dataPermission.region);
          let item = {
            owner: dataPermission.ownerId,
            option: dataPermission.option,
            dataPermissionId: dataPermission.dataPermissionId,
            dataLakePrincipal: dataPermission.dataLakePrincipal,
            resourceType: 'Tag permission',
            type: dataPermission.type,
            status: dataPermission.status,
            statusReason: dataPermission.statusReason,
            resource: {
              catalogName: catalogName,
              tagKey: policy.expression[0].tagKey,
              tagValue: tagValue,
            },
            accountId: policy.catalogId,
          };
          itemList.push(item);
        }
      } else if (dataPermission.resource?.redshiftTagPolicy !== undefined) {
        let redshiftTagPolicy = dataPermission.resource.redshiftTagPolicy;

        let catalogName = props.catalogMap.get(redshiftTagPolicy.catalogId + ':' + dataPermission.region);
        let item = {
          owner: dataPermission.ownerId,
          option: dataPermission.option,
          dataPermissionId: dataPermission.dataPermissionId,
          dataLakePrincipal: dataPermission.dataLakePrincipal,
          resourceType: 'Tag permission',
          type: dataPermission.type,
          status: dataPermission.status,
          statusReason: dataPermission.statusReason,
          resource: {
            catalogName: catalogName,
            tagKey: redshiftTagPolicy.tagKey,
            tagValue: redshiftTagPolicy.tagValue,
          },
          accountId: redshiftTagPolicy.catalogId,
        };
        itemList.push(item);
      } else if (dataPermission.resource?.table !== undefined) {
        let table = dataPermission.resource.table;
        let catalogName = props.catalogMap.get(table.catalogId + ':' + dataPermission.region);
        let item = {
          owner: dataPermission.ownerId,
          option: dataPermission.option,
          dataPermissionId: dataPermission.dataPermissionId,
          dataLakePrincipal: dataPermission.dataLakePrincipal,
          type: dataPermission.type,
          status: dataPermission.status,
          statusReason: dataPermission.statusReason,
          resourceType: 'Resource permission',
          resource: {
            catalogName: catalogName,
            database: table.databaseName,
            dataset: table.name,
          },
          accountId: table.catalogId,
        };
        itemList.push(item);
      } else if (dataPermission.resource?.dataCellsFilter !== undefined) {
        let dataCellFilter = dataPermission.resource.dataCellsFilter;
        let catalogName = props.catalogMap.get(dataCellFilter.tableCatalogId + ':' + dataPermission.region);
        let item = {
          owner: dataPermission.ownerId,
          option: dataPermission.option,
          dataPermissionId: dataPermission.dataPermissionId,
          dataLakePrincipal: dataPermission.dataLakePrincipal,
          type: dataPermission.type,
          status: dataPermission.status,
          statusReason: dataPermission.statusReason,
          fgapId: dataPermission.fgapId,
          resourceType: 'Fine grain permission',
          resource: {
            catalogName: catalogName,
            clusterName: dataCellFilter.clusterId,
            database: dataCellFilter.databaseName,
            schemaName: dataCellFilter.schemaName,
            dataset: dataCellFilter.tableName,
            filterName: dataCellFilter.name,
          },
          accountId: dataCellFilter.tableCatalogId,
        };
        itemList.push(item);
      }
    }
    setItems(itemList);
  };

  const handleRefresh = async () => {
    setLoadingResource(true);
    try {
      await fetchDataPermissions();
    } catch (err) {
      setLoadingResource(false);
    }
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const flatten = (items) => {
    return items.map((item) => ({
      dataPermissionId: item?.dataPermissionId,
      resourceType: item?.resourceType,
      dataLakePrincipal: item?.dataLakePrincipal,
      option: item?.option,
      resource:
        item?.resourceType == 'Tag permission'
          ? `${item.resource.tagKey}.${item.resource.tagValue}`
          : item?.type == REDSHIFT_TAG_TYPE
          ? `${item.resource?.clusterName}/${item.resource?.database}/${item.resource?.schemaName}/${
              item.resource?.dataset == undefined ? '*' : item.resource?.dataset
            }`
          : `${item.resource?.catalogName}/${item.resource?.database}/${
              item.resource?.dataset == undefined ? '*' : item.resource?.dataset
            }${item.resource?.filterName == undefined ? '' : '/' + item.resource?.filterName}`,
      accountId: item?.accountId,
      type: item?.type,
      status: item?.status,
      statusReason: item?.statusReason,
      fgapId: item?.fgapId,
      fgapName: item?.resource?.filterName,
    }));
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'dataPermissionId',
      header: 'Data permission ID',
      cell: (item) =>
        item?.option == DATA_PERMISSION_CONSUMER_OPTION ? (
          item?.type == REDSHIFT_TAG_TYPE ? (
            <Link to={createRedshiftPermissionDetailLink(item?.dataPermissionId)}>{item?.dataPermissionId}</Link>
          ) : (
            <Link to={createLFPermissionDetailLink(item?.dataPermissionId)}>{item?.dataPermissionId}</Link>
          )
        ) : (
          <Link to={createPublishedPermissionDetailLink(item?.dataPermissionId)}>{item?.dataPermissionId}</Link>
        ),

      minWidth: 200,
    },
    {
      id: 'dataLakePrincipal',
      header: 'Datalake Principal',
      cell: (item) => item?.dataLakePrincipal,
      minWidth: 200,
    },
    {
      id: 'resourceType',
      header: 'Permission type',
      cell: (item) => item?.resourceType,
      minWidth: 200,
    },
    {
      id: 'resource',
      header: 'Resource',
      cell: (item) => item?.resource,
      minWidth: 400,
    },
    {
      id: 'accountId',
      header: 'Data owner account ID',
      cell: (item) => item?.accountId,
      minWidth: 200,
    },
    {
      id: 'option',
      header: 'Option',
      cell: (item) => item?.option,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item?.status,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Data source',
      cell: (item) => item?.type,
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      filtering: {
        empty: <EmptyState title='No permissions' subtitle='No consumed permissions were found in the workspace.' />,
        noMatch: <EmptyState title='No matches' subtitle='We can’t find a match.' />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Data permission ID',
            key: 'dataPermissionId',
            groupValuesLabel: 'Data permission IDs',
          },
          {
            propertyLabel: 'Permission type',
            key: 'resourceType',
            groupValuesLabel: 'Permission types',
          },
          {
            propertyLabel: 'Resource',
            key: 'resource',
            groupValuesLabel: 'Resources',
          },
          {
            propertyLabel: 'Account ID',
            key: 'accountId',
            groupValuesLabel: 'Account IDs',
          },
        ],
      },
    },
  );

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading permissions...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
              ]}
              header={
                <>
                  Data Access Permissions
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={<EmptyState title='No matches' subtitle='No permissions consumed' />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
