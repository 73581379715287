import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { Link } from 'react-router-dom';
import { getSchemasForDatabase } from 'src/api/catalog';
import { createWSSchemaDetailLink } from 'src/routes';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface WSDatasetListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogId: any;
  databaseName: any;
  schemas: [];
  match: any;
  clusterName: string;
  region: string;
}

export const WSSchemaList = (props: WSDatasetListProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);

  const handleRefresh = async () => {
    setLoadingDatasets(true);

    let getSchemasForDatabaseRequest = {
      DatabaseName: props.databaseName,
      CatalogId: props.catalogId,
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      ClusterIdentifier: props.clusterName,
      Region: props.region,
      NextToken: null,
    };

    let schemaInfoList = [];
    let getSchemasForDatabaseResponse = await getSchemasForDatabase(getSchemasForDatabaseRequest);
    schemaInfoList = getSchemasForDatabaseResponse.SchemaInfoList;
    while (getSchemasForDatabaseResponse.NextToken != null) {
      getSchemasForDatabaseRequest.NextToken = getSchemasForDatabaseResponse.NextToken;
      getSchemasForDatabaseResponse = await getSchemasForDatabase(getSchemasForDatabaseRequest);
      schemaInfoList.push(...getSchemasForDatabaseResponse.SchemaInfoList);
    }

    setItems(schemaInfoList);
    setLoadingDatasets(false);
  };

  useEffect(() => {
    handleRefresh();
  }, [props.catalogId]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'schemaName',
      header: 'Schema name',
      cell: (item) => (
        <Link
          to={createWSSchemaDetailLink(
            item?.CatalogId,
            item?.ClusterIdentifier,
            item?.DatabaseName,
            item?.Schema,
            item?.Region,
          )}
        >
          {item.Schema}
        </Link>
      ),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Dataset name',
          key: 'DataSetName',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });
  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No registered datasets</p>
          </div>
        }
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Schemas
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
