import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { createGroupDetailLink, createWorkspaceDetailLink } from 'src/routes';
import { Link } from 'react-router-dom';
import React from 'react';

export const tagsColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
  // TODO: add checkbox
  // TODO: add singular actions
  // TODO: add bulk actions (i.e. edit, delete)
  {
    id: 'Workspace',
    header: 'Workspace',
    cell: (e) => (
      <Link
        to={isValidWorkspace(e.Workspace) ? createWorkspaceDetailLink(e.Workspace) : createGroupDetailLink(e.Workspace)}
      >
        {e.Workspace}
      </Link>
    ),
    minWidth: '200px',
    sortingField: 'Workspace',
  },
  {
    id: 'TagId',
    header: 'Tag',
    cell: (e) => e.Tag,
    minWidth: '200px',
    sortingField: 'TagId',
  },
  {
    id: 'Type',
    header: 'Tag type',
    cell: (e) => e.TagType,
    minWidth: '200px',
    sortingField: 'Type',
  },
  {
    id: 'Permission',
    header: 'Permissions',
    cell: (e) => e.Permission,
    minWidth: '200px',
    sortingField: 'Permission',
  },
  {
    id: 'Status',
    header: 'Status',
    cell: (e) => e.Status,
    minWidth: '200px',
    sortingField: 'Status',
  },
  {
    id: 'Reason',
    header: 'Reason',
    cell: (e) => e.Reason,
    minWidth: '200px',
    sortingField: 'Reason',
  },
];
export const resourceColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'workspace',
    header: 'Owner',
    cell: (item) => (
      <Link
        to={
          isValidWorkspace(item.workspace)
            ? createWorkspaceDetailLink(item.workspace)
            : createGroupDetailLink(item.workspace)
        }
      >
        {item.workspace}
      </Link>
    ),
    minWidth: '100px',
    sortingField: 'workspace',
  },
  {
    id: 'dataLakePrincipal',
    header: 'Datalake Principal',
    cell: (item) => item.dataLakePrincipal,
    minWidth: '200px',
    sortingField: 'dataLakePrincipal',
  },
  {
    id: 'catalogId',
    header: 'Catalog',
    cell: (item) => item.catalogId,
    minWidth: '200px',
    sortingField: 'catalogId',
  },
  {
    id: 'clusterId',
    header: 'Cluster',
    cell: (item) => item.clusterId,
    minWidth: '200px',
    sortingField: 'clusterId',
  },
  {
    id: 'databaseName',
    header: 'Database name',
    cell: (item) => item.databaseName,
    minWidth: '200px',
    sortingField: 'databaseName',
  },
  {
    id: 'schemaName',
    header: 'Schema name',
    cell: (item) => item.schemaName,
    minWidth: '200px',
    sortingField: 'schemaName',
  },
  {
    id: 'tableName',
    header: 'Table name',
    cell: (item) => (item.tableName != undefined ? item.tableName : 'All Tables'),
    minWidth: '200px',
    sortingField: 'tableName',
  },
  {
    id: 'fgapName',
    header: 'Fine grain policy',
    cell: (item) => (item.fgapName != undefined ? item.fgapName : ''),
    minWidth: '200px',
    sortingField: 'fgapName',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => item.status,
    minWidth: '200px',
    sortingField: 'status',
  },
  {
    id: 'statusReason',
    header: 'Reason',
    cell: (item) => item.statusReason,
    minWidth: '200px',
    sortingField: 'statusReason',
  },
];
export const DEFAULT_CANCEL_REASON = 'User cancel from workspace access page';
