import * as React from 'react';
import { Component } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  FlashbarProps,
  Header,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { getSubscription, deleteSubscription } from 'src/api/catalog';
import { labeledTextFromList, RMPageHeader } from '../../resourcesmanager/components';
import { Redirect } from 'react-router-dom';
import { dateString } from '../../resourcesmanager/helpers';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';

export interface eventSubscriptionDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

export interface eventSubscriptionDetailState {
  loading: boolean;
  eventSubscription: object;
  error: object;
  redirect: string;
  contentType: string;
  redirectParams: object;
  guardrail: any;
  unsubscribeModalVisible: boolean;
  isAction: boolean;
  notifications: object;
}

export default class EventSubscriptionDetails extends Component<
  eventSubscriptionDetailProps,
  eventSubscriptionDetailState
> {
  state = {
    loading: true,
    error: undefined,
    eventSubscription: null,
    redirect: undefined,
    contentType: undefined,
    redirectParams: {},
    guardrail: null,
    unsubscribeModalVisible: false,
    isAction: false,
    notifications: [],
  };

  componentDidMount = async () => {
    this.props.setContentType(CARDS_CONTENT_TYPE);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup || prevProps.activeWorkspace !== this.props.activeWorkspace) {
      await this.handleRefresh();
    }
  };

  /**
   * Asynchronously fetches the eventsubscriptions from the HC API and updates the state.
   */
  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const response = await getSubscription({
        Id: this.props.match.params.id,
      });
      this.setState({
        eventSubscription: response.Subscription,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: { ...err, while: 'LOADING EVENT SUBSCRIPTION' },
        eventSubscription: null,
        loading: false,
      });
    }
  };

  openDeleteModal = () => {
    this.setState({
      unsubscribeModalVisible: true,
    });
  };

  getInfoFromSubscriptions = (subscription, infoName) => {
    if (!subscription) return null;
    switch (infoName) {
      case 'ownerIdLink':
        if (subscription.OwnerId.startsWith('wks-')) return `/search/workspaces/${subscription.OwnerId}`;
        else return `/groups/${subscription.OwnerId}`;
    }
  };

  handleDeleteSubscription = async () => {
    try {
      this.setState({
        isAction: true,
      });
      await deleteSubscription({ Id: this.state.eventSubscription.Id });
    } catch (err) {
      this.setState({
        isAction: false,
        unsubscribeModalVisible: false,
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to delete subscription.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      console.error(err);
    }
    this.setState({
      isAction: false,
      unsubscribeModalVisible: false,
      notifications: [
        {
          type: 'success' as FlashbarProps.Type,
          content: `Successfully deleted subscription.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
    await this.handleRefresh();
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />

        <Modal
          visible={this.state.unsubscribeModalVisible}
          header={[`Unsubscribe?`]}
          onDismiss={() => {
            this.setState({
              unsubscribeModalVisible: false,
              isAction: false,
            });
          }}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    this.setState({
                      unsubscribeModalVisible: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button variant='primary' loading={this.state.isAction} onClick={this.handleDeleteSubscription}>
                  Confirm
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <div>
            Are you sure you want to unsubscribe from events for the resource:{' '}
            <strong>{this.state.eventSubscription?.ResourceId}</strong> for{' '}
            <strong>{this.state.eventSubscription?.SubscriberId}</strong> account?
            <br />
            <br />
            <strong>
              This account will no longer receive events for the resource, which can cause issues with any dependencies
              on these events.
            </strong>
          </div>
        </Modal>

        <SpaceBetween size='l'>
          <RMPageHeader
            buttons={[
              {
                text: 'Unsubscribe',
                onItemClick: this.openDeleteModal,
                disabled:
                  this.state.eventSubscription?.Status === 'InActive' ||
                  this.props.activeWorkspace?.workspaceId !== this.state.eventSubscription?.OwnerId,
              },
            ]}
            subheader={this.state.eventSubscription?.name}
          />

          <Container header={<Header variant='h2'> Event Subscription details </Header>}>
            <ColumnLayout columns={2} borders='horizontal'>
              {labeledTextFromList(
                [
                  {
                    label: 'Event Subscription ID',
                    value: this.state.eventSubscription?.Id,
                    copiable: true,
                  },
                  {
                    label: 'Resource ID',
                    value: this.state.eventSubscription?.ResourceId,
                    copiable: true,
                  },
                  {
                    label: 'Subscriber ID',
                    value: this.state.eventSubscription?.SubscriberId,
                    copiable: true,
                  },
                  {
                    label: 'Owner (Group ID)',
                    value: (
                      <Link to={this.getInfoFromSubscriptions(this.state.eventSubscription, 'ownerIdLink')}>
                        {this.state.eventSubscription?.OwnerId}
                      </Link>
                    ),
                    copiable: true,
                  },
                  {
                    label: 'Type',
                    value: this.state.eventSubscription?.Type,
                  },
                  {
                    label: 'Status',
                    value: <StatusIcon status={this.state.eventSubscription?.Status} />,
                  },
                  {
                    label: 'Created by',
                    value: this.state.eventSubscription?.CreatedBy,
                    copiable: true,
                  },
                  {
                    label: 'Created on',
                    value: dateString(this.state.eventSubscription?.CreatedOn),
                  },
                  {
                    label: 'Updated by',
                    value: this.state.eventSubscription?.UpdatedBy,
                    copiable: true,
                  },
                  {
                    label: 'Updated on',
                    value: dateString(this.state.eventSubscription?.UpdatedOn),
                  },
                  {
                    label: 'KMS key ARN',
                    value: this.state.eventSubscription?.EventMetadata?.EventKMSKeyARN,
                    copiable: true,
                  },
                  {
                    label: 'SNS topic ARN',
                    value: this.state.eventSubscription?.EventMetadata?.EventSNSTopicARN,
                    copiable: true,
                  },
                ],
                this.state.loading,
              )}
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      </>
    );
  }
}
