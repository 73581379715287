import * as React from 'react';
import {
  Button,
  Form,
  FormField,
  Container,
  Input,
  Textarea,
  SpaceBetween,
  Header,
  FlashbarProps,
} from '@amzn/awsui-components-react-v3';

import * as validate from '../../../commons/validationUtils';
import { Redirect } from 'react-router-dom';
import { createTemplate, updateTemplate, getTemplate } from '../../../api/resourcesmanager';
import { Page } from '../../../routes/Paths';
import { Flashbar } from '@amzn/awsui-components-react';
import { createBootstrapError } from '../components';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';

export interface TemplateFormProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  isUpdate: boolean;
  match: any;
}

export interface TemplateFormState {
  loading: boolean;
  redirect: string;
  values: object;
  notifications: FlashbarProps.MessageDefinition[];
  validation: object;
}

export class TemplateForm extends React.Component<TemplateFormProps, TemplateFormState> {
  state = {
    notifications: [],
    loading: false,
    redirect: undefined,
    values: {
      name: undefined,
      description: undefined,
      templateJsonString: undefined,
      type: undefined,
    },
    validation: {
      name: false,
      type: false,
      templateJsonString: false,
    },
  };

  componentDidMount = async () => {
    this.props.setContentType(FORM_CONTENT_TYPE);

    var values = { ...this.state.values };

    Object.keys(this.state.values).forEach(
      (key) =>
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.values &&
        key in this.props.location.state.values &&
        (values[key] = this.props.location.state.values[key]),
    );

    if (this.props.isUpdate) {
      this.setState({ loading: true });
      try {
        const ct = await getTemplate({
          id: this.props.match.params.id,
        });
        Object.keys(this.state.values).forEach((key) => key in ct && (values[key] = ct[key]));
      } catch (err) {
        this.setState({
          notifications: createBootstrapError({
            ...err,
            errorWhile: 'LOADING TEMPLATE',
          }),
        });
        return;
      }
    }

    this.setState({ loading: false, values });
  };

  /**
   * [UNGUARDED]: Asynchronous call to update the existing Template with the user-defined configuration.
   * If succesful, redirects to Temp. If failure, raises Error Flashbar.
   */
  updateTemp = async (values) => {
    values.id = this.props.match.params.id;
    try {
      const output = await updateTemplate(values);
      this.setState({
        loading: false,
        redirect: Page.RG_TEMPLATE_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'UPDATING TEMPLATE',
        }),
      });
    }
  };

  /**
   * [UNGUARDED]: Asynchronous call to create a new Template with the user-defined configuration.
   * If succesful, redirects to Temp. If failure, raises Error Flashbar.
   */
  createTemp = async (values) => {
    values.groupId = this.props.activeGroup;
    try {
      const output = await createTemplate(values);
      this.setState({
        loading: false,
        redirect: Page.RG_TEMPLATE_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        loading: false,
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'CREATING TEMPLATE',
        }),
      });
    }
  };

  validateForm = (): boolean => {
    const validation = {
      name: this.state.values.name !== null && validate.isFalsy(this.state.values.name),
      type: this.state.values.type !== null && validate.isFalsy(this.state.values.type),
      templateJsonString:
        this.state.values.templateJsonString !== null &&
        !validate.isValidJSONString(this.state.values.templateJsonString),
    };

    this.setState({ validation });
    return Object.values(validation).some((v) => v);
  };

  /**
   * A class that handles either creating a new, or updating the current Template.
   */
  handleSubmit = async () => {
    this.setState({ loading: true });
    if (this.validateForm()) {
      this.setState({
        loading: false,
        notifications: createBootstrapError({
          message: 'Please verify that all required fields are completed and correct. ',
          code: 'Invalid Input',
          errorWhile: 'SUBMITTING FORM',
        }),
      });
      return;
    }

    var values = new Object({ ...this.state.values });
    values['templateJsonString'] = JSON.stringify(JSON.parse(values['templateJsonString']));

    if (this.props.isUpdate) {
      delete values['type'];
      this.updateTemp(values);
    } else {
      this.createTemp(values);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <div>
        <Flashbar items={this.state.notifications} />

        <Form
          header={<Header variant='h1'>{this.props.isUpdate ? 'Update existing ' : 'Create new '} template</Header>}
          actions={
            <SpaceBetween size='s' direction='horizontal'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({
                    redirect: this.props.isUpdate
                      ? Page.RG_TEMPLATE_DETAILS.replace(':id', this.props.match.params.id)
                      : Page.RESOURCEGROUPS,
                  })
                }
              >
                Cancel
              </Button>
              <Button variant='primary' onClick={this.handleSubmit} loading={this.state.loading}>
                {this.props.isUpdate ? 'Update' : 'Create'}
              </Button>
            </SpaceBetween>
          }
        >
          <Container className='custom-screenshot-hide' header={<Header variant='h2'>Template details</Header>}>
            <SpaceBetween size='m'>
              {this.props.isUpdate && (
                <FormField label='Template ID' description='ID cannot be edited.'>
                  <Input
                    name='target-ct-id'
                    placeholder='Template ID'
                    value={this.props.match.params.id}
                    ariaRequired={false}
                    disabled
                  />
                </FormField>
              )}
              <FormField label={'Template name'}>
                <Input
                  name='target-ct-name'
                  placeholder='Name of template'
                  value={this.state.values.name}
                  ariaRequired={true}
                  onChange={(e) => {
                    this.setState({
                      values: { ...this.state.values, name: e.detail.value },
                    });
                  }}
                  invalid={this.state.validation.name}
                />
              </FormField>
              <FormField label={'Template description'}>
                <Textarea
                  name='target-ct-description'
                  placeholder='A brief description of the template'
                  value={this.state.values.description}
                  ariaRequired={true}
                  onChange={(e) => {
                    this.setState({
                      values: {
                        ...this.state.values,
                        description: e.detail.value,
                      },
                    });
                  }}
                />
              </FormField>
              <FormField label={'Type'}>
                <Input
                  name='target-ct-type'
                  value={this.state.values.type}
                  ariaRequired={true}
                  onChange={(e) => {
                    this.setState({
                      values: { ...this.state.values, type: e.detail.value },
                    });
                  }}
                  invalid={this.state.validation.type}
                />
              </FormField>
              <FormField
                label={'Configuration JSON'}
                description='A JSON defining the configuration of the desired resource.'
              >
                <Textarea
                  name='target-ct-template'
                  placeholder='e.g. {\n\t key1: value1,\n\t key2: [val2a, val2b, val2c], \n\t key3: { \n\t\t key3a: value3a, \n\t\t key3b: value3b \n\t } \n }'
                  value={this.state.values.templateJsonString}
                  onChange={(e) => {
                    this.setState({
                      values: {
                        ...this.state.values,
                        templateJsonString: e.detail.value,
                      },
                    });
                  }}
                  invalid={this.state.validation.templateJsonString}
                />
              </FormField>
            </SpaceBetween>
          </Container>
        </Form>
      </div>
    );
  }
}
