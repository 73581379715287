import {
  MidwayIdentityCredentialProviderCognito,
  MidwayIdentityCredentialProviderSTS,
} from '@amzn/midway-identity-credential-provider';
import { getAuthConfig, authUsesCognito, getRegion, getMidwayAuthorizedRoleArn, getMidwayPartition } from './config';
import { Credentials } from 'aws-sdk/global';

var credentials;
var stsCredentialProvider: MidwayIdentityCredentialProviderSTS;
var user;

// the credentials provided by MidwayIdentityCredentialProviderSTS extend aws-sdk/client-sts/Credentials,
// rather than aws-sdk/lib/credentials/Credentials, which is the one we pass in to the client.
// this simple wrapper allows us to pass it in, so the clients can periodically refresh the crednetials automatically.
class MidwaySTSCredentials extends Credentials {
  private stsCredentialProvider: MidwayIdentityCredentialProviderSTS;
  private midwayUser: string;

  constructor(
    stsCredProvider: MidwayIdentityCredentialProviderSTS,
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    midwayUser: string,
  ) {
    super(accessKeyId, secretAccessKey, sessionToken);
    this.stsCredentialProvider = stsCredProvider;
    this.midwayUser = midwayUser;
  }

  async refresh() {
    const stsCredentials = await this.stsCredentialProvider.getCredentials();
    this.accessKeyId = stsCredentials.AccessKeyId;
    this.secretAccessKey = stsCredentials.SecretAccessKey;
    this.sessionToken = stsCredentials.SessionToken;
    this.midwayUser = stsCredentials.MidwayUser;
  }

  getMidwayUser() {
    return this.midwayUser;
  }
}

export async function initCredentials() {
  // functions in this file split into two cases: credentials provided by Cognito vs STS
  // Cognito is used by the original stages (beta IAD, prod IAD) and STS everywhere else
  // details here: https://code.amazon.com/packages/MidwayIdentityCredentialProvider/trees/mainline
  if (authUsesCognito()) {
    // if credentials already exist, refresh if needed, otherwise return
    if (credentials !== undefined) {
      // refresh credentials if needed
      if (credentials.needsRefresh()) {
        await credentials.refresh(() => {});
        // a recent commit broke backwards compatibility of the credential provider
        // cred.refresh() no longer returns a promise
        // https://code.amazon.com/packages/MidwayIdentityCredentialProvider/commits/2c9e7df89f4b80f350096b33a25eb7b40dee7988#
        if (typeof credentials.refreshTokenAndCredentialsPromise === 'function') {
          await credentials.refreshTokenAndCredentialsPromise();
        }
      }
      return;
    }
    // else, initialize credentials
    const config = getAuthConfig();
    credentials = new MidwayIdentityCredentialProviderCognito(
      { IdentityPoolId: config.identityPoolId },
      window.location.host,
      new URL(window.location.href),
      { region: config.region },
      getMidwayPartition(),
    );
  } else {
    // if credential provider doesn't exist, create it now
    if (stsCredentialProvider === undefined) {
      stsCredentialProvider = new MidwayIdentityCredentialProviderSTS(
        getMidwayAuthorizedRoleArn(),
        window.location.host,
        new URL(window.location.href),
        getMidwayPartition(),
        {
          maxAttempts: 3,
          region: getRegion(),
        },
      );
    }

    const stsCredentials = await stsCredentialProvider.getCredentials();
    credentials = new MidwaySTSCredentials(
      stsCredentialProvider,
      stsCredentials.AccessKeyId,
      stsCredentials.SecretAccessKey,
      stsCredentials.SessionToken,
      stsCredentials.MidwayUser,
    );
  }
}

export async function getCredentials() {
  await initCredentials();
  return credentials;
}

export async function getUser() {
  await initCredentials();

  if (authUsesCognito()) {
    try {
      if (user) {
        return user;
      }
      const token = credentials.params['Logins']['midway-auth.amazon.com'];
      user = parseJwt(token).sub;
    } catch (err) {
      console.log(err);
    }
    return user;
  } else {
    return credentials.getMidwayUser();
  }
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}
