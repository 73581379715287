import { getMetric, getMetricCollectionById, getMetricMetadataById } from 'src/api/metricstore';
import { ITableUserFriendlyDataDefinition } from 'src/components/metricstore/csvDownloaderWrapper';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

export function getColumnDefinitionCollectionView(tableData: string[]) {
  let columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
  let csvColumnDefinition: ITableUserFriendlyDataDefinition[] = [];
  let csvDataColumn: { [key: string]: string } = {};
  csvDataColumn['Metric'] = '';
  columnDefinitions.push({
    id: 'Metric',
    header: 'Metric',
    cell: (item) => item['Metric'],
    minWidth: 300,
    sortingField: 'Metric',
  });

  for (const cellData of tableData) {
    columnDefinitions.push({
      id: cellData,
      header: cellData,
      cell: (item) => item[cellData],
      minWidth: 150,
      sortingField: cellData,
    });
    csvDataColumn[cellData] = '';
  }

  console.log('column definition is:', columnDefinitions);

  for (const key in csvDataColumn) {
    csvColumnDefinition.push({
      header: key,
      friendlyName: (csvData: Array<Record<string, string>>) => csvData[key],
    });
  }
  return [columnDefinitions, csvColumnDefinition];
}

export const handleRefresh = async (
  metricCollectionId: string,
  setMetricData: (arg0: string[]) => void,
  setLoadingTableData: (arg0: boolean) => void,
  setAllMetricItems: (arg0: any[]) => void,
  setError: (arg0: any) => void,
  viewType: string,
) => {
  setLoadingTableData(true);
  let allTableColumnsSet = new Set<string>();

  try {
    const metricCollectionMetadata = await getMetricCollectionById({
      id: metricCollectionId,
    });
    let allMetricData = [];

    // Create an array of promises for each metricId
    const listOfMetric = Object.values(metricCollectionMetadata.metricPositionToIdMap);

    const metricPromises = listOfMetric.map(async (metricId) => {
      const oneMetricDataPromise = await getMetric({
        metricId: metricId,
        viewType: viewType,
      });

      const metricMetadata = await getMetricMetadataById({
        id: metricId,
      });

      let fittingResponse: any = {};
      fittingResponse['Metric'] = metricMetadata.name;
      fittingResponse['Id'] = metricId;
      oneMetricDataPromise.data.forEach((rowData: any) => {
        allTableColumnsSet.add(rowData.interval_value);
        fittingResponse[rowData.interval_value] = rowData.metric_value;
      });
      return fittingResponse;
    });

    //  Promise.all to wait for all promises to resolve
    allMetricData = await Promise.all(metricPromises);
    let allTableColumnsArray: string[] = Array.from(allTableColumnsSet);
    allTableColumnsArray.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    setMetricData(allTableColumnsArray);
    setAllMetricItems(allMetricData);
  } catch (err) {
    console.log('Error in fetching view data: ', err);
    setError(err);
  }
  setLoadingTableData(false);
};
