import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  ColumnLayout,
  Flashbar,
  Header,
  Icon,
  Select,
  Spinner,
  Table,
  Tabs,
} from '@amzn/awsui-components-react-v3';
import { DataBadges, DetailsPageHeader } from 'src/components/catalog/common';
import {
  convertToDgsHCResourceArn,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  getGroupOrWorkspaceName,
  isDataLakeAdmin,
  isDGSAdmin,
} from 'src/commons/common';
import { TableColumnList } from 'src/components/workspaces/dataBrowse/dataset/TablecolumnList';
import { DatasetTagList } from 'src/components/workspaces/dataBrowse/dataset/DatasetTagList';
import { dataSetDetail, listCatalogs, listDataSets, syncDataSets } from 'src/api/catalog';
import { getResourceTags, listFgaPolicies } from 'src/api/permissions';
import { AccessSelectModal } from 'src/components/workspaces/dataBrowse/dataset/accessSelectModal';
import { DatasetEditModal } from 'src/components/workspaces/dataBrowse/dataset/datasetEdit';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  createFgaPolicyViewLink,
  createGroupDetailLink,
  createWorkspaceDetailLink,
  createWSGlueCatalogDetailLink,
  createWSGlueDatabaseDetailLink,
  createWSRedshiftCatalogDetailLink,
  createWSRedshiftDatabaseDetailLink,
  createWSSchemaDetailLink,
  Page,
} from 'src/routes';
import {
  CONTACT_PRIMARY_OWNER_FORM_NAME,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  STATUS_ACTIVE,
  TABLE_CONTENT_TYPE,
  TABLE_STATUS_DEPRECATED,
} from 'src/commons/constants';
import { Link, Redirect } from 'react-router-dom';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import { enableAdvisories } from 'src/api/config';
import { DataQuality } from 'src/components/catalog/dataquality/dataQuality';
import _ from 'lodash';
import {
  generateMetaDataMap,
  getGlossariesForResource,
  metadataMapHasCTI,
} from 'src/components/workspaces/common/common';
import ContactInfo from 'src/components/workspaces/common/ContactInfo';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';

export interface WorkspaceDatasetDetailProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveDatasetName: any;
  cartItemIds: [];
  addToCart: any;
  groups: any[];
  userInfo: any;
  cartItems: [];
}

export const WorkspaceDatasetDetail = (props: WorkspaceDatasetDetailProps) => {
  const [dataset, setDataset] = useState(undefined);
  const [editDataSetModalVisible, setEditDataSetModalVisible] = useState(false);
  const [accessSelectVisible, setAccessSelectVisible] = useState(undefined);
  const [catalogName, setCatalogName] = useState(undefined);
  const [CTI, setCTI] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [options, setOptions] = useState([]);
  const [mostRecentOption, setMostRecentOption] = useState(undefined);
  const [status, setStatus] = useState('loading');
  const [option, setOption] = useState(undefined);
  const [curNextToken, setCurNextToken] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [fgaPolicies, setFgaPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);
  const [templates, setTemplates] = useState(undefined);
  const [businessGlossaries, setBusinessGlossaries] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);

  let redshiftIcon = require('src/components/common/Redshift.png');
  let lakeFormationIcon = require('src/components/common/LakeFormation.png');

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    if (!signedInWithWorkspace(props)) {
      setRedirect(Page.DATASET_DETAILS.replace(':id', props.match.params.id));
    }
  }, [props.activeWorkspace, props.activeGroup]);

  const fetchCatalogName = async (dataset) => {
    let catalog;
    try {
      catalog = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: dataset?.IdInfo?.CatalogId,
              ClusterIdentifier: dataset?.IdInfo?.ClusterIdentifier,
              Region: dataset?.IdInfo?.Region,
              DataSourceId: dataset?.ClusterIdentifier != null ? REDSHIFT_DATASOURCE_ID : LAKE_FORMATION_DATASOURCE_ID,
            },
          ],
        },
      });
    } catch (e) {
      console.log('Could not load catalog for dataset: ' + dataset);
      console.log('Could not load catalog : ' + catalog);
      catalog = null;
    }
    let catalogName = catalog?.CatalogInfoList[0]?.Name;
    if (catalogName == null) {
      catalogName = dataset?.IdInfo?.CatalogId;
    }
    setCatalogName(catalogName);
    let metadataMap = await generateMetaDataMap(getArn(dataset));
    if (!metadataMapHasCTI(metadataMap.get(CONTACT_PRIMARY_OWNER_FORM_NAME)?.values)) {
      setCTI(catalog?.CatalogInfoList[0]?.CTI);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      let dataSetDetails = await listDataSets({
        Filter: {
          IdList: [props.match.params.id],
        },
      });
      let dataset = dataSetDetails.DataSetList[0];

      setDataset(dataset);
      if (dataset?.IdInfo?.TableName) {
        props.setActiveDatasetName(dataset?.IdInfo?.TableName);
        if (dataset?.TableState == TABLE_STATUS_DEPRECATED) {
          setNotifications([
            {
              type: 'error',
              content: 'This dataset is deprecated',
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        }
      }
      await fetchDetails();
      if (dataset !== undefined) {
        await fetchCatalogName(dataset);
      }
      await fetchTags(dataset);
      await fetchFineGrainPoliciesOnDataset(dataset);
      await fetchTemplatesForDataset(dataset);
      setBusinessGlossaries(await getGlossariesForResource(getArn(dataset)));
      setAccessSelectVisible(false);
    } catch (err) {
      console.log('Failed to load dataset', err.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const getDatasetDetailsMetadata = (props: any) => {
    const datasetMetadata = [];
    if (dataset?.IdInfo?.TableName) {
      datasetMetadata.push(
        <CopiableText name={'Dataset name'} key={'Dataset name'} value={dataset?.IdInfo?.TableName} />,
      );
    }
    if (dataset?.IdInfo?.CatalogId) {
      datasetMetadata.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={dataset?.IdInfo?.CatalogId}
          url={
            dataset?.ClusterIdentifier != null
              ? createWSRedshiftCatalogDetailLink(
                  dataset?.IdInfo?.CatalogId,
                  dataset?.IdInfo?.ClusterIdentifier,
                  dataset?.IdInfo?.Region,
                )
              : createWSGlueCatalogDetailLink(dataset?.IdInfo?.CatalogId, dataset?.IdInfo?.Region)
          }
        />,
      );
    }

    if (dataset?.Owners) {
      datasetMetadata.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={dataset?.Owners.map((owner) => (
            <div>
              <Link to={isValidWorkspace(owner) ? createWorkspaceDetailLink(owner) : createGroupDetailLink(owner)}>
                {getGroupOrWorkspaceName(owner, props.workspaceNameMap)}
              </Link>
            </div>
          ))}
        />,
      );
    }
    // include dataset description without condition.
    // Users can view a blank one and update if needed.
    datasetMetadata.push(
      <CopiableText name={'Dataset description'} key={'Dataset description'} value={dataset?.DataSetDesc} />,
    );

    if (dataset?.IdInfo?.DatabaseName) {
      datasetMetadata.push(
        <CopiableText
          name={'Database name'}
          key={'DatabaseName'}
          value={dataset?.IdInfo?.DatabaseName}
          url={
            dataset?.ClusterIdentifier != null
              ? createWSRedshiftDatabaseDetailLink(
                  dataset?.IdInfo?.CatalogId,
                  dataset?.IdInfo?.ClusterIdentifier,
                  dataset?.IdInfo?.DatabaseName,
                  dataset?.IdInfo?.Region,
                )
              : createWSGlueDatabaseDetailLink(
                  dataset?.IdInfo?.CatalogId,
                  dataset?.IdInfo?.DatabaseName,
                  dataset?.IdInfo?.Region,
                )
          }
        />,
      );
    }

    if (dataset?.IdInfo?.SchemaName) {
      datasetMetadata.push(
        <CopiableText
          name={'Schema name'}
          key={'SchemaName'}
          value={dataset?.IdInfo?.SchemaName}
          url={createWSSchemaDetailLink(
            dataset?.IdInfo?.CatalogId,
            dataset?.IdInfo?.ClusterIdentifier,
            dataset?.IdInfo?.DatabaseName,
            dataset?.IdInfo?.SchemaName,
            dataset?.IdInfo?.Region,
          )}
        />,
      );
    }

    if (dataset?.IdInfo?.ClusterIdentifier) {
      datasetMetadata.push(
        <CopiableText name={'Cluster name'} key={'ClusterName'} value={dataset?.IdInfo?.ClusterIdentifier} />,
      );
    }

    if (dataset?.IdInfo?.Region) {
      datasetMetadata.push(<CopiableText name={'Region'} key={'Region'} value={dataset?.IdInfo?.Region} />);
    }
    if (dataset?.Id) {
      datasetMetadata.push(<CopiableText name={'ID'} key={'ID'} value={dataset?.Id} />);
    }
    if (catalogName) {
      datasetMetadata.push(
        <CopiableText
          name={'Catalog name'}
          key={'CatalogName'}
          value={catalogName}
          url={
            dataset?.ClusterIdentifier != null
              ? createWSRedshiftCatalogDetailLink(
                  dataset?.IdInfo?.CatalogId,
                  dataset?.IdInfo?.ClusterIdentifier,
                  dataset?.IdInfo?.Region,
                )
              : createWSGlueCatalogDetailLink(dataset?.IdInfo?.CatalogId, dataset?.IdInfo?.Region)
          }
        />,
      );
    }
    if (dataset?.CreatedBy) {
      datasetMetadata.push(<CopiableText name={'Created by'} key={'CreatedBy'} value={dataset?.CreatedBy} />);
    }
    if (dataset?.CreatedOn) {
      datasetMetadata.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={dataset?.CreatedOn} />);
    }
    if (dataset?.WheeljackConfidenceFileLocation && dataset.WheeljackConfidenceFileLocation.trim()) {
      datasetMetadata.push(
        <CopiableText
          name={'Confidence files'}
          key={'Confidence files'}
          value={dataset?.WheeljackConfidenceFileLocation}
        />,
      );
    }

    if (mostRecentOption?.item || option?.item) {
      let version = mostRecentOption?.item || option?.item;

      if (version?.Location) {
        datasetMetadata.push(<CopiableText key='S3 location' name='S3 location' value={version?.Location} />);
      }

      if (version?.AdditionalLocations) {
        datasetMetadata.push(
          <CopiableText
            key='Additional locations'
            name='Additional locations'
            value={version.AdditionalLocations.join(', ')}
          />,
        );
      }

      if (version?.SourceUpdateTime) {
        datasetMetadata.push(
          <CopiableText key='Update time' name='Update time' value={version?.SourceUpdateTime?.toString()} />,
        );
      }
    }

    return datasetMetadata;
  };

  const columnDefinitions = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.Type,
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Comment,
      minWidth: '200px',
    },
  ];

  const fgaPoliciesColumnDefinitions = [
    {
      id: 'fgapName',
      header: 'Policy name',
      cell: (item) => <Link to={createFgaPolicyViewLink(item?.id)}>{item?.tableData?.physicalName}</Link>,
      minWidth: 200,
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => item?.ownerId,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item?.status,
      minWidth: 200,
    },
    {
      id: 'statusReason',
      header: 'Status reason',
      cell: (item) => item?.statusReason,
      minWidth: 200,
    },
  ];

  const fetchTemplatesForDataset = async (dataset) => {
    let fetchedTemplates = await fetchTemplatesForResourceId(dataset?.Id);
    setTemplates(fetchedTemplates);
  };

  // display "Partition Keys" section on UI
  const getPartitionKeysTable = (version) => {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={columnDefinitions}
        items={version?.PartitionKeys || []}
        resizableColumns
        header={
          <Header counter={version?.PartitionKeys?.length ? `(${version.PartitionKeys.length})` : '(0)'}>
            Partition keys
          </Header>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No partition keys</b>
            </div>
            <p className='awsui-util-mb-s'>No partition keys to display.</p>
          </Box>
        }
      />
    );
  };

  // display "Fine grain policies" section on UI
  const getFgaPoliciesTable = () => {
    return (
      <Table
        loadingText='Loading policies'
        columnDefinitions={fgaPoliciesColumnDefinitions}
        items={fgaPolicies}
        resizableColumns
        header={<Header counter={`(${fgaPolicies.length})`}>Fine grain policies</Header>}
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No policies</b>
            </div>
            <p className='awsui-util-mb-s'>No fine grain policies found for this dataset.</p>
          </Box>
        }
      />
    );
  };

  const getTemplateForDataset = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsDataset()} />;
  };

  // display "Consumers" section on UI
  const getDataConsumersTable = () => {
    if (dataset) {
      const dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
      const resource = {
        accountId: dataset.IdInfo.CatalogId,
        region: dataset.IdInfo.Region,
        type: 'TABLE',
        dataCatalogObjectDetails: {
          dataSourceId: dataSourceId,
          clusterIdentifier: dataset.IdInfo.ClusterIdentifier,
          databaseName: dataset.IdInfo.DatabaseName,
          schemaName: dataset.IdInfo.SchemaName,
          tableName: dataset.IdInfo.TableName,
        },
        dpType: dataSourceId === 'GlueLF' ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_REDSHIFT_TYPE,
        tagResourceId: dataset.Id,
        tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
      };

      return <DataConsumersTable {...props} resource={resource} />;
    }
  };

  const closeEditModal = () => {
    setEditDataSetModalVisible(false);
  };

  const notify = async (type, msg) => {
    setNotifications([
      {
        type: type,
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const notifyEditSuccess = async (msg) => {
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyEditFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const syncDataset = async () => {
    setSyncButtonLoading(true);
    try {
      let syncRequest = {
        IdList: [props.match.params.id],
      };
      await syncDataSets(syncRequest);
      await handleRefresh();
      setNotifications([
        {
          type: 'success',
          content: 'Successfully synced dataset',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to sync dataset: ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    }
  };

  const handleAddToCart = () => {
    setAccessSelectVisible(true);
  };

  const fetchTags = async (dataset) => {
    let tagList = [];
    let getDatasetTagsResponse;
    let getInheritedTagsResponse;
    try {
      getDatasetTagsResponse = await getResourceTags({
        resourceId: dataset?.Id,
        statusCustomerType: 'Active:Publisher',
      });
    } catch (err) {
      console.log(err);
    }
    if (dataset.ClusterIdentifier != null) {
      try {
        getInheritedTagsResponse = await getResourceTags({
          resourceId: `DS-redshift|A-${dataset?.IdInfo?.CatalogId}|CI-${dataset?.IdInfo?.ClusterIdentifier}|DN-${dataset?.IdInfo?.DatabaseName}|SN-${dataset?.IdInfo?.SchemaName}|R-${dataset?.IdInfo?.Region}`,
          statusCustomerType: 'Active:Publisher',
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        getInheritedTagsResponse = await getResourceTags({
          resourceId: `DS-glueLF|A-${dataset?.IdInfo?.CatalogId}|DN-${dataset?.IdInfo?.DatabaseName}|R-${dataset?.IdInfo?.Region}`,
          statusCustomerType: 'Active:Publisher',
        });
      } catch (err) {
        console.log(err);
      }
    }
    let tagSet = new Set();
    if (getInheritedTagsResponse != null) {
      for (let tag of getInheritedTagsResponse?.tags) {
        tagList.push(tag);
        tagSet.add(tag.tagPair);
      }
    }
    if (getDatasetTagsResponse != null) {
      for (let tag of getDatasetTagsResponse?.tags) {
        if (!tagSet.has(tag.tagPair)) {
          tagList.push(tag);
        }
      }
    }
    setTags(tagList);
  };

  const fetchFineGrainPoliciesOnDataset = async (dataset) => {
    let fgaPolicyList = [];
    if (dataset) {
      try {
        let listFgaPoliciesForResourceResult = await listFgaPolicies({
          status: STATUS_ACTIVE,
          tableData: {
            tableCatalogId: dataset.IdInfo.CatalogId,
            clusterId: dataset.IdInfo.ClusterIdentifier,
            databaseName: dataset.IdInfo.DatabaseName,
            schemaName: dataset.IdInfo.SchemaName,
            tableName: dataset.IdInfo.TableName,
            region: dataset.IdInfo.Region,
          },
        });
        fgaPolicyList.push(...listFgaPoliciesForResourceResult.FineGrainAccess);
        while (listFgaPoliciesForResourceResult.nextToken) {
          listFgaPoliciesForResourceResult = await listFgaPolicies({
            status: STATUS_ACTIVE,
            nextToken: listFgaPoliciesForResourceResult.nextToken,
            tableData: {
              tableCatalogId: dataset.IdInfo.CatalogId,
              clusterId: dataset.IdInfo.ClusterIdentifier,
              databaseName: dataset.IdInfo.DatabaseName,
              schemaName: dataset.IdInfo.SchemaName,
              tableName: dataset.IdInfo.TableName,
              region: dataset.IdInfo.Region,
            },
          });
          fgaPolicyList.push(...listFgaPoliciesForResourceResult.FineGrainAccess);
        }
        setFgaPolicies(fgaPolicyList);
      } catch (err) {
        console.log('Failed to fetch Fine grain policies on dataset.' + err.message);
      }
    }
  };

  const versionDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <div className='awsui-util-container'>
              <div className='awsui-util-container-header'>
                <div className='awsui-util-action-stripe'>
                  <div className='awsui-util-action-stripe-title'>
                    <h2>Dataset details</h2>
                  </div>
                  <div className='awsui-util-action-stripe-group'>
                    <div style={{ paddingTop: 5, paddingRight: 10 }}></div>
                  </div>
                </div>
              </div>
              <ColumnLayout columns={3} borders='horizontal'>
                {getDatasetDetailsMetadata(props)}
              </ColumnLayout>
            </div>
            {dataset && <ContactInfo resource={getArn(dataset)} />}
          </>
        ),
      },
      {
        label: 'Columns',
        id: 'Columns',
        content: <TableColumnList {...props} version={mostRecentOption?.item || option?.item} />,
      },
      {
        label: 'Partition keys',
        id: 'Partition keys',
        content: getPartitionKeysTable(mostRecentOption?.item || option?.item),
      },
      {
        label: 'Data quality',
        id: 'Data quality',
        content: <DataQuality setContentType={props.setContentType} datasetId={props.match.params.id} />,
      },
      {
        label: 'Tags',
        id: 'tags',
        content: (
          <DatasetTagList
            {...props}
            dataset={dataset}
            updateTagLabel={handleRefresh}
            notifyEditFailure={notifyEditFailure}
            notifyEditSuccess={notifyEditSuccess}
          />
        ),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForDataset(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={dataset?.Owners}
            activeWorkspace={props.activeWorkspace}
            resource={getArn(dataset)}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
            setNotification={notify}
          />
        ),
      },
      {
        label: 'Fine grain policies',
        id: 'fgaPolicies',
        content: getFgaPoliciesTable(),
      },
    ];

    if (userOwnsDataset())
      tabs.push({
        label: 'Consumers',
        id: 'consumers',
        content: getDataConsumersTable(),
      });
    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={getArn(dataset)}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
          />
        ),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const userOwnsDataset = () => {
    if (!dataset || !dataset.Owners) return false;
    if (props.userInfo && isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    // check if dataset owner is in user workspaces
    // authZ will happen on backend APIs, based on roles
    return (
      _.intersection(dataset.Owners, props.userInfo.memberWorkspaceIds).length > 0 ||
      !!_.find(props.userInfo.memberWorkspaceIds, (wksId) => wksId === dataset.PrimaryOwner)
    );
  };

  const getArn = (dataset) => {
    return convertToDgsHCResourceArn(
      dataset?.IdInfo.CatalogId,
      dataset?.IdInfo.ClusterIdentifier,
      dataset?.IdInfo.DatabaseName,
      dataset?.IdInfo.SchemaName,
      dataset?.IdInfo.TableName,
      undefined,
      dataset?.DataAccessRole,
    );
  };

  const getFormattedOptions = (listOfDataSetDetailResponses) => {
    const mappedList = listOfDataSetDetailResponses.map((i) => ({
      id: i.VersionId.toString(),
      label: 'Version ' + i.VersionId,
      item: i,
    }));
    mappedList.sort(function (a, b) {
      return b.item.VersionId - a.item.VersionId;
    });
    return mappedList;
  };

  const fetchDetails = async () => {
    let dataSetDetails;
    try {
      dataSetDetails = await dataSetDetail({
        Filter: {
          IdList: [props.match.params.id],
        },
      });
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to load dataset details: ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    // we reverse the version list so that the newest versions show first
    const formattedOptions = getFormattedOptions(dataSetDetails.DataSetDetailList);
    const noError = formattedOptions.length > 0;
    if (noError) {
      formattedOptions[0].label = formattedOptions[0].label + ' (latest)';
    }
    if (dataSetDetails.NextToken != null) {
      formattedOptions.push({
        id: 'load-more-datasets',
        label: 'Load more...',
        item: null,
      });
    }
    if (!noError) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to load dataset details`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }

    const newStatus = noError ? 'finished' : 'error';
    const newOption = noError ? formattedOptions[0] : null;

    setOptions(formattedOptions);
    setMostRecentOption(newOption);
    setStatus(newStatus);
    setOption(undefined);
    setCurNextToken(dataSetDetails.NextToken);
  };

  const handleSelectChange = async (e) => {
    const selectedOption = e.detail.selectedOption;
    if (selectedOption != undefined && selectedOption.id == 'load-more-datasets') {
      const combinedOptions = [];
      for (let i = 0; i < options.length - 1; i++) {
        combinedOptions.push(options[i]);
      }

      const response = await dataSetDetail({
        NextToken: curNextToken,
      });

      combinedOptions.push(...getFormattedOptions(response.DataSetDetailList));

      if (response.NextToken != null) {
        combinedOptions.push(options[options.length - 1]);
      }

      // Note that option should remain unchanged
      setOptions(combinedOptions);
      setCurNextToken(response.NextToken);
      setOption(combinedOptions[options.length - 1]);
    } else {
      setOption(e.detail.selectedOption);
    }
  };

  const getVersion = () => {
    let version;
    if (status === 'error') {
      version = 'No metadata available';
    } else if (option == undefined) {
      if (mostRecentOption == undefined) {
        // If everything is not loaded yet
        version = <Spinner size='normal' />;
      } else {
        // If we have not selected an option.  Default to most recent
        version = 'Metadata version ' + mostRecentOption.id;
      }
    } else {
      version = 'Metadata version ' + option.id;
    }
    return version;
  };

  if (!dataset && !loading) {
    return (
      <>
        <h2>Dataset not found</h2>
        The given dataset is not valid, or you do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );
  }

  return (
    <>
      <Flashbar items={notifications} />
      {redirect && <Redirect push to={redirect} />}

      <DetailsPageHeader
        description={<DataBadges dataset={dataset} tags={tags} businessGlossaries={businessGlossaries} />}
        header={
          <>
            {dataset?.IdInfo?.ClusterIdentifier == undefined ? (
              <Icon url={lakeFormationIcon} size='big' />
            ) : (
              <Icon url={redshiftIcon} size='big' />
            )}{' '}
            {dataset?.DataSetName}
          </>
        }
        buttons={
          isDataLakeAdmin(props.activeGroup) || userOwnsDataset()
            ? [
                {
                  text: 'Sync dataset',
                  onItemClick: syncDataset,
                  loading: syncButtonLoading,
                },
                {
                  text:
                    dataset?.IdInfo?.ClusterIdentifier != null
                      ? 'Request Redshift access'
                      : 'Request Lake Formation access',
                  onItemClick: handleAddToCart,
                  disabled: props.activeWorkspace == null || dataset?.TableState == TABLE_STATUS_DEPRECATED,
                },
              ]
            : [
                {
                  text:
                    dataset?.IdInfo?.ClusterIdentifier != null
                      ? 'Request Redshift access'
                      : 'Request Lake Formation access',
                  onItemClick: handleAddToCart,
                  disabled: props.activeWorkspace == null || dataset?.TableState == TABLE_STATUS_DEPRECATED,
                },
              ]
        }
        editButton={{
          text: 'Edit',
          onItemClick: () => setEditDataSetModalVisible(true),
          hidden: !userOwnsDataset(),
          loading: false,
        }}
        versionHeader={getVersion()}
        cti={CTI}
        versionSelect={
          <Select
            ariaLabel={'version1'}
            options={options}
            selectedOption={option}
            loadingText='Loading...'
            placeholder='Metadata history'
            errorText='Error: Unable to load metadata history.'
            ariaRequired={true}
            onChange={handleSelectChange}
          />
        }
      />
      {versionDetail()}
      <br />
      {editDataSetModalVisible && (
        <DatasetEditModal
          {...props}
          visible={editDataSetModalVisible}
          dismiss={closeEditModal}
          dataset={dataset}
          notifyEditFailure={notifyEditFailure}
          notifyEditSuccess={notifyEditSuccess}
          refreshPage={handleRefresh}
        />
      )}
      {accessSelectVisible !== undefined && catalogName !== undefined && accessSelectVisible && tags && dataset && (
        <AccessSelectModal
          {...props}
          visible={accessSelectVisible}
          dismiss={() => {
            setAccessSelectVisible(false);
          }}
          tags={tags}
          fgaPolicies={fgaPolicies}
          dataset={dataset}
          catalogName={catalogName}
        />
      )}
    </>
  );
};
