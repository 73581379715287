import React from 'react';
import CsvDownloader from 'react-csv-downloader';

export interface ITableUserFriendlyDataDefinition {
  header: string;
  friendlyName: (item: any) => string | number;
}
interface ICsvDownloaderWrapperProps {
  rowItems: any[];
  userFriendlyDataDefinitions: ITableUserFriendlyDataDefinition[];
  refForCsvDownloader: React.RefObject<any>;
  fileName: string;
  separator?: string;
  wrapColumnsChar?: string;
}

class CsvDownloaderWrapper extends React.PureComponent<ICsvDownloaderWrapperProps> {
  // setting this helps with debugging in react developer tools

  public render() {
    return (
      <div style={{ display: 'none' }}>
        <CsvDownloader
          ref={this.props.refForCsvDownloader}
          filename={this.props.fileName}
          separator={this.props.separator ?? ','}
          wrapColumnChar={this.props.wrapColumnsChar ?? '"'}
          datas={getCsvData(this.props.rowItems, this.props.userFriendlyDataDefinitions)}
        />
      </div>
    );
  }
}

export const cleanString = (stringToClean: string): string => {
  stringToClean = stringToClean.replace(/"/g, '""').replace('undefined', '-').replace('null', '-');
  return stringToClean;
};

export function mapTableDataToUserFriendlyData(
  tableData: any,
  columnDefinitions: ITableUserFriendlyDataDefinition[],
): any {
  const backendDataToUserFriendlyData: any = {};
  columnDefinitions.forEach((columnDefinition) => {
    backendDataToUserFriendlyData[columnDefinition.header] = cleanString(columnDefinition.friendlyName(tableData) + '');
  });
  return backendDataToUserFriendlyData;
}

export const getCsvData = (rowItems: any[], columnDefinitions: ITableUserFriendlyDataDefinition[]): any[] => {
  const data = [];
  for (const row of rowItems) {
    data.push(mapTableDataToUserFriendlyData(row, columnDefinitions));
  }
  return data;
};

export default CsvDownloaderWrapper;
