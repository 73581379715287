import * as React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react-v3';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';
import { createFgaPolicyViewLink, createWSRequestDetailsLink } from 'src/routes';
import { Link } from 'react-router-dom';
import { CopiableText, getGroupMechanismIdLink } from 'src/commons/common';
import {
  CONTACT_CATEGORY,
  CONTACT_CTI,
  CONTACT_ITEM,
  CONTACT_NOTES,
  CONTACT_ONCALL_ROTATION,
  CONTACT_OWNER,
  CONTACT_TYPE,
  CONTACT_WIKI,
  DW_GDP_LF_ACCOUNT,
  DW_GDP_LF_DB_NAME,
  DW_IAD_LF_ACCOUNT,
  DW_IAD_LF_DB_NAME,
  DW_TABLE_NAMES_WITH_MP_DATA,
  METADATA_BUSINESS_GLOSSARY,
  METADATA_ENABLED,
  METADATA_FORM,
} from 'src/commons/constants';
import {
  getGlossary,
  listMetadataEntitiesAssociatedToResource,
  listMetadataFields,
  listMetadataValues,
} from 'src/api/catalog';

export const flattenDataPermission = (item) => {
  let tableName = undefined;
  let databaseName = undefined;
  let schemaName = undefined;
  let clusterId = undefined;
  let catalogId = undefined;
  let fgapName = undefined;
  let columns = undefined;
  let tag = undefined;
  if (item.resource.table !== undefined) {
    databaseName = item.resource.table.databaseName;
    catalogId = item.resource.table.catalogId;
    tableName = item.resource.table.name == null ? 'ALL_TABLES' : item.resource.table.name;
  } else if (item.resource.tableWithColumns !== undefined) {
    databaseName = item.resource.tableWithColumns.databaseName;
    catalogId = item.resource.tableWithColumns.catalogId;
    tableName = item.resource.tableWithColumns.name;
    columns = item.resource.tableWithColumns.columnNames;
  } else if (item.resource.lFTagPolicy !== undefined) {
    let policy = item.resource?.lFTagPolicy;
    let tagKey = policy.expression[0].tagKey;
    let tagValue = policy.expression[0].tagValues[0];
    catalogId = policy.catalogId;
    tag = `${tagKey}.${tagValue}`;
  } else if (item.resource.redshiftTagPolicy !== undefined) {
    let policy = item.resource.redshiftTagPolicy;
    tag = `${policy.tagKey}.${policy.tagValue}`;
    catalogId = policy.catalogId;
  } else if (item.resource.glueDatabase !== undefined || item.resource.glueTable !== undefined) {
    return null;
  } else if (item.resource.dataCellsFilter) {
    catalogId = item.resource.dataCellsFilter.tableCatalogId;
    clusterId = item.resource.dataCellsFilter.clusterId;
    databaseName = item.resource.dataCellsFilter.databaseName;
    schemaName = item.resource.dataCellsFilter.schemaName;
    tableName = item.resource.dataCellsFilter.tableName;
    fgapName = item.resource.dataCellsFilter.name;
  }
  return {
    dataPermissionId: item.dataPermissionId,
    metadata: item.metadata,
    ownerId: item.ownerId,
    status: item.status,
    option: item.option,
    type: item.type,
    region: item.region,
    schemaName: schemaName,
    clusterId: clusterId,
    databaseName: databaseName,
    catalogId: catalogId,
    tableName: tableName,
    columns: columns,
    tag: tag,
    permissions: item.permissions,
    permissionsWithGrantOption: item.permissionsWithGrantOption,
    dataLakePrincipal: item.dataLakePrincipal,
    auditStatus: item.auditStatus,
    dateActive: item.dateActive,
    dateInActive: item.dateInActive,
    dateOfLastAudit: item.dateOfLastAudit,
    activatedBy: item.activatedBy,
    deactivatedBy: item.deactivatedBy,
    reasonOfAction: item.reasonOfAction,
    audit: item.audit,
    ticketDetails: item.ticketDetails,
    resource: tag != null ? tag : tableName,
    fgapId: item.fgapId,
    fgapName: fgapName,
    permissionRequestId: item.permissionRequestId,
    permissionRequestedDate: item.permissionRequestedDate,
  };
};

export const dataPermissionDetail = (dataPermission) => {
  let permissionItem = flattenDataPermission(dataPermission);

  return (
    <div className='awsui-util-container'>
      <div className='awsui-util-container-header'>
        <h2>DataPermission Information</h2>
      </div>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Owner ID</div>
          <div>{permissionItem.ownerId}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Source Account</div>
          <div>{permissionItem.catalogId}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Source Region</div>
          <div>{permissionItem.region}</div>
        </div>
        {permissionItem.clusterId && (
          <div>
            <div className='awsui-util-label'>Cluster ID</div>
            <div>{permissionItem.clusterId}</div>
          </div>
        )}
        {permissionItem.tag != null ? (
          <div>
            <div className='awsui-util-label'>Tag</div>
            <div>{permissionItem.tag}</div>
          </div>
        ) : (
          <>
            <div>
              <div className='awsui-util-label'>Source Database</div>
              <div>{permissionItem.databaseName}</div>
            </div>
            {permissionItem.schemaName && (
              <div>
                <div className='awsui-util-label'>Schema</div>
                <div>{permissionItem.schemaName}</div>
              </div>
            )}
            <div>
              <div className='awsui-util-label'>Source Table</div>
              <div>{permissionItem.tableName}</div>
            </div>
          </>
        )}
        {permissionItem.fgapId && (
          <div>
            <div className='awsui-util-label'>Fine grain policy</div>
            <div>
              <Link to={createFgaPolicyViewLink(permissionItem.fgapId)}>{permissionItem.fgapName}</Link>
            </div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>DataLake Principal</div>
          <div>{permissionItem.dataLakePrincipal}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Share Type</div>
          <div>{typeToHumanReadableObject[permissionItem.type]}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Status</div>
          <StatusIcon status={permissionItem.status} />
          <div>Date: {permissionItem.dateActive}</div>
          {permissionItem.dateInActive !== undefined && permissionItem.dateInActive !== null && (
            <div>Date Inactive: {permissionItem.dateInActive}</div>
          )}
        </div>
        <div>
          <div className='awsui-util-label'>Request</div>
          {permissionItem.permissionRequestId !== undefined && permissionItem.permissionRequestId !== null && (
            <Link to={createWSRequestDetailsLink(permissionItem.permissionRequestId)}>
              {permissionItem.permissionRequestId}
            </Link>
          )}
        </div>
        <div>
          <div className='awsui-util-label'>Audit Status</div>
          <StatusIcon status={permissionItem.auditStatus} />
          <div>Date: {permissionItem.dateOfLastAudit}</div>
        </div>
      </ColumnLayout>
    </div>
  );
};

export const contactInfoMetadata = (metadataList, contactLevel, resource) => {
  const generateitemName = (itemName) => {
    return `${contactLevel} ${itemName}`;
  };

  let contactMetadata = [];
  let category;
  let type;
  let item;

  for (let metadata of metadataList) {
    let name = metadata.Name;
    let value = metadata.Value;
    switch (name) {
      case CONTACT_CATEGORY:
        category = value;
        break;
      case CONTACT_TYPE:
        type = value;
        break;
      case CONTACT_ITEM:
        item = value;
        break;
      case CONTACT_OWNER:
        contactMetadata.push(
          <div>
            <div className='awsui-util-label'>{generateitemName(name)}</div>
            {getGroupMechanismIdLink(value)}
          </div>,
        );
        break;
      case CONTACT_WIKI:
        contactMetadata.push(
          <div>
            <div className='awsui-util-label'>{generateitemName(name)}</div>
            <div>
              <a href={value} target={'_blank'}>
                {value}
              </a>
            </div>
          </div>,
        );
        break;
      case CONTACT_ONCALL_ROTATION:
        contactMetadata.push(
          <div>
            <div className='awsui-util-label'>{generateitemName(name)}</div>
            <div>
              <a href={value} target={'_blank'}>
                {value}
              </a>
            </div>
          </div>,
        );
        break;
      case CONTACT_NOTES:
        contactMetadata.push(
          <div>
            <div className='awsui-util-label'>{generateitemName(name)}</div>
            <div>{value}</div>
          </div>,
        );
        break;
      default:
        contactMetadata.push(<CopiableText name={generateitemName(name)} key={generateitemName(name)} value={value} />);
    }
  }

  if (category && type && item) {
    contactMetadata.push(
      <div>
        <div className='awsui-util-label'>{generateitemName(CONTACT_CTI)}</div>
        <div>
          <a href={contactCTILink(category, type, item, resource)} target={'_blank'}>
            {category}/{type}/{item}
          </a>
        </div>
      </div>,
    );
  }

  return contactMetadata;
};

export const contactCTILink = (category, type, item, resource) => {
  const BASE_URL = 'https://t.corp.amazon.com/create/options?';
  const ticketTitle = `Omni data related issue`;
  const ticketDescription = `Ticket description for Omni resource: ${resource}`;
  const urlParams = new URLSearchParams([
    ['ticket_type', 'Regular Ticket'],
    ['severity', 'FIVE'],
    ['category', category],
    ['type', type],
    ['item', item],
    ['title', ticketTitle],
    ['description', ticketDescription],
  ]);

  return BASE_URL + urlParams.toString();
};

export const generateMetaDataMap = async (resource) => {
  const generateCombinedFieldValues = async (metadataFormFields, metadataFieldValues) => {
    let newCombinedFieldValues = [];
    for (const field of metadataFormFields) {
      const fieldName = field.Name;
      const fieldType = field.FieldType;
      const fieldId = field.MetadataFieldId;
      const fieldConfig = field.FieldConfig;
      let value = undefined;
      let fieldValue = undefined;
      for (const fieldValueDetail of metadataFieldValues) {
        if (fieldValueDetail.Id == fieldId) {
          fieldValue = fieldValueDetail;
          value = await convertValueToString(fieldValueDetail.Value);
        }
      }
      const item = {
        Name: fieldName,
        FieldType: fieldType,
        Value: value,
        MetadataFieldId: fieldId,
        GlossaryValues: fieldValue?.Value?.GlossaryValueList,
        FieldConfig: fieldConfig,
      };
      if (item.Value != null) {
        newCombinedFieldValues.push(item);
      }
    }
    return newCombinedFieldValues;
  };

  const convertValueToString = async (value) => {
    if (!value) {
      return '';
    }
    if (value.StringValue) {
      return value.StringValue;
    } else if (value.BooleanValue != null) {
      return value.BooleanValue ? 'true' : 'false';
    } else if (value.NumberValue) {
      return String(value.NumberValue);
    } else if (value.GlossaryValueList) {
      const glossaryValueIds = value.GlossaryValueList;
      let glossaryNamesAndIds = [];
      let getGlossaryResponse;
      for (const glossary of glossaryValueIds) {
        getGlossaryResponse = await getGlossary({
          GlossaryId: glossary.GlossaryId,
          OwnerId: glossary.OwnerId,
        });
        glossaryNamesAndIds.push({
          Name: getGlossaryResponse.GlossaryDetails?.Name,
          Id: getGlossaryResponse.GlossaryDetails?.GlossaryId,
          GlossaryValue: getGlossaryResponse.GlossaryDetails?.GlossaryValue,
          GlossaryOwnerId: getGlossaryResponse.GlossaryDetails?.OwnerId,
        });
      }
      return (
        <>
          {glossaryNamesAndIds.map((nameAndId) => (
            <>
              <Link href={`/glossaries/${nameAndId.Id}/${nameAndId.GlossaryOwnerId}`} to={''}>
                {nameAndId.Name} - {nameAndId.GlossaryValue}
              </Link>
              <br />
            </>
          ))}
        </>
      );
    }
  };

  const getMetadataForms = async (resource) => {
    let formsForResource = [];
    let filteredEnabledForms = [];
    let result = await listMetadataEntitiesAssociatedToResource({
      ResourceArn: resource,
      MetadataEntityFilter: METADATA_FORM,
    });
    filteredEnabledForms = result.FormList.filter((form) => {
      return form.Status == METADATA_ENABLED;
    });
    formsForResource.push(...filteredEnabledForms);
    while (result.NextToken != undefined) {
      result = await listMetadataEntitiesAssociatedToResource({
        ResourceArn: resource,
        MetadataEntityFilter: METADATA_FORM,
        NextToken: result.NextToken,
      });
      filteredEnabledForms = result.FormList.filter((form) => {
        return form.Status == METADATA_ENABLED;
      });
      formsForResource.push(...filteredEnabledForms);
    }
    return formsForResource;
  };

  const getMetaDataFormFields = async (formId) => {
    try {
      let formFields = [];
      let filteredEnabledFields = [];
      let listMetadataFormFieldsResult = await listMetadataFields({
        MetadataFormId: formId,
      });
      filteredEnabledFields = listMetadataFormFieldsResult?.MetadataFieldsList.filter((field) => {
        return field.Status == METADATA_ENABLED;
      });
      formFields.push(...filteredEnabledFields);
      while (listMetadataFormFieldsResult.NextToken != undefined) {
        listMetadataFormFieldsResult = await listMetadataFields({
          MetadataFormId: formId,
          NextToken: listMetadataFormFieldsResult.NextToken,
        });
        filteredEnabledFields = listMetadataFormFieldsResult?.MetadataFieldsList.filter((field) => {
          return field.Status == METADATA_ENABLED;
        });
        formFields.push(...filteredEnabledFields);
      }
      return formFields;
    } catch (err) {
      console.log(`Unable to load fields. ${err.message}`);
    }
  };

  const getMetaDataValuesForField = async (formId) => {
    try {
      let result = await listMetadataValues({
        ResourceArn: resource,
        MetadataFormId: formId,
      });
      let valueList = result?.MetadataValuesList;
      while (result.NextToken != undefined) {
        result = await listMetadataValues({
          ResourceArn: resource,
          MetadataFormId: formId,
          NextToken: result.NextToken,
        });
        valueList.push(...result?.MetadataValuesList);
      }
      return valueList;
    } catch (err) {
      console.log(`Unable to load values. ${err.message}`);
    }
  };

  let metadataForms = await getMetadataForms(resource);
  let map = new Map();
  for (let metadataForm of metadataForms) {
    let metadataFormId = metadataForm.MetadataFormId;
    let metadataFormName = metadataForm.Name;
    let displayFormOn = metadataForm.DisplayFormOn;
    let metadataFormFields = await getMetaDataFormFields(metadataFormId);
    const metadataFormFieldValueList = await getMetaDataValuesForField(metadataFormId);
    const combinedFieldValues = await generateCombinedFieldValues(metadataFormFields, metadataFormFieldValueList);
    map.set(metadataFormName, { values: combinedFieldValues, displayFormOn: displayFormOn });
  }

  return map;
};

export const metadataMapHasCTI = (metadataList) => {
  let catalogProvided = false;
  let typeProvided = false;
  let itemProvided = false;

  if (metadataList == null) {
    return false;
  }

  for (let metadata of metadataList) {
    if (CONTACT_CATEGORY == metadata.Name) {
      catalogProvided = true;
    }
    if (CONTACT_TYPE == metadata.Name) {
      typeProvided = true;
    }
    if (CONTACT_ITEM == metadata.Name) {
      itemProvided = true;
    }
  }
  return catalogProvided && typeProvided && itemProvided;
};

export const getGlossariesForResource = async (resource) => {
  try {
    // get all glossaries for owner and filter on the results
    let glossariesForResource = [];
    let filteredEnabledGlossaries = [];
    let result = await listMetadataEntitiesAssociatedToResource({
      ResourceArn: resource,
      MetadataEntityFilter: METADATA_BUSINESS_GLOSSARY,
    });
    filteredEnabledGlossaries = result.GlossaryList.filter((glossary) => {
      return glossary.Status == METADATA_ENABLED;
    });
    glossariesForResource.push(...filteredEnabledGlossaries);
    while (result.NextToken != undefined) {
      result = await listMetadataEntitiesAssociatedToResource({
        ResourceArn: resource,
        MetadataEntityFilter: METADATA_BUSINESS_GLOSSARY,
        NextToken: result.NextToken,
      });
      filteredEnabledGlossaries = result.GlossaryList.filter((glossary) => {
        return glossary.Status == METADATA_ENABLED;
      });
      glossariesForResource.push(...filteredEnabledGlossaries);
    }
    return glossariesForResource;
  } catch (err) {
    console.log('error', `Unable to load glossaries. ${err.message}`);
  }
};

// Short term fix to disable customer request db/table level access and use fga instead. Would replace with custom policy.
export const isMPData = (catalogId, databaseName, tableName) => {
  if (catalogId != DW_GDP_LF_ACCOUNT && catalogId != DW_IAD_LF_ACCOUNT) {
    return false;
  }

  if (databaseName != DW_GDP_LF_DB_NAME && databaseName != DW_IAD_LF_DB_NAME) {
    return false;
  }

  return DW_TABLE_NAMES_WITH_MP_DATA.has(tableName);
};
