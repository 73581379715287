// This file will contain common reusable react component
import React from 'react';
import { Button, ButtonDropdown, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import CsvDownloaderWrapper from 'src/components/metricstore/csvDownloaderWrapper';

export const MetricStorePageHeader = ({ header = null, buttons = null, subheader = null }) => {
  header = header || '';
  buttons = buttons || [];
  subheader = subheader || '';
  return (
    <Header
      variant={header ? 'h1' : 'h2'}
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key} onItemClick={button.onItemClick} loading={button.loading}>
                {button.text}
              </ButtonDropdown>
            ),
          )}
        </SpaceBetween>
      }
    >
      {header}
      {subheader}
    </Header>
  );
};

//Header with CSV Download option
export const MetricStorePageHeaderWithCSV = ({
  header = null,
  buttons = null,
  subheader = null,
  onClickDownload,
  rowItems,
  fileName,
  refForCSVDownloader,
  columnDefinition,
}) => {
  header = header || '';
  buttons = buttons || [];
  subheader = subheader || '';
  return (
    <Header
      variant={header ? 'h1' : 'h2'}
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key} onItemClick={button.onItemClick} loading={button.loading}>
                {button.text}
              </ButtonDropdown>
            ),
          )}
          <Button variant='primary' onClick={onClickDownload} iconName='download'>
            Download as CSV
          </Button>
          <CsvDownloaderWrapper
            rowItems={rowItems}
            fileName={fileName}
            refForCsvDownloader={refForCSVDownloader}
            userFriendlyDataDefinitions={columnDefinition}
          />
        </SpaceBetween>
      }
    >
      {header}
      {subheader}
    </Header>
  );
};
