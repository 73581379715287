import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  Table,
  TextFilter,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
} from '@amzn/awsui-components-react-v3/polaris';

import { listTargets } from '../../api/subscriptions';
import { PageHeader } from '../subscriptions/common';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Page } from '../../routes/Paths';
import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface ConnectionsTableProps {
  setContentType: any;
  activeGroup: string;
}

export const ConnectionsTable = (props: ConnectionsTableProps) => {
  const [allItems, setAllItems] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingConnections, setLoadingConnections] = useState(undefined);
  const [tableMessage, setTableMessage] = useState('No connections');

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions = [
    {
      id: 'connectionId',
      header: 'Connection ID',
      cell: (item) => <Link to={`connections/${item.targetId}`}>{item.targetId}</Link>,
      minWidth: 200,
    },
    {
      id: 'connectionName',
      header: 'Name',
      cell: (item) => item.name,
      minWidth: 200,
      sortingField: 'name',
    },
    {
      id: 'createDate',
      header: 'Create date',
      cell: (item) => item.createDate,
      minWidth: 200,
      sortingField: 'createDate',
    },
    {
      id: 'createdBy',
      header: 'Created by',
      cell: (item) => item.createdBy,
      minWidth: 200,
      sortingField: 'createdBy',
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingConnections(true);

    try {
      const targets = await listTargets({
        ownerId: props.activeGroup,
      });

      setAllItems(targets.targets);
      setLoadingConnections(false);
    } catch (err) {
      setTableMessage(`Unable to load connections. ${err.message}`);
      setLoadingConnections(false);
    }
  };

  const { items, filterProps, filteredItemsCount, collectionProps, paginationProps } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No connections to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        selectionType='single' // Is this unused?
        loadingText='Loading connections...'
        loading={loadingConnections}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Create connection',
                  variant: 'primary',
                  onItemClick: () => {
                    setRedirect(Page.CREATE_CONNECTION);
                  },
                },
              ]}
              header={
                <>
                  Connections
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          </>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};
