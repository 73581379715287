import { Box, Button, Header, Input, SpaceBetween, Table, Link } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import {
  convertResourceARNToLink,
  generateArnFromId,
  getIdInfoFromArn,
  getScopeForIdInfo,
} from 'src/components/utils/arnUtil';
import { COMMENT_TOO_LONG_ERROR, getResourceNameFromArn } from 'src/components/dataadvisory/constants';
import { AdvisoryImpactedResourceList } from 'aws-sdk/clients/awsdlomni';
import { ResourceSelectorModal } from 'src/components/common/resourceSelector/resourceSelectorModal';

export interface ImpactedResourcesEditorProps {
  activeGroup: string;
  activeWorkspace?: any;
  impactedResources: AdvisoryImpactedResourceList;
  setImpactedResources?: any;
  setErrorMessage?: any;
  readonly: boolean;
}

export const ImpactedResourcesEditor = (props: ImpactedResourcesEditorProps) => {
  const [resourceSelectorModalVisible, setResourceSelectorModalVisible] = useState(false);
  const [currentEditValue, setCurrentEditValue] = useState(undefined);

  const addResource = (resourceId: string) => {
    setResourceSelectorModalVisible(false);
    const arn = generateArnFromId(resourceId);

    let newItems = [];
    newItems.push(...props.impactedResources);
    newItems.push({
      resourceArn: arn,
      comment: '',
    });

    if (!props.readonly) props.setImpactedResources(newItems);
  };

  const validateResources = () => {
    if (!props.setErrorMessage) return;

    const aCommentIsTooLong = props.impactedResources.filter((resource) => resource.comment?.length > 256).length > 0;
    if (aCommentIsTooLong) {
      props.setErrorMessage(COMMENT_TOO_LONG_ERROR);
    } else {
      props.setErrorMessage(undefined);
    }
  };

  useEffect(() => {
    validateResources();
  }, [props.impactedResources]);

  return (
    <>
      <div>
        <ResourceSelectorModal
          onSelect={(resourceId) => {
            addResource(resourceId);
          }}
          visible={resourceSelectorModalVisible}
          close={() => setResourceSelectorModalVisible(false)}
          {...props}
        />
        <Table
          items={props.impactedResources}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign={'center'} color={'inherit'}>
              <SpaceBetween size={'m'}>
                <b>No resources</b>
                <p>Select "Add resource" above to add a resource.</p>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              counter={'(' + props.impactedResources.length + ')'}
              actions={
                props.readonly
                  ? []
                  : [<Button onClick={() => setResourceSelectorModalVisible(true)}>Add resource</Button>]
              }
            >
              Selected resources
            </Header>
          }
          columnDefinitions={[
            {
              id: 'name',
              header: 'Name',
              cell: (item) => (
                <Link href={convertResourceARNToLink(item.resourceArn, props.activeWorkspace !== null)}>
                  {getResourceNameFromArn(item.resourceArn)}
                </Link>
              ),
              minWidth: 150,
            },
            {
              id: 'type',
              header: 'Type',
              cell: (item) => getScopeForIdInfo(getIdInfoFromArn(item.resourceArn)),
              minWidth: 50,
            },
            {
              id: 'comment',
              header: 'Comment',
              cell: (item) => item.comment,
              editConfig: props.readonly
                ? undefined
                : {
                    ariaLabel: 'Resource comment',
                    editIconAriaLabel: 'editable',
                    errorIconAriaLabel: 'Comment error',
                    editingCell: (item, { currentValue, setValue }) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.comment}
                          onChange={(event) => {
                            const value = event.detail.value ?? item.comment;
                            setValue(value);
                            setCurrentEditValue(value);
                          }}
                        />
                      );
                    },
                  },
            },
            {
              id: 'actions',
              header: '',
              cell: (item) => (
                <Button
                  variant={'icon'}
                  iconName={'remove'}
                  onClick={() => {
                    props.setImpactedResources(
                      props.impactedResources.filter((resource) => resource.resourceArn != item.resourceArn),
                    );
                  }}
                />
              ),
            },
          ].slice(0, props.readonly ? 3 : 4)}
          submitEdit={(item) => {
            const newItems = props.impactedResources.map((itm) => {
              if (itm == item) {
                return {
                  resourceArn: itm.resourceArn,
                  comment: currentEditValue,
                };
              }
              return itm;
            });
            props.setImpactedResources(newItems);
            setCurrentEditValue(undefined);
          }}
        />
      </div>
    </>
  );
};
