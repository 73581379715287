import { Page } from '../routes';
import { enableAdvisories } from 'src/api/config';
import { Badge } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { getDismissedAdvisories } from 'src/components/common/activeAdvisoryGlobalFlashbar';
import { DGS_TEAM_WIKI, FEATURE_REQUEST_URL, OMNI_WIKI_PAGE } from 'src/commons/constants';

const IMPORT_DATASETS = 'Import dataset';
export const DATA_INGESTION_TITLE = 'Data ingestion (Tethys)';

const moreInfoItems = () => {
  return [
    {
      type: 'link',
      text: 'How-to guide',
      href: OMNI_WIKI_PAGE,
      external: true,
    },
    {
      type: 'link',
      text: 'About AWS Data Governance Services',
      href: DGS_TEAM_WIKI,
      external: true,
    },
    {
      type: 'link',
      text: 'Feature request',
      href: FEATURE_REQUEST_URL,
      external: true,
    },
  ];
};

const metricStoreItems = () => {
  return [
    {
      type: 'section',
      text: 'MetricStore',
      items: [
        { type: 'link', text: 'Metrics catalog', href: Page.METRICS_CATALOG },
        { type: 'link', text: 'Metrics collection catalog', href: Page.METRIC_COLLECTIONS_CATALOG },
        { type: 'link', text: 'Reporting table catalog', href: Page.REPORTING_TABLE_CATALOG },
      ],
    },
  ];
};

const advisoryItem = async (props) => {
  const dismissedAdvisories = await getDismissedAdvisories();
  const advisoriesToDisplay = props.activeAdvisories.filter(
    (advisory: Advisory) => !dismissedAdvisories.includes(advisory.advisoryId),
  );
  return {
    type: 'link',
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
    info: advisoriesToDisplay.length > 0 ? <Badge color='red'>{advisoriesToDisplay.length}</Badge> : undefined,
  };
};

// the "Discover data" section of the sidebar only displays "Data advisories" in certain regions, and
// it displays a red badge next to "Data advisories" if the user has active advisories.
let browseItemsForGroup = async (props) => {
  let items = [
    { type: 'link', text: 'Search', href: Page.SEARCH },
    { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
    { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
    { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
  ];
  if (enableAdvisories()) {
    items.push(await advisoryItem(props));
  }
  return items;
};
let browseItemsForWorkspace = async (props) => {
  let items = [
    { type: 'link', text: 'Search', href: Page.SEARCH },
    {
      type: 'link',
      text: 'Browse datasets',
      href: Page.WORKSPACES_BROWSE_DATASETS,
    },
    {
      type: 'link',
      text: 'Browse databases',
      href: Page.WORKSPACES_BROWSE_DATABASES,
    },
    {
      type: 'link',
      text: 'Browse catalogs',
      href: Page.WORKSPACES_BROWSE_CATALOGS,
    },
  ];
  if (enableAdvisories()) {
    items.push(await advisoryItem(props));
  }
  return items;
};

// Navigation
export const navItemsForProvider = async (props) => [
  {
    type: 'section',
    text: 'Discover data',
    items: await browseItemsForGroup(props),
  },
  {
    type: 'section',
    text: 'Subscribe to data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: IMPORT_DATASETS, href: Page.PUBLISH },
      { type: 'link', text: DATA_INGESTION_TITLE, href: Page.PUBLISHER_TABLES },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Templates', href: Page.TEMPLATES },
      { type: 'link', text: 'Baseline', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Resources manager',
    items: [
      { type: 'link', text: 'Metadata forms', href: Page.METADATA_FORMS },
      {
        type: 'link',
        text: 'Business glossaries',
        href: Page.BUSINESS_GLOSSARIES,
      },
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      { type: 'link', text: 'Bootstrap actions', href: Page.BOOTSTRAPACTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Omni workspace',
    items: [
      {
        type: 'link',
        text: 'Workspace',
        href: Page.BROWSE_WORKSPACES,
      },
    ],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const navItemsForAdmins = async (props) => [
  {
    type: 'section',
    text: 'Discover data',
    items: await browseItemsForGroup(props),
  },
  {
    type: 'section',
    text: 'Subscribe to data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: IMPORT_DATASETS, href: Page.PUBLISH },
      { type: 'link', text: DATA_INGESTION_TITLE, href: Page.PUBLISHER_TABLES },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Templates', href: Page.TEMPLATES },
      { type: 'link', text: 'Baseline', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Manage resources',
    items: [
      { type: 'link', text: 'Metadata forms', href: Page.METADATA_FORMS },
      {
        type: 'link',
        text: 'Business glossaries',
        href: Page.BUSINESS_GLOSSARIES,
      },
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      { type: 'link', text: 'Bootstrap actions', href: Page.BOOTSTRAPACTIONS },
      {
        type: 'link',
        text: 'Event subscriptions',
        href: Page.EVENT_SUBSCRIPTIONS,
      },
    ],
  },
  {
    type: 'section',
    text: 'Omni workspace',
    items: [{ type: 'link', text: 'Group workspaces', href: Page.BROWSE_WORKSPACES }],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const navItemsForConsumer = async (props) => [
  {
    type: 'section',
    text: 'Discover data',
    items: await browseItemsForGroup(props),
  },
  {
    type: 'section',
    text: 'Subscribe to Data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Baseline', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Resources manager',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      {
        type: 'link',
        text: 'Bootstrap actions',
        href: Page.BOOTSTRAPACTIONS,
      },
    ],
  },
  {
    type: 'section',
    text: 'Omni workspace',
    items: [
      {
        type: 'link',
        text: 'Workspace',
        href: Page.BROWSE_WORKSPACES,
      },
    ],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

// Navigation for new customers
export const navItemsNewCustomers = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Search', href: Page.SEARCH },
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Onboard',
    items: [{ type: 'link', text: 'Submit Onboarding Request', href: '/onboarding' }],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel',
};

export const activeHref = '/subscription';

export const navItemsForWorkspacesProvider = async (props) => [
  {
    type: 'section',
    text: 'Discover data',
    items: await browseItemsForWorkspace(props),
  },
  {
    type: 'section',
    text: 'Register data',
    items: [
      {
        type: 'link',
        text: 'Lake Formation',
        href: Page.WORKSPACE_GLUE_REGISTER,
      },
      {
        type: 'link',
        text: 'Redshift',
        href: Page.WORKSPACE_REDSHIFT_REGISTER,
      },
    ],
  },
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      {
        type: 'link',
        text: 'New access requests',
        href: Page.WORKSPACE_MY_REQUESTS,
      },
      { type: 'link', text: 'Templates', href: Page.TEMPLATES },
      {
        type: 'link',
        text: 'Data access permissions',
        href: Page.WORKSPACE_ALL_PERMISSIONS,
      },
      { type: 'link', text: 'Workspace access', href: Page.WORKSPACEACCESS },
      { type: 'link', text: 'Permission tags', href: Page.TAGS },
      {
        type: 'link',
        text: 'Fine grain access policies ',
        href: Page.FINE_GRAIN_ACCESS_POLICIES,
      },
    ],
  },
  {
    type: 'section',
    text: 'Manage resources',
    items: [
      {
        type: 'link',
        text: 'Event subscriptions',
        href: Page.EVENT_SUBSCRIPTIONS,
      },
      { type: 'link', text: 'Metadata forms', href: Page.METADATA_FORMS },
      {
        type: 'link',
        text: 'Business glossaries',
        href: Page.BUSINESS_GLOSSARIES,
      },
    ],
  },
  {
    type: 'section',
    text: 'Data analytics',
    items: [
      {
        type: 'link',
        text: 'Athena query',
        href: Page.WORKSPACE_RESOURCE_LINKS,
      },
      {
        type: 'link',
        text: 'Redshift query',
        href: Page.WORKSPACE_REDSHIFT_QUERY,
      },
    ],
  },
  {
    type: 'section',
    text: 'Omni workspace',
    items: [{ type: 'link', text: 'Current workspace', href: Page.WORKSPACE }],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const navItemsForWorkspacesConsumer = async (props) => [
  {
    type: 'section',
    text: 'Discover data',
    items: await browseItemsForWorkspace(props),
  },
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      {
        type: 'link',
        text: 'New access requests',
        href: Page.WORKSPACE_MY_REQUESTS,
      },
      {
        type: 'link',
        text: 'Data access permissions',
        href: Page.WORKSPACE_ALL_PERMISSIONS,
      },
      { type: 'link', text: 'Workspace access', href: Page.WORKSPACEACCESS },
    ],
  },
  {
    type: 'section',
    text: 'Data analytics',
    items: [
      {
        type: 'link',
        text: 'Athena query',
        href: Page.WORKSPACE_RESOURCE_LINKS,
      },
      {
        type: 'link',
        text: 'Redshift query',
        href: Page.WORKSPACE_REDSHIFT_QUERY,
      },
    ],
  },
  {
    type: 'section',
    text: 'Omni workspace',
    items: [{ type: 'link', text: 'Current workspace', href: Page.WORKSPACE }],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

//mvp navigation
export const navItemsForIsoProvider = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Search', href: Page.SEARCH },
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: IMPORT_DATASETS, href: Page.PUBLISH },
      { type: 'link', text: DATA_INGESTION_TITLE, href: Page.PUBLISHER_TABLES },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Manage resources',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      { type: 'link', text: 'Bootstrap actions', href: Page.BOOTSTRAPACTIONS },
    ],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const navItemsForIsoAdmins = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Search', href: Page.SEARCH },
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: IMPORT_DATASETS, href: Page.PUBLISH },
      { type: 'link', text: DATA_INGESTION_TITLE, href: Page.PUBLISHER_TABLES },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Manage resources',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      { type: 'link', text: 'Bootstrap actions', href: Page.BOOTSTRAPACTIONS },
    ],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];

export const navItemsForIsoConsumer = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Search', href: Page.SEARCH },
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  ...metricStoreItems(),
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'New access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  { type: 'divider' },
  ...moreInfoItems(),
];
